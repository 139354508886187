<template v-slot:body>
  <Api :config="config" :isTab="true"></Api>
</template>

<script>
import Api from "../../components/Api.vue";
export default {
  components: {
    Api,
  },
  data() {
    return {
      config: [
        {
          title: "Api para Previsão do Tempo",
          data: [
            {
              title: "Lista de Cidades Cadastradas",
              caption:
                "Lista todas as cidades cadastradas das na base de dados onAgro. O “id” informado na resposta será utilizado posteriormente para a busca de dados meteorológicos",
              curlParam: [
                "Public Token: O token deve ser passado no header da requisição.",
              ],
              curlReturn1: [
                "Id: Identificador da cidade, utilizado para consultar a previsão do tempo posteriormente.",
                "Nome: Nome da cidade.",
                "Estado: Nome do estado que a cidade pertence.",
                "UF: Sigla do estado.",
              ],
              recomendation:
                "Salvar os dados referentes a cidade na sua base de dados, para agilizar a consulta, pois são mais de 5 mil municípios, isso pode deixar a aplicação um pouco lenta caso seja consultado frequentemente a rota de aquisição de cidades.",
              method: "curl",
              url: "curl --location 'https://api.onagro.com.br/api/WeatherForecast/GetAllCity' \
                 --header 'x-api-key: public_token'",
              response: {
                200: {
                  description: "Resposta padrão",
                  example: {
                    data: [
                      {
                        id: 5200050,
                        nome: "Abadia de Goiás",
                        estado: "Goiás",
                        uf: "GO",
                      },
                      {
                        id: 3100104,
                        nome: "Abadia dos Dourados",
                        estado: "Minas Gerais",
                        uf: "MG",
                      },
                      {
                        id: 5200100,
                        nome: "Abadiânia",
                        estado: "Goiás",
                        uf: "GO",
                      },
                      {
                        id: 3100203,
                        nome: "Abaeté",
                        estado: "Minas Gerais",
                        uf: "MG",
                      },
                      {
                        id: 1500107,
                        nome: "Abaetetuba",
                        estado: "Pará",
                        uf: "PA",
                      },
                      {
                        id: 2300101,
                        nome: "Abaiara",
                        estado: "Ceará",
                        uf: "CE",
                      },
                      {
                        id: 2900108,
                        nome: "Abaíra",
                        estado: "Bahia",
                        uf: "BA",
                      },
                      {
                        id: 2900207,
                        nome: "Abaré",
                        estado: "Bahia",
                        uf: "BA",
                      },
                      {
                        id: 4100103,
                        nome: "Abatiá",
                        estado: "Paraná",
                        uf: "PR",
                      },
                      {
                        id: 4200051,
                        nome: "Abdon Batista",
                        estado: "Santa Catarina",
                        uf: "SC",
                      },
                      {
                        id: 1500131,
                        nome: "Abel Figueiredo",
                        estado: "Pará",
                        uf: "PA",
                      },
                      {
                        id: 4200101,
                        nome: "Abelardo Luz",
                        estado: "Santa Catarina",
                        uf: "SC",
                      },
                      {
                        id: 3100302,
                        nome: "Abre Campo",
                        estado: "Minas Gerais",
                        uf: "MG",
                      },
                      {
                        id: 2600054,
                        nome: "Abreu e Lima",
                        estado: "Pernambuco",
                        uf: "PE",
                      },
                      {
                        id: 1700251,
                        nome: "Abreulândia",
                        estado: "Tocantins",
                        uf: "TO",
                      },
                      {
                        id: 3100401,
                        nome: "Acaiaca",
                        estado: "Minas Gerais",
                        uf: "MG",
                      },
                      {
                        id: 2100055,
                        nome: "Açailândia",
                        estado: "Maranhão",
                        uf: "MA",
                      },
                      {
                        id: 2900306,
                        nome: "Acajutiba",
                        estado: "Bahia",
                        uf: "BA",
                      },
                      "...",
                    ],
                  },
                  400: {
                    description: "Erro ao não passar o token",
                    example: {
                      code: "400",
                      message: "Token is Empty",
                      outputErrorMessage: {
                        message: "Token is Empty",
                      },
                    },
                  },
                  401: {
                    description: "Erro na autenticação do token",
                    example: {
                      code: "401",
                      message: "Token is Invalid",
                      outputErrorMessage: {
                        message: "Token is Invalid",
                      },
                    },
                  },
                },
                400: {
                  description: "Erro ao não passar o token",
                  example: {
                    code: "400",
                    message: "Token is Empty",
                    outputErrorMessage: {
                      message: "Token is Empty",
                    },
                  },
                },
                401: {
                  description: "Erro na autenticação do token",
                  example: {
                    code: "401",
                    message: "Token is Invalid",
                    outputErrorMessage: {
                      message: "Token is Invalid",
                    },
                  },
                },
              },
            },
            {
              title:
                "Previsão do tempo para o dia atual mas de uma hora específica",
              caption:
                "Retorna da base de dados a previsão do tempo de uma cidade específica, do dia em que a consulta foi realizada.",
              curlParam: [
                "Public Token: O token deve ser passado no header da requisição.",
                "CityID: É o “id” da cidade que é apresentado com na rota /GetAllCity.",
                "hour: É a hora da previsão do tempo que você deseja consultar.",
              ],
              curlReturnTitle1: "Dados de previsão do tempo:",
              curlReturn1: [
                "data_referencia: Data e hora da previsão do tempo.",
                "temperatura_max: Temperatura máxima esperada na hora solicitada.",
                "temperatura_min: Temperatura minima esperada na hora solicitada.",
                "temperatura: A temperatura esperada para o momento da solicitação.",
                "vento_velocidade_media: Velocidade média do vento em km/h.",
                "precipitacao_total: O total de chuva esperada para a hora solicitada.",
              ],
              curlReturnTitle2:
                "Dados referentes a cidade que a previsão pertence:",
              curlReturn2: [
                "id: Identificador da cidade, o mesmo da requisição.",
                "nome: Nome da cidade.",
                "estado: Unidade federativa que a cidade pertence.",
                "latitude: Latitude da cidade para ser usado em mapas.",
                "longitude: Longitude da cidade para ser utilizado em mapas.",
                "uf: Sigla da unidade federativa que a cidade pertence",
              ],
              method: "curl",
              url: "curl --location 'https://api.onagro.com.br/api/WeatherForecast/GetByHourAndDateNow/<CityID>?hour=14:00' \
                 --header 'x-api-key: public_token'",
              response: {
                200: {
                  description: "Resposta padrão",
                  example: {
                    data: {
                      data_referencia: "2024-04-01T08:00:00",
                      temperatura_max: 33.3,
                      temperatura_min: 17.7,
                      temperatura: 18.1,
                      vento_velocidade_media: 3.4041666666666666666666666667,
                      precipitacao_total: 0.0,
                      cidade: {
                        id: 4311403,
                        nome: "Lajeado",
                        estado: "Rio Grande do Sul",
                        latitude: -29.4591,
                        longitude: -51.9644,
                        uf: "RS",
                      },
                    },
                  },
                },
                400: {
                  description: "Erro ao não passar o token",
                  example: {
                    code: "400",
                    message: "Token is Empty",
                    outputErrorMessage: {
                      message: "Token is Empty",
                    },
                  },
                },
                401: {
                  description: "Erro na autenticação do token",
                  example: {
                    code: "401",
                    message: "Token is Invalid",
                    outputErrorMessage: {
                      message: "Token is Invalid",
                    },
                  },
                },
              },
            },
            {
              title: "Previsão do tempo para 5 dias para uma hora específica",
              caption:
                "Mostra a previsão do tempo para os próximos 5 dias, para a hora informada.",
              curlParam: [
                "Public Token: O token deve ser passado no header da requisição.",
                "CityID: É o “id” da cidade que é apresentado com na rota /GetAllCity.",
                "hour: É a hora da previsão do tempo que você deseja consultar.",
              ],
              curlReturnTitle1: "Dados de previsão do tempo:",
              curlReturn1: [
                "data_referencia: Data e hora da previsão do tempo.",
                "temperatura_max: Temperatura máxima esperada na hora solicitada.",
                "temperatura_min: Temperatura minima esperada na hora solicitada.",
                "temperatura: A temperatura esperada para o momento da solicitação.",
                "vento_velocidade_media: Velocidade média do vento em km/h.",
                "precipitacao_total: O total de chuva esperada para a hora solicitada.",
              ],
              curlReturnTitle2:
                "Dados referentes a cidade que a previsão pertence:",
              curlReturn2: [
                "id: Identificador da cidade, o mesmo da requisição.",
                "nome: Nome da cidade.",
                "estado: Unidade federativa que a cidade pertence.",
                "latitude: Latitude da cidade para ser usado em mapas.",
                "longitude: Longitude da cidade para ser utilizado em mapas.",
                "uf: Sigla da unidade federativa que a cidade pertence.",
              ],
              method: "curl",
              url: "curl --location 'https://api.onagro.com.br/api/WeatherForecast/GetByHour/<CityID>?hour=14:00' \
                --header 'x-api-key: public_token'",
              response: {
                200: {
                  description: "Resposta padrão",
                  example: {
                    data: [
                      {
                        data_referencia: "2024-03-26T08:00:00",
                        temperatura_max: 28.8,
                        temperatura_min: 15.9,
                        temperatura: 15.9,
                        vento_velocidade_media: 5.8583333333333333333333333333,
                        precipitacao_total: 0.0,
                        cidade: {
                          id: 4311403,
                          nome: "Lajeado",
                          estado: "Rio Grande do Sul",
                          latitude: -29.4591,
                          longitude: -51.9644,
                          uf: "RS",
                        },
                      },
                      {
                        data_referencia: "2024-03-27T08:00:00",
                        temperatura_max: 30.3,
                        temperatura_min: 14.6,
                        temperatura: 15.0,
                        vento_velocidade_media: 4.4375,
                        precipitacao_total: 0.0,
                        cidade: {
                          id: 4311403,
                          nome: "Lajeado",
                          estado: "Rio Grande do Sul",
                          latitude: -29.4591,
                          longitude: -51.9644,
                          uf: "RS",
                        },
                      },
                      {
                        data_referencia: "2024-03-28T08:00:00",
                        temperatura_max: 32.1,
                        temperatura_min: 16.8,
                        temperatura: 17.1,
                        vento_velocidade_media: 3.1541666666666666666666666667,
                        precipitacao_total: 2.0,
                        cidade: {
                          id: 4311403,
                          nome: "Lajeado",
                          estado: "Rio Grande do Sul",
                          latitude: -29.4591,
                          longitude: -51.9644,
                          uf: "RS",
                        },
                      },
                      {
                        data_referencia: "2024-03-29T08:00:00",
                        temperatura_max: 30.1,
                        temperatura_min: 18.9,
                        temperatura: 19.1,
                        vento_velocidade_media: 4.925,
                        precipitacao_total: 10.89,
                        cidade: {
                          id: 4311403,
                          nome: "Lajeado",
                          estado: "Rio Grande do Sul",
                          latitude: -29.4591,
                          longitude: -51.9644,
                          uf: "RS",
                        },
                      },
                      {
                        data_referencia: "2024-03-30T08:00:00",
                        temperatura_max: 31.4,
                        temperatura_min: 17.4,
                        temperatura: 18.7,
                        vento_velocidade_media: 4.7958333333333333333333333333,
                        precipitacao_total: 0.0,
                        cidade: {
                          id: 4311403,
                          nome: "Lajeado",
                          estado: "Rio Grande do Sul",
                          latitude: -29.4591,
                          longitude: -51.9644,
                          uf: "RS",
                        },
                      },
                    ],
                  },
                },
                400: {
                  description: "Erro ao não passar o token",
                  example: {
                    code: "400",
                    message: "Token is Empty",
                    outputErrorMessage: {
                      message: "Token is Empty",
                    },
                  },
                },
                401: {
                  description: "Erro na autenticação do token",
                  example: {
                    code: "401",
                    message: "Token is Invalid",
                    outputErrorMessage: {
                      message: "Token is Invalid",
                    },
                  },
                },
              },
            },
          ],
        },
        {
          title: "Api para Dispositivos onAgro",
          data: [
            {
              CSharpCode: true,
            },
            {
              title: "Lista de dispositivos onAgro",
              curlParam: ["Public Token: O token deve ser passado no header da requisição.",],
              caption:"Lista todos os dispositivos onAgro ativos na conta ou dispositivos que foram compartilhados com o usuário proprietário da chave de API da consulta.",
              curlReturn1: [
                "identifier: Identificador que será utilizado para consultar a leitura do dispositivo.",
                "qrCode: Código identificador unico do dispositivo.",
                "name: Nome que foi dado para o dispositivo onAgro, pelo usuário na hora do cadastro.",
                "type: Tipo de dispositivo se é urbano ou rural.",
                "module: Tipo de modulo(onConnect ou onStation).",
                "addressData: informações sobre onde o dispositivo foi instalado.",
                "name: Nome da localização.",
                "neighborhood: Bairro onde foi instalado o dispositivo.",
                "address: Dados complementares, como rua e número do endereço.",
                "city: Cidade onde foi instalado o dispositivo.",
                "uf: Unidade federativa onde foi instalado o dispositivo.",
                "cep: número do CEP.",
                "status:  Informa o status de operação do dispositivo(1-offline 2-online).",
              ],
              method: "curl",
              url: "curl --location 'https://api.onagro.com.br/api/Devices' --header 'x-api-key: public_token'",
              response: {
                200: {
                  description: "Resposta padrão",
                  example: {
                    data: [
                      {
                        identifier: 1,
                        qrCode: "2023110711470",
                        name: "LabDevice",
                        type: "urbano",
                        module: "onConnect",
                        addressData: {
                          name: "Univates",
                          neighborhood: "Carneiros",
                          address: "Rua Bento Rosa",
                          city: "Lajeado",
                          uf: "Rio Grande do Sul",
                          cep: "95913-470",
                        },
                        status: 2,
                      },
                    ],
                  },
                },
                400: {
                  description: "Erro ao não passar o token",
                  example: {
                    code: "400",
                    message: "Token is Empty",
                    outputErrorMessage: {
                      message: "Token is Empty",
                    },
                  },
                },
                401: {
                  description: "Erro na autenticação do token",
                  example: {
                    code: "401",
                    message: "Token is Invalid",
                    outputErrorMessage: {
                      message: "Token is Invalid",
                    },
                  },
                },
              },
            },
            {
              title: "Última leitura salva",
              curlParam: [
                "DeviceId: Esse campo equivale ao identifier do retorno da lista de dispositivos, ele que vai selecionar o dispositivo que será consultado.",
                "Public Token: O token deve ser passado no header da requisição.",
              ],
              caption:"Consulta qual foi a última leitura realizada por um dispositivo, a leitura é composta por listas de tipos de sensores(estação, sensor de solo, sonda de nível, entre outros...). Cada placa de coleta, pode possuir até 4 sensores mais um sensor de radiofrequência . Nesse exemplo vamos partir da ideia que a placa de coleta possua uma estação e uma sonda de nível.",
              curlReturn1: [
                "data: Representa a atributo chave que possui todas as leituras.",
                "itens: Representa as listas com os tipos de sensores.",
                "station: Representa o objeto que tem as leituras de uma estação.",
              ],
              curlReturnSubitem1: [
                "datetime: Data e hora do momento que a leitura foi realizada.",
                "model: Modelo do da estação.",
                "id: Identificador da estação(uso interno onAgro).",
                "battery: Indica se a bateria da estação  está em boa condições ou precisa ser substituída.",
                "temperature: Temperatura ambiente em Grau Celsius.",
                "humidity: Umidade ambiente em %.",
                "pressure: Pressão em hPa.",
                "wind_direction: Direção do vento em Grau, sendo Zero e 360 o norte.",
                "wind_speed: Velocidade do vento em km/h.",
                "gust_speed: Rajada do vento em km/h.",
                "rainfall: Acumulado de chuva da estação em mm.",
                "uv: Radiação ultravioleta W/m².",
                "uvi: Índice de radiação ultravioleta (0-15).",
                "light: Luminosidade em Lux.",
              ],
              curlReturn2: ["hidro: Representa uma sonda hidrostática."],
              curlReturnSubitem2: [
                "datetime: Data e hora do momento que a leitura foi realizada.",
                "model: Modelo da sonda.",
                "protocol_id: (Uso interno).",
                "protocol:  Tipo de protocolo de comunicação da sonda.",
                "offset: Distância do fundo em relação à sonda.",
                "depth_max: Profundidade limite da sonda(limite do fabricante).",
                "depth: Leitura da sonda.",
                "diameter: Diâmetro se a sonda for instalada em reservatorio circular.",
                "side1: Tamanho do primeiro lado, se a sonda for instalada em reservatório retangular.",
                "side2: Tamanho do segundo lado, se a sonda for instalada em reservatório retangular.",
              ],
              method: "curl",
              url: "curl --location 'https://api.onagro.com.br/api/Devices/<DeviceId>/LastReadDay' --header 'x-api-key: 'public_token'",
              response: {
                200: {
                  description: "Resposta padrão",
                  example: {
                    data: {
                      itens: {
                        station: [
                          {
                            datetime: "2024-04-22T13:35:19",
                            model: "Fineoffset-WH6XX",
                            id: 0,
                            battery: "12,5",
                            temperature: 22.3,
                            humidity: 84,
                            pressure: 1006.41,
                            wind_direction: 14,
                            wind_speed: 2.52,
                            gust_speed: 3.36,
                            rainfall: 47.4,
                            uv: 99,
                            uvi: 0,
                            light: 12632,
                          },
                        ],
                        rain: null,
                        leaf: null,
                        soil: null,
                        power: null,
                        powerSmtp: null,
                        hidro: [
                          {
                            datetime: "2024-04-22T13:35:19",
                            model: "Keller",
                            protocol_id: "0",
                            protocol: "4mA to 20mA",
                            offset: "0",
                            depth_max: "1",
                            depth: "0.17",
                            diameter: null,
                            side1: null,
                            side2: null,
                          },
                        ],
                        pyranometer: null,
                        laser: null,
                        ath: null,
                      },
                    },
                  },
                },
                400: {
                  description: "Erro ao não passar o token",
                  example: {
                    code: "400",
                    message: "Token is Empty",
                    outputErrorMessage: {
                      message: "Token is Empty",
                    },
                  },
                },
                401: {
                  description: "Erro na autenticação do token",
                  example: {
                    code: "401",
                    message: "Token is Invalid",
                    outputErrorMessage: {
                      message: "Token is Invalid",
                    },
                  },
                },
              },
            },
            {
              title: "Leituras do Dia Atual",
              curlParam: [
                "DeviceId: Esse campo equivale ao identifier do retorno da lista de dispositivos, ele que vai selecionar.",
                "Public Token: O token deve ser passado no header da requisição.",
              ],
              caption:
                "Lista todas as leituras disponíveis do dia em que a requisição foi realizada, retornando todas as leituras com um espaçamento de aproximadamente 5 minutos.  ",
              method: "curl",
              curlReturn1: [
                "data: Representa a atributo chave que possui todas as leituras.",
                "itens: Representa as listas com os tipos de sensores.",
                "station: Representa o objeto que tem as leituras de uma estação.",
              ],
              curlReturnSubitem1: [
                "datetime: Data e hora do momento que a leitura foi realizada.",
                "model: Modelo do da estação.",
                "id: Identificador da estação(uso interno onAgro).",
                "battery: indica se a bateria da estação está em boa condições ou precisa ser substituída.",
                "temperature: temperatura ambiente em Grau Celsius.",
                "humidity: Umidade ambiente em %.",
                "pressure: Pressão em hPa.",
                "wind_direction: Direção do vento em Grau, sendo Zero e 360 o norte.",
                "wind_speed: Velocidade do vento em km/h.",
                "gust_speed: Rajada do vento em km/h.",
                "rainfall: Acumulado de chuva da estação em mm.",
                "uv: Radiação ultravioleta W/m².",
                "uvi: Índice de radiação ultravioleta (0-15).",
                "light: Luminosidade em Lux.",
              ],
              url: "curl --location 'https://api.onagro.com.br/api/Devices/<DeviceId>/ReadDayNow' --header 'x-api-key: 'public_token'",
              response: {
                200: {
                  description: "Resposta padrão",
                  example: {
                    data: [
                      {
                        itens: {
                          station: [
                            {
                              datetime: "2024-04-22T00:02:44",
                              model: "Fineoffset-WH6XX",
                              id: 0,
                              battery: "12,5",
                              temperature: 18.1,
                              humidity: 99,
                              pressure: 1009.23,
                              wind_direction: 184,
                              wind_speed: 0,
                              gust_speed: 0,
                              rainfall: 47.4,
                              uv: 2,
                              uvi: 0,
                              light: 0,
                            },
                            {
                              datetime: "2024-04-22T00:13:08",
                              model: "Fineoffset-WH6XX",
                              id: 0,
                              battery: "12,5",
                              temperature: 18.8,
                              humidity: 99,
                              pressure: 1009.23,
                              wind_direction: 219,
                              wind_speed: 1.54,
                              gust_speed: 2.24,
                              rainfall: 47.4,
                              uv: 1,
                              uvi: 0,
                              light: 0,
                            },
                            {
                              datetime: "2024-04-22T00:18:28",
                              model: "Fineoffset-WH6XX",
                              id: 0,
                              battery: "12,5",
                              temperature: 18.9,
                              humidity: 99,
                              pressure: 1009.14,
                              wind_direction: 229,
                              wind_speed: 2.8,
                              gust_speed: 3.36,
                              rainfall: 47.4,
                              uv: 2,
                              uvi: 0,
                              light: 0,
                            },
                            {
                              datetime: "2024-04-22T00:24:04",
                              model: "Fineoffset-WH6XX",
                              id: 0,
                              battery: "12,5",
                              temperature: 19,
                              humidity: 97,
                              pressure: 1008.94,
                              wind_direction: 141,
                              wind_speed: 1.26,
                              gust_speed: 2.24,
                              rainfall: 47.4,
                              uv: 2,
                              uvi: 0,
                              light: 0,
                            },
                            {
                              datetime: "2024-04-22T00:29:08",
                              model: "Fineoffset-WH6XX",
                              id: 0,
                              battery: "12,5",
                              temperature: 19.2,
                              humidity: 96,
                              pressure: 1009.08,
                              wind_direction: 188,
                              wind_speed: 0.98,
                              gust_speed: 1.12,
                              rainfall: 47.4,
                              uv: 2,
                              uvi: 0,
                              light: 0,
                            },
                            {
                              datetime: "2024-04-22T00:34:28",
                              model: "Fineoffset-WH6XX",
                              id: 0,
                              battery: "12,5",
                              temperature: 19.3,
                              humidity: 95,
                              pressure: 1009.12,
                              wind_direction: 187,
                              wind_speed: 3.64,
                              gust_speed: 4.48,
                              rainfall: 47.4,
                              uv: 2,
                              uvi: 0,
                              light: 0,
                            },
                            {
                              datetime: "2024-04-22T00:39:48",
                              model: "Fineoffset-WH6XX",
                              id: 0,
                              battery: "12,5",
                              temperature: 19.4,
                              humidity: 95,
                              pressure: 1009.12,
                              wind_direction: 194,
                              wind_speed: 1.68,
                              gust_speed: 2.24,
                              rainfall: 47.4,
                              uv: 1,
                              uvi: 0,
                              light: 0,
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                400: {
                  description: "Erro ao não passar o token",
                  example: {
                    code: "400",
                    message: "Token is Empty",
                    outputErrorMessage: {
                      message: "Token is Empty",
                    },
                  },
                },
                401: {
                  description: "Erro na autenticação do token",
                  example: {
                    code: "401",
                    message: "Token is Invalid",
                    outputErrorMessage: {
                      message: "Token is Invalid",
                    },
                  },
                },
              },
            },
            {
              title: "Leituras de um Dia Específico",
              curlParam: [
                "DeviceId: Esse campo equivale ao identifier do retorno da lista de dispositivos, ele que vai selecionar o dispositivo que será consultado.",
                "AAAA-MM-DD: Ano, mês e dia, da leitura a ser consultada",
                "Public Token: O token deve ser passado no header da requisição.",
              ],
              caption:
                "Lista todas as leituras disponíveis na data informada na requisição, retornando todas as leituras com um espaçamento de aproximadamente 5 minutos.  ",
              curlReturn1: [
                "data: Representa a atributo chave que possui todas as leituras.",
                "itens: Representa as listas com os tipos de sensores.",
                "station: Representa o objeto que tem as leituras de uma estação.",
              ],
              curlReturnSubitem1: [
                "datetime: Data e hora do momento que a leitura foi realizada.",
                "model: Modelo do da estação.",
                "id: Identificador da estação(uso interno onAgro).",
                "battery: indica se a bateria da estação está em boa condições ou precisa ser substituída.",
                "temperature: temperatura ambiente em Grau Celsius.",
                "humidity: Umidade ambiente em %.",
                "pressure: Pressão em hPa.",
                "wind_direction: Direção do vento em Grau, sendo Zero e 360 o norte.",
                "wind_speed: Velocidade do vento em km/h.",
                "gust_speed: Rajada do vento em km/h.",
                "rainfall: Acumulado de chuva da estação em mm.",
                "uv: Radiação ultravioleta W/m².",
                "uvi: Índice de radiação ultravioleta (0-15).",
                "light: Luminosidade em Lux.",
              ],
              method: "curl",
              url: "curl --location 'https://api.onagro.com.br/api/Devices/<DeviceId>/ReadDay/<AAAA-MM-DD>' --header 'x-api-key: 'public_token'",
              response: {
                200: {
                  description: "Resposta padrão",
                  example: {
                    data: {
                      itens: {
                        station: [
                          {
                            datetime: "2024-04-19T00:00:41",
                            model: "Fineoffset-WH6XX",
                            id: 0,
                            battery: "12,5",
                            temperature: 16.3,
                            humidity: 94,
                            pressure: 1014.54,
                            wind_direction: 226,
                            wind_speed: 1.54,
                            gust_speed: 2.24,
                            rainfall: 46.8,
                            uv: 1,
                            uvi: 0,
                            light: 0,
                          },
                          {
                            datetime: "2024-04-19T00:06:01",
                            model: "Fineoffset-WH6XX",
                            id: 0,
                            battery: "12,5",
                            temperature: 16.6,
                            humidity: 93,
                            pressure: 1014.34,
                            wind_direction: 124,
                            wind_speed: 1.96,
                            gust_speed: 2.24,
                            rainfall: 46.8,
                            uv: 2,
                            uvi: 0,
                            light: 0,
                          },
                          {
                            datetime: "2024-04-19T00:11:05",
                            model: "Fineoffset-WH6XX",
                            id: 0,
                            battery: "12,5",
                            temperature: 16.3,
                            humidity: 94,
                            pressure: 1014.43,
                            wind_direction: 166,
                            wind_speed: 0.98,
                            gust_speed: 1.12,
                            rainfall: 46.8,
                            uv: 2,
                            uvi: 0,
                            light: 0,
                          },
                          {
                            datetime: "2024-04-19T00:16:25",
                            model: "Fineoffset-WH6XX",
                            id: 0,
                            battery: "12,5",
                            temperature: 16.4,
                            humidity: 93,
                            pressure: 1014.23,
                            wind_direction: 52,
                            wind_speed: 3.08,
                            gust_speed: 4.48,
                            rainfall: 46.8,
                            uv: 2,
                            uvi: 0,
                            light: 0,
                          },
                          {
                            datetime: "2024-04-19T00:21:45",
                            model: "Fineoffset-WH6XX",
                            id: 0,
                            battery: "12,5",
                            temperature: 16.6,
                            humidity: 92,
                            pressure: 1014.07,
                            wind_direction: 179,
                            wind_speed: 1.82,
                            gust_speed: 2.24,
                            rainfall: 46.8,
                            uv: 1,
                            uvi: 0,
                            light: 0,
                          },
                        ],
                      },
                    },
                  },
                },
                400: {
                  description: "Erro ao não passar o token",
                  example: {
                    code: "400",
                    message: "Token is Empty",
                    outputErrorMessage: {
                      message: "Token is Empty",
                    },
                  },
                },
                401: {
                  description: "Erro na autenticação do token",
                  example: {
                    code: "401",
                    message: "Token is Invalid",
                    outputErrorMessage: {
                      message: "Token is Invalid",
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    };
  },
};
</script>
