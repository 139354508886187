<template>
  <div class="row">
    <h1 class="title">Informações dispositivo ({{ form.crud.form.nome }})</h1>
    <!--Ultima Leitura-->
    <div
      style="display: flex; justify-content: space-between"
      v-if="form.crud.form.ultimaLeitura != null"
    >
      <div style="margin-bottom: 5px; width: 38%">
        <span class="header-last-reading">Última leitura</span>
      </div>
      <div style="display: flex; justify-content: space-around">
        <span class="date">
          {{
            new Date(form.crud.form.ultimaLeitura)
              .toLocaleString()
              .split(", ")[0]
          }}
        </span>
        <img
          style="margin-bottom: 29px"
          src="../../components/mapa/src/components/mapa/stationIcon/Relogio.svg"
          alt="clock"
        />
        <span class="datetime">
          {{
            new Date(form.crud.form.ultimaLeitura).toLocaleTimeString('pt-BR', {
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </span>
      </div>
    </div>
    <!--Station Info-->
    <div
      class="station-info-wrapper-first"
      v-if="form.crud.form.ultimaLeitura != null"
    >
      <div class="station-info-wrapper-second">
        <div class="station-info-row-wrapper">
          <div class="station-info-name-wrapper">
            <div class="station-info-name">Versão onConnect</div>
          </div>
          <div class="station-info-value-wrapper">
            <div class="station-info-value">
              {{ form.crud.form.versaoConnect }}
            </div>
          </div>
        </div>
        <div class="station-info-row-wrapper">
          <div class="station-info-name-wrapper">
            <div class="station-info-name">Temperatura onConnect</div>
          </div>
          <div class="station-info-value-wrapper">
            <div class="station-info-value">
              {{ form.crud.form.temperatura }}
            </div>
          </div>
        </div>
        <div class="station-info-row-wrapper">
          <div class="station-info-name-wrapper">
            <div class="station-info-name">Versão Firmeware</div>
          </div>
          <div class="station-info-value-wrapper">
            <div class="station-info-value">
              {{ form.crud.form.versaoFirmware }}
            </div>
          </div>
        </div>
        <div class="station-info-row-wrapper">
          <div class="station-info-name-wrapper">
            <div class="station-info-name">Wifi RSSI</div>
          </div>
          <div class="station-info-value-wrapper">
            <div class="station-info-value">
              {{ form.crud.form.wifi }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="station-info-wrapper-first"
      v-if="form.crud.form.ultimaLeitura != null"
    >
      <div class="station-info-wrapper-second">
        <div class="station-info-row-wrapper">
          <div class="station-info-name-wrapper">
            <div class="station-info-name">UTC</div>
          </div>
          <div class="station-info-value-wrapper">
            <div class="station-info-value">
              {{ form.crud.form.utc }}
            </div>
          </div>
        </div>
        <div class="station-info-row-wrapper">
          <div class="station-info-name-wrapper">
            <div class="station-info-name">Bateria onConnect (v)</div>
          </div>
          <div class="station-info-value-wrapper">
            <div class="station-info-value">
              {{ form.crud.form.bateria }}
            </div>
          </div>
        </div>
        <div class="station-info-row-wrapper">
          <div class="station-info-name-wrapper">
            <div class="station-info-name">Origem</div>
          </div>
          <div class="station-info-value-wrapper">
            <div class="station-info-value">
              {{ form.crud.form.origem }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Geral-->
    <div class="header-wrapper-first">
      <div class="header-wrapper-second">
        <div class="header">Geral</div>
      </div>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label">Nome <span class="text-danger">*</span></label>
      <input type="text" class="form-control" v-model="form.crud.form.nome" />
      <Error v-if="form.crud.error" :error="form.crud.error.nome"></Error>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >QR Code <span class="text-danger">*</span></label
      >
      <input
        disabled
        type="text"
        class="form-control"
        v-model="form.crud.form.qrcode"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.qrcode"></Error>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Modelo <span class="text-danger">*</span></label
      >
      <input
        disabled
        type="text"
        class="form-control"
        v-model="form.crud.form.modelo"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.modelo"></Error>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Usuario <span class="text-danger">*</span></label
      >
      <input
        disabled
        type="text"
        class="form-control"
        v-model="form.crud.form.usuario"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.usuario"></Error>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Firmware<span class="text-danger">*</span></label
      >
      <SelectData
        :disabled="user"
        url="/AdminDevices/DevicesFirmware"
        field="nome"
        label="nome"
        empty="Selecione"
        :value="
          form.crud.form.firmware == undefined ? null : form.crud.form.firmware
        "
        @change="clearTypeOrganization(form)"
        @value="form.crud.form.firmware = $event"
      >
      </SelectData>
      <Error v-if="form.crud.error" :error="form.crud.error.firmware"></Error>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Status <span class="text-danger">*</span></label
      >
      <select
        disabled
        name="status"
        id="status"
        v-model="form.crud.form.status"
        class="form-select"
      >
        <option value="3" :selected="form.crud.form.status == 3">Online</option>
        <option value="1" :selected="form.crud.form.status == 1">
          Pendente
        </option>
        <option value="2" :selected="form.crud.form.status == 2">
          Offline
        </option>
      </select>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Situação <span class="text-danger">*</span></label
      >
      <select
        :disabled="user"
        name="situacao"
        id="situacao"
        v-model="form.crud.form.situacao"
        class="form-select"
      >
        <option :value="2" :selected="form.crud.form.situacao == 0">
          Bloqueada
        </option>
        <option :value="1" :selected="form.crud.form.situacao == 1">
          Ativa
        </option>
      </select>
      <Error v-if="form.crud.error" :error="form.crud.error.situacao"></Error>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Limite de compartilhamento <span class="text-danger">*</span></label
      >
      <input
        :disabled="user"
        type="text"
        class="form-control"
        v-model="form.crud.form.limite"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.limite"></Error>
    </div>
    <div
      style="
        width: 100%;
        height: 100%;
        padding-right: 20px;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        display: inline-flex;
      "
    >
      <div class="toggle-switch-outer-first">
        <div class="toggle-switch-outer-second">
          <label class="toggle-switch">
            <input
              :disabled="user"
              type="checkbox"
              v-model="form.crud.form.premium"
            />
            <span class="slider"></span>
          </label>
          <div style="font-size: 14px" class="toggle-switch-text">Premium</div>
        </div>
        <div v-if="!user" style="font-size: 12px" class="toggle-switch-text">
          Data de ativação: {{ localeString(form.crud.form.ativacao) }}
        </div>
      </div>
      <div v-if="!user" class="toggle-switch-outer-first">
        <div class="toggle-switch-outer-second">
          <label class="toggle-switch">
            <input type="checkbox" v-model="form.crud.form.oculto" />
            <span class="slider"></span>
          </label>
          <div style="font-size: 14px" class="toggle-switch-text">
            Estação oculta
          </div>
        </div>
      </div>
    </div>
    <!--Endereço-->
    <div class="header-wrapper-first">
      <div class="header-wrapper-second">
        <div class="header">Endereço e instalação</div>
      </div>
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label">Cep <span class="text-danger">*</span></label>
      <input
        type="text"
        v-mask="'#####-###'"
        class="form-control"
        v-model="form.crud.form.cep"
      />
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label">UF <span class="text-danger">*</span></label>
      <input type="text" class="form-control" v-model="form.crud.form.uf" />
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Municipio <span class="text-danger">*</span></label
      >
      <input
        type="text"
        class="form-control"
        v-model="form.crud.form.municipio"
      />
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Endereço <span class="text-danger">*</span></label
      >
      <input
        type="text"
        class="form-control"
        v-model="form.crud.form.endereco"
      />
    </div>
    <div class="mb-3 col-md-3 col-12">
      <label class="form-label"
        >Bairro <span class="text-danger">*</span></label
      >
      <input type="text" class="form-control" v-model="form.crud.form.bairro" />
    </div>
    <!-- Mapa -->
    <div class="mb-3 col-md-12 col-12">
      <label class="form-label">Local <span class="text-danger">*</span></label>
      <Local
        @value="form.crud.form.localId = $event"
        :value="form.crud.form.localId"
      ></Local>
      <Error v-if="form.crud.error" :error="form.crud.error.localId"></Error>
    </div>
    <!-- Compartilhamento -->
    <div style="display: flex">
      <label class="header-device">Dispositivo compartilhado com:</label>
      <div style="margin-left: auto">
        <LoadButton
          style="margin-bottom: 25px; background-color: #006035"
          ref="btn_gerar"
          @click="abrirModalCompartilhar(form.crud.form.id)"
          class="btn btn-success"
          text="Compartilhar"
        />
      </div>
    </div>
    <div style="display: flex">
      <div style="width: 33.3%" class="tabela-horario tabela-cabecalho-device">
        Usuário
      </div>
      <div
        style="width: 33.3%"
        class="tabela-info-device tabela-cabecalho-device"
      >
        Código
      </div>
      <div
        style="width: 33.3%; border-radius: 0px 10px 0px 0px"
        class="tabela-info-device tabela-cabecalho-device"
      >
        Excluir
      </div>
    </div>
    <div v-if="sharedUsers.length > 0">
      <div
        v-for="(user, code) in sharedUsers"
        :key="code"
        class="sharedUsers"
        style="
          width: 100%;
          display: flex;
          height: 31px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          text-align: center;
        "
      >
        <span class="nameText" style="width: 33.3%">{{ user.Nome }}</span>

        <span class="tokenText" style="width: 33.3%; margin-left: 10px">{{
          user.token
        }}</span>
        <i
          class="tf-icons bx bx-trash text-danger"
          style="width: 33.3%; cursor: pointer"
          @click="RemoverUsuario(user)"
        ></i>
      </div>
    </div>
    <div style="text-align: center; margin-top: 10px" v-else>
      Nenhum Usuário Compartilhado
    </div>
  </div>
  <div class="modal fade" tabindex="-1" ref="modal_compartilhamento">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <div style="display: inline-block">
            <h3 class="modal-title text-white mb-2">
              Compartilhamento de Dispositivo
            </h3>
            <h6 class="modal-title text-white mb-2" v-if="!this.limiteAtingido">
              Você tem no máximo {{ this.limite }} compartilhamentos por
              propriedade.
            </h6>
            <h6 class="modal-title text-white mb-2" v-if="this.limiteAtingido">
              O numero de Compartilhamentos foi excedido.
            </h6>
          </div>
          <button
            type="button"
            class="btn-close"
            style="margin-bottom: 40px"
            @click="fecharModalCompartilhar"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3 col-md-8 col-12" v-if="!this.limiteAtingido">
            <label class="form-label"
              >Codigo do usuário<span class="text-danger">*</span></label
            >
            <input
              :disabled="this.limiteAtingido"
              type="text"
              class="form-control"
              onfocusout="myFunction()"
              v-model="this.shareModal.CodigoCompartilhado"
            />
            <span v-if="!fetched" style="font-size: 12px"
              >Informe o nº do Codigo do Usuário</span
            >
          </div>
          <div v-if="fetched">
            <span style="font-size: 12px"
              >Voce quer compartilhar com {{ this.sharedName }}</span
            >
            <button class="btn btn-white p-0" @click="this.fetched = false">
              <i class="bx bx-x text-danger"></i>
            </button>
            <button class="btn btn-white p-0" @click="CompartilharModal()">
              <i class="bx bx-check text-success"></i>
            </button>
          </div>
          <div
            v-for="(d, i) in shared"
            :key="i"
            style="
              width: auto;
              height: auto;
              overflow: hidden;
              justify-content: flex-start;
              align-items: flex-start;
              display: inline-flex;
            "
          >
            <div
              style="
                margin-top: 3px;
                margin-right: 8px;
                width: auto;
                height: 100%;
                padding-left: 3px;
                padding-right: 3px;
                padding-top: 2px;
                padding-bottom: 2px;
                background: #7d9965;
                border-radius: 5px;
                justify-content: flex-start;
                align-items: center;
                display: flex;
                gap: 5px;
                word-wrap: break-all;
              "
            >
              <div
                style="
                  color: #f0f5ea;
                  font-size: 14px;
                  font-weight: 500;
                  white-space: nowrap;
                "
              >
                {{ d.Nome }}
              </div>
              <div
                style="
                  width: 12px;
                  height: 12px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  display: inline-flex;
                "
              >
                <button
                  class="btn btn-white p-0"
                  @click="RemoverUsuarioModal(d.token)"
                >
                  <i class="bx bx-x text-danger"></i>
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="modal-footer" v-if="!this.limiteAtingido">
            <LoadButton
              style="display: flex; margin-left: 25px; background: #006035"
              @click="BuscarUsuarioModal()"
              ref="btn_finalizar"
              class="btn btn-success"
              text="Buscar"
              loadtext="Buscando..."
            >
            </LoadButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Alert ref="alert"></Alert>
</template>

<script>
import Local from "../../components/Local.vue";
import SelectData from "../../components/crud/input/SelectData.vue";
import { mask } from "vue-the-mask";
import LoadButton from "../../components/form/LoadButton.vue";
import Alert from "../../components/Alert.vue";

export default {
  props: ["form", "user"],
  directives: { mask },
  components: {
    Local,
    SelectData,
    LoadButton,
    Alert,
  },

  data() {
    return {
      sharedUsers: [],
      share: {
        QrCodeId: this.form.crud.form.id,
        CodigoCompartilhado: null,
      },
      removeUser: {
        QrCodeId: this.form.crud.form.id,
        CodigoCompartilhado: null,
      },
      shared: [],
      limite: null,
      sharedName: null,
      fetched: false,
      limiteAtingido: false,
      shareModal: {
        QrCodeId: null,
        CodigoCompartilhado: null,
      },
      sharedUser: {
        QrCodeId: null,
        CodigoCompartilhado: null,
      },
    };
  },

  methods: {
    localeString(date) {
      const dateString = new Date(date);

      const dd = String(dateString.getUTCDate()).padStart(2, "0");
      const mm = String(dateString.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based
      const yyyy = dateString.getUTCFullYear();

      const formattedDate = `${dd}/${mm}/${yyyy}`;
      return formattedDate;
    },
    UsuariosVinculados() {
      this.urlPost("/Devices/FetchSharedUserName", this.share)
        .then((e) => {
          this.sharedUsers = e.data.Usuario;
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Código de Usuario Invalido!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
    RemoverUsuario(user) {
      this.removeUser.QrCodeId;
      this.removeUser.CodigoCompartilhado = user.token;
      this.urlPost("/AdminUser/RemoveSharedUser", this.removeUser)
        .then((e) => {
          this.UsuariosVinculados();
          this.UsuariosVinculadosModal();
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível Remover do Compartilhamento!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
    abrirModalCompartilhar(id) {
      $(this.$refs.modal_compartilhamento).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.shareModal.QrCodeId = id;
      this.UsuariosVinculadosModal(this.shared);
    },
    fecharModalCompartilhar() {
      $(this.$refs.modal_compartilhamento).modal("hide");
    },
    CompartilharModal() {
      console.log(this.shareModal);
      this.urlPost("/Devices/Share", this.shareModal)
        .then((e) => {
          this.UsuariosVinculados();
          this.error = null;
          this.$refs.alert.show(
            "Compartilhando",
            "Compartilhado com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          this.shared.push(e.data.Usuario);
          this.fetched = false;
          this.limiteAtingido = e.data.LimiteAtingido;
          this.shareModal.CodigoCompartilhado = "";
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.shareModal.CodigoCompartilhado = "";
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Você ja Compartilhou com este Usuario!",
              null,
              3000,
              "bg-danger"
            );
            this.fetched = false;
          }
        });
    },
    RemoverUsuarioModal(token) {
      (this.sharedUser.CodigoCompartilhado = token),
        (this.sharedUser.QrCodeId = this.shareModal.QrCodeId),
        this.urlPost("/AdminUser/RemoveSharedUser", this.sharedUser)
          .then((e) => {
            this.UsuariosVinculados();
            this.shared = this.shared.filter(
              (item) => JSON.stringify(item) !== JSON.stringify(e.data.Usuario)
            );
            this.limiteAtingido = e.data.LimiteAtingido;
          })
          .catch((e) => {
            if (e.response.status == 400) {
              this.error = e.response.data;
              this.$refs.alert.show(
                "Erro ",
                "Erro no processo",
                null,
                3000,
                "bg-danger"
              );
            } else {
              this.$refs.alert.show(
                "Erro " + e.response.status,
                "Não foi possível Remover do Compartilhamento!",
                null,
                3000,
                "bg-danger"
              );
            }
          });
    },
    BuscarUsuarioModal() {
      console.log(this.shareModal);
      this.urlPost("/Devices/FetchUserNameToShare", this.shareModal)
        .then((e) => {
          this.fetched = true;
          this.sharedName = e.data.NomeDispositivo;
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          }
          if (e.response.status == 406) {
            this.error = e.response.data;
            this.share.CodigoCompartilhado = "";
            this.$refs.alert.show(
              "Erro ",
              "Não Pode Compartilhar com Você",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.shareModal.CodigoCompartilhado = "";
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Código de Usuario Invalido!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
    UsuariosVinculadosModal() {
      this.urlPost("/Devices/FetchSharedUserName", this.shareModal)
        .then((e) => {
          this.shared = e.data.Usuario;
          this.limite = e.data.Limite;
          this.limiteAtingido = e.data.LimiteAtingido;
          this.sharedUser.CodigoCompartilhado = e.data.Usuario;
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Código de Usuario Invalido!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
  },
  beforeMount() {
    this.UsuariosVinculados();
  },
};
</script>
<style>
.date {
  margin-right: 5px;
  margin-left: 25px;
}
.datetime {
  margin-left: 2px;
}
.title {
  width: 100%;
  color: #393939;
  font-size: 20px;
  font-weight: 700;
  word-wrap: break-word;
}
.tabela-info-device {
  background: #53703c;
}
.tabela-cabecalho-device {
  display: flex;
  height: 40px;
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: white;
}

.header-last-reading {
  margin-left: 3px;
  font-weight: 600;
  font-size: 16px;
}
.station-info-row-wrapper .station-info-wrapper-first {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}
.station-info-wrapper-second {
  border-radius: 10px;
  overflow: hidden;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}
.station-info-row-wrapper {
  width: auto;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  margin-bottom: 5px;
}
.station-info-name-wrapper {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #53703c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}
.station-info-name {
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
}
.station-info-value-wrapper {
  border-radius: 0px 10px 10px 0px;
  padding: 10px;
  background: #7d9965;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}
.station-info-value {
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
}
.header-wrapper-first {
  width: 100%;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}
.header-wrapper-second {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
}
.header {
  color: #393939;
  font-weight: 600;
  word-wrap: break-word;
}
.header-device {
  color: #393939;
  font-weight: 600;
  word-wrap: break-word;
  margin-bottom: 10px;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 35.5px;
  height: 20.94px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f5ea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 10.32px;
  border: 0.92px rgba(83, 112, 60, 0.5) solid;
  background-color: #d1d1d1;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20.35px;
  width: 19.35px;
  background-color: #919191;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f0f5ea;
}
input:checked + .slider:before {
  background-color: #78a64e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16.05px);
  -ms-transform: translateX(16.05px);
  transform: translateX(16.05px);
}

.toggle-switch-outer-first {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
}
.toggle-switch-outer-second {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}
.toggle-switch-text {
  color: #555555;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}
@media only screen and (max-width: 821px) {
  .form-body {
    padding-left: 5px;
    padding-right: 5px;
  }
  .header {
    font-size: 14px;
  }
  .header-device {
    font-size: 14px;
  }
  .station-info-name {
    font-size: 12px;
  }
  .station-info-value {
    font-size: 12px;
  }
  .sharedUsers {
    margin-top: 3px;
  }
  .station-info-row-wrapper {
    margin-left: 5px;
  }
  .form-control {
    font-size: 12px;
  }
  .form-select {
    font-size: 12px;
  }
  .date {
    font-size: 12px;
  }
  .datetime {
    font-size: 12px;
  }
  .tabela-cabecalho-device {
    font-size: 12px;
  }
  .tabela-cabecalho-device {
    font-size: 12px;
  }
  .header-last-reading {
    font-size: 14px;
  }
  .form-label {
    font-size: 12px;
  }
}
@media only screen and (min-width: 821px) {
  .header {
    font-size: 16px;
  }
  .header-device {
    font-size: 16px;
  }
  .station-info-wrapper-second {
    display: flex;
  }
}
@media only screen and (max-width: 427px) {
  .station-info-name-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 602px) {
  .tokenText {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 602px) {
  .tokenText {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .nameText {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
