<template>
  <div class="termos-politica" style="line-height: normal; padding: 15px;">
    <button class="form-back-button d-inline md-d-none">
      <i class="menu-icon tf-icons bx bx-left-arrow-alt m-0" onclick="window.location = '/'"></i></button>
    <div class="row">
      <div style="flex: 1">
        <h2 style="text-align: center; margin-top: 10px">TERMOS DE USO</h2>
      </div>
    </div>

    <p>Estes são os termos que determinarão nossa relação.</p>

    <p>
      Antes de utilizar a plataforma onAgro, é necessário que você leia, entenda
      e concorde com estes termos.
    </p>

    <br />

    <h3 style="text-align: center">ACEITE DOS TERMOS</h3>

    <p>
      Este Contrato de Licença de Usuário Final (“EULA”) é um acordo legal entre
      o licenciado (pessoa física ou jurídica) (o “LICENCIADO”) e a ALVAP
      Engenharia LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob
      n. 10.758.700/0001-01, com sede na DR. Chaves, 439, Térreo - Centro, CEP
      95980-000, ANTA GORDA/RS, (a “LICENCIANTE”) para uso do programa de
      computador denominado onAgro, disponibilizado neste ato pela LICENCIANTE
      (o “SOFTWARE”) por meio do site “onagro.com.br e onagro.com.br” (“Site”),
      pelo determinado pelo LICENCIADO no ato do licenciamento do SOFTWARE,
      compreendendo o programa de computador e podendo incluir os meios físicos
      associados, bem como quaisquer materiais impressos e qualquer documentação
      online ou eletrônica. Ao utilizar o SOFTWARE, mesmo que parcialmente ou a
      título de teste, o LICENCIADO estará vinculado aos termos deste EULA,
      concordando com suas disposições, principalmente com relação ao
      CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E
      TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do LICENCIADO pela LICENCIANTE,
      necessárias para a integral execução das funcionalidades ofertadas pelo
      SOFTWARE. Em caso de discordância com os termos aqui apresentados, a
      utilização do SOFTWARE deve ser imediatamente interrompida pelo
      LICENCIADO.
    </p>

    <h4>Da Propriedade Intelectual</h4>

    <p>
      O LICENCIADO não adquire, pelo presente instrumento ou pela utilização do
      SOFTWARE, nenhum direito de propriedade intelectual ou outros direitos
      exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou
      quaisquer direitos sobre informações confidenciais ou segredos de negócio,
      bem como todo o conteúdo disponibilizado no Site, incluindo, mas não se
      limitando a textos, gráficos, imagens, logotipos, ícones, fotografias,
      conteúdo editorial, notificações, softwares e qualquer outro material,
      sobre ou relacionados ao SOFTWARE ou nenhuma parte dele. O LICENCIADO
      também não adquire nenhum direito sobre ou relacionado ao SOFTWARE ou
      qualquer componente dele, além dos direitos expressamente licenciados ao
      LICENCIADO sob o presente EULA ou em qualquer outro contrato mutuamente
      acordado por escrito entre o LICENCIADO e a LICENCIANTE. Quaisquer
      direitos não expressamente concedidos sob o presente instrumento são
      reservados.
    </p>

    <p>
      Também será de propriedade exclusiva da LICENCIANTE ou está devidamente
      licenciado, todo o conteúdo disponibilizado no Site, incluindo, mas não se
      limitando a, textos, gráficos, imagens, logos, ícones, fotografias,
      conteúdo editorial, notificações, softwares e qualquer outro material.
    </p>

    <h4>Declarações do LICENCIADO</h4>

    <p>
      O LICENCIADO declara ter pleno conhecimento dos direitos e obrigações
      decorrentes do presente EULA, constituindo este instrumento o acordo
      completo entre as partes. Declara, ainda, ter lido, compreendido e aceito
      todos os seus termos e condições.
    </p>

    <p>
      O LICENCIADO declara que foi devidamente informado da política de
      confidencialidade e ambientes de proteção de informações confidenciais,
      dados pessoais e registros de acesso da LICENCIANTE, consentindo livre e
      expressamente às ações de coleta, uso, armazenamento e tratamento das
      referidas informações e dados.
    </p>

    <p>
      O LICENCIADO declara estar ciente de que as operações que correspondam à
      aceitação do presente EULA, de determinadas condições e opções, bem como
      eventual rescisão do presente instrumento e demais alterações, serão
      registradas nos bancos de dados da LICENCIANTE, juntamente com a data e
      hora em que foram realizadas pelo LICENCIADO, podendo tais informações
      serem utilizadas como prova pelas partes, independentemente do cumprimento
      de qualquer outra formalidade.
    </p>

    <h4>Licença de Uso do Software</h4>

    <p>
      Sujeito aos termos e condições aqui estabelecidos, este EULA concede ao
      LICENCIADO uma licença revogável, não exclusiva e intransferível para uso
      do SOFTWARE. O LICENCIADO não poderá utilizar e nem permitir que o
      SOFTWARE seja utilizado para outra finalidade que não seja o processamento
      de suas informações ou de pessoas jurídicas indicadas pelo LICENCIADO no
      ato do cadastramento, exceto caso o LICENCIADO seja empresa de CONSULTORIA
      AGRÍCOLA que utiliza o SOFTWARE para processar informações de seus
      clientes, observados os limites estabelecidos neste EULA. Esta licença não
      implica a capacidade de acessar outros softwares além daqueles
      originalmente localizados no SOFTWARE. Em nenhuma hipótese o LICENCIADO
      terá acesso ao código fonte do SOFTWARE ora licenciado, por este se tratar
      de propriedade intelectual da LICENCIANTE.
    </p>

    <h4>Das Restrições</h4>

    <p>
      Em hipótese alguma é permitido ao LICENCIADO ou a terceiros, de forma
      geral:- Copiar, ceder, sublicenciar, vender, dar em locação ou em
      garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou
      parcialmente, sob quaisquer modalidades, gratuita ou onerosamente,
      provisória ou permanentemente, o SOFTWARE objeto deste EULA, assim como
      seus módulos, partes, manuais ou quaisquer informações a ele relativas;-
      Retirar ou alterar, total ou parcialmente, os avisos de reserva de direito
      existente no SOFTWARE e na documentação;- Praticar engenharia reversa,
      descompilação ou desmontagem do SOFTWARE.
    </p>

    <h4>Do Prazo</h4>

    <p>
      O presente EULA entra em vigor na data de seu aceite pelo LICENCIADO e
      vigorará pelo prazo do Plano contratado pelo LICENCIADO. Este EULA será
      automaticamente renovado por iguais períodos caso o LICENCIADO não se
      manifeste expressamente em contrário. Este EULA poderá ser rescindido
      conforme estabelecido abaixo nesse instrumento.
    </p>

    <h4>Da Remuneração e Forma de Pagamento</h4>

    <p>
      O LICENCIADO deverá pagar à LICENCIANTE o valor do plano de licenciamento
      escolhido e de acordo com a periodicidade definida entre as opções de
      pagamento disponibilizadas ao LICENCIADO no ato da contratação. Caso o
      LICENCIADO, no decorrer da vigência do presente instrumento, opte por
      outro plano de licenciamento, os valores serão alterados de acordo com o
      respectivo plano escolhido.
    </p>

    <p>
      A falta de pagamento de quaisquer valores nas respectivas datas de
      vencimento não acarretará na rescisão automática do EULA, mas causará a
      suspensão do acesso do LICENCIADO ao SOFTWARE até que as pendências
      financeiras tenham sido regularizadas. O acesso ao SOFTWARE somente será
      restabelecido após a identificação pela LICENCIANTE do pagamento integral
      de todos os valores devidos enquanto o mesmo esteve suspenso. A
      identificação poderá ocorrer em até dois dias úteis após a data de
      pagamento pelo LICENCIADO. Caso o LICENCIADO não resolva a pendência
      financeira no prazo de 60 (sessenta) dias contados do vencimento do valor
      não pago, a LICENCIANTE se reserva o direito de rescindir o presente EULA
      e apagar de forma definitiva e irrecuperável todas as informações do
      LICENCIADO que por ventura estejam armazenadas no SOFTWARE.
    </p>

    <p>
      Os valores dos planos de licenciamento estabelecidos no ato do
      licenciamento do SOFTWARE serão atualizados anualmente pelo IGPM-FGV
      acumulado no período, ou no caso de extinção deste, de outro índice
      oficial que venha a substituí-lo.
    </p>

    <h4>Restituição das Informações</h4>

    <p>
      Suspenso o acesso do LICENCIADO ao SOFTWARE, a LICENCIANTE manterá as
      informações do LICENCIADO armazenadas no SOFTWARE pelo período de 60
      (sessenta) dias, contados da suspensão de acesso. Durante este período, a
      LICENCIANTE tornará as informações do LICENCIADO disponíveis para serem
      extraídas do SOFTWARE em formato .csv.
    </p>

    <p>
      Conforme descrito na cláusula 6 acima, passados 60 (sessenta) dias da
      suspensão do acesso do LICENCIADO ao SOFTWARE, todas as INFORMAÇÕES do
      LICENCIADO, incluindo as INFORMAÇÕES PESSOAIS, em poder da LICENCIANTE
      serão excluídos permanentemente do banco de dados da LICENCIANTE,
      independentemente de terem sido extraídas ou não pelo LICENCIADO.
    </p>

    <p>
      Não obstante o disposto acima, as informações referentes à data e hora de
      acesso e ao endereço de protocolo de internet utilizado pelo LICENCIADO
      para acessar o Site e o SOFTWARE permanecerão armazenadas pela LICENCIANTE
      por 6 (meses) a contar da data de cada acesso realizado, independentemente
      do término da relação jurídica e comercial entre a LICENCIANTE e o
      LICENCIADO, em cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014,
      podendo ser armazenados por um período maior de tempo mediante ordem
      judicial.
    </p>

    <h4>Das Obrigações do Licenciado</h4>

    <p>Obriga-se o LICENCIADO a:</p>

    <p>
      - Manter pessoal treinado para a operação do SOFTWARE e para a comunicação
      com a LICENCIANTE e prover, sempre que ocorrerem quaisquer problemas com o
      SOFTWARE, toda a documentação, relatórios e demais informações que relatem
      as circunstâncias em que os problemas ocorreram, objetivando facilitar e
      agilizar os trabalhos;
    </p>

    <p>
      - Manter, às suas expensas, linha de telecomunicação, modem, software de
      comunicação, endereço de correio eletrônico e outros recursos necessários
      à comunicação com a LICENCIANTE;
    </p>

    <p>
      - Responder pelas INFORMAÇÕES inseridas no SOFTWARE, pelo cadastramento,
      permissões, senhas e modo de utilização de seus usuários. A LICENCIANTE em
      hipótese alguma será responsável pelo conteúdo (INFORMAÇÕES, senhas,
      cópias de informações, etc.) incluído no SOFTWARE, não sendo, portanto,
      estas INFORMAÇÕES revisadas em momento algum. A responsabilidade pelas
      INFORMAÇÕES inseridas no SOFTWARE é sempre do LICENCIADO;
    </p>

    <p>
      - Certificar-se de que não está proibido por determinação legal e/ou
      contratual de passar INFORMAÇÕES e INFORMAÇÕES PESSOAIS, bem como
      quaisquer outros dados à LICENCIANTE, necessários para a execução do
      serviço oferecido por este EULA;
    </p>

    <p>
      - Não utilizar o SOFTWARE de qualquer forma que possa implicar em ato
      ilícito, infração, violação de direitos ou danos à LICENCIANTE ou
      terceiros, incluindo, mas não se limitando ao uso para invasão de
      dispositivo informático com o objetivo de obter, adulterar ou destruir
      dados ou informações sem a autorização expressa do titular de tais dados
      ou do dispositivo ou servidor nos quais estes estejam armazenados;
    </p>

    <p>
      - Não publicar, enviar ou transmitir qualquer arquivo que contenha vírus,
      worms, cavalos de troia ou qualquer outro programa que possa contaminar,
      destruir ou interferir no bom funcionamento do SOFTWARE;
    </p>

    <p>
      - Informar a LICENCIANTE sempre que houver qualquer alteração das
      INFORMAÇÕES fornecidas à LICENCIANTE e que possam impedir, limitar ou
      prejudicar o acesso da LICENCIANTE às INFORMAÇÕES necessárias para a
      execução das funcionalidades ofertadas pelo SOFTWARE;
    </p>

    <p>
      - Caso o LICENCIADO acredite que seu login e senha de acesso ao SOFTWARE
      tenham sido roubados ou sejam de conhecimento de outras pessoas, por
      qualquer razão, o LICENCIADO deverá imediatamente comunicar tal fato à
      LICENCIANTE, sem prejuízo da alteração da sua senha imediatamente, por
      meio do SOFTWARE.
    </p>

    <h4>Das Obrigações do Licenciante</h4>

    <p>Obriga-se a LICENCIANTE a:</p>

    <p>
      - A LICENCIANTE garante ao LICENCIADO que o SOFTWARE deverá funcionar
      regularmente, se respeitadas as condições de uso definidas na
      documentação. Na ocorrência de falhas de programação (“bugs”), a
      LICENCIANTE obrigar-se-á a corrigir tais falhas, podendo a seu critério
      substituir a cópia dos Programas com falhas por cópias corrigidas;
    </p>

    <p>
      - Fornecer, ato contínuo ao aceite deste EULA, acesso ao SOFTWARE durante
      a vigência deste EULA;
    </p>

    <p>
      - Suspender o acesso ao SOFTWARE do LICENCIADO que esteja desrespeitando
      as regras de conteúdo aqui estabelecidas ou as normas legais em vigor;
    </p>

    <p>
      - Alterar as especificações e/ou características do SOFTWARE licenciados
      para a melhoria e/ou correções de erros;
    </p>

    <p>
      - Disponibilizar acesso aos serviços de suporte compreendido de segunda a
      sexta-feira, das 09:00h às 17:30h (pelo horário de Brasília), sem
      intervalo, por telefone, e por meio de correio eletrônico
      (contato@aegro.com.br) para esclarecimento de dúvidas de ordem não técnica
      diretamente relacionadas ao SOFTWARE.
    </p>

    <p>
      - Manter as INFORMAÇÕES PESSOAIS do LICENCIADO, bem como seus registros de
      acesso, em sigilo, sendo que as referidas INFORMAÇÕES serão armazenadas em
      ambiente seguro, sendo respeitadas a intimidade, a vida privada, a honra e
      a imagem do LICENCIADO, em conformidade com as disposições da Lei nº
      12.965/2014.
    </p>

    <h4>Nível de Serviço</h4>

    <p>
      A LICENCIANTE empreenderá esforços comercialmente razoáveis para tornar o
      SOFTWARE disponível, no mínimo, 99% (noventa e nove vírgula sete por
      cento) durante cada Ano de Serviço (conforme definido abaixo)
      (“Compromisso de Nível de Serviço”). Na hipótese de a LICENCIANTE não
      cumprir o Compromisso de Nível de Serviço, o LICENCIADO terá o direito de
      receber o crédito correspondente a 1 (um) mês de mensalidade ou 1/12 avos
      (para planos anuais) (“Crédito de Serviço”).
    </p>

    <p>
      Por “Ano de Serviço” entende-se os 365 dias precedentes à data de uma
      reivindicação relacionada ao nível de serviço. Se o LICENCIADO estiver se
      utilizando do SOFTWARE durante período inferior a 365 dias, o Ano de
      Serviço que lhe corresponde será, ainda assim, considerado como os 365
      dias precedentes; no entanto, os dias anteriores a seu uso dos serviços
      serão considerados como de 100% de disponibilidade. Os períodos de
      inatividade operacional que ocorrerem antes de uma reivindicação bem
      sucedida de Crédito de Serviço não poderão ser usados para efeito de
      reivindicações futuras.
    </p>

    <p>
      O Compromisso de Nível de Serviço não se aplica caso as circunstâncias de
      indisponibilidade resultem (i) de uma interrupção do fornecimento de
      energia elétrica ou paradas emergenciais não superiores a 2 (duas) horas
      ou que ocorram no período das 24h às 6:00h (horário de Brasília); (ii)
      forem causadas por fatores que fujam ao razoável controle da LICENCIANTE,
      inclusive casos de força maior ou de acesso à Internet e problemas
      correlatos; (iii) resultem de quaisquer atos ou omissões do LICENCIADO ou
      de terceiros; (iv) resultem do equipamento, software ou outras tecnologias
      que o LICENCIADO usar que impeçam o acesso regular do SOFTWARE; (v)
      resultem de falhas de instâncias individuais não atribuíveis à
      indisponibilidade do LICENCIADO; (vi) resultem de práticas de
      gerenciamento da rede que possam afetar sua qualidade.
    </p>

    <h4>Isenção de Responsabilidade da Licenciante</h4>

    <p>A LICENCIANTE não se responsabiliza:</p>

    <p>
      - Por falha de operação, operação por pessoas não autorizadas ou qualquer
      outra causa em que não exista culpa da LICENCIANTE;
    </p>

    <p>
      - Pelo cumprimento dos prazos legais do LICENCIADO para a entrega de
      documentos fiscais ou pagamentos de impostos;
    </p>

    <p>
      - Pelos danos ou prejuízos decorrentes de decisões administrativas,
      gerenciais ou comerciais tomadas com base nas informações fornecidas pelo
      SOFTWARE;
    </p>

    <p>
      - Por problemas definidos como “caso fortuito” ou “força maior”,
      contemplados pelo Art. 393 do Código Civil Brasileiro;
    </p>

    <p>
      - Por eventuais problemas oriundos de ações de terceiros que possam
      interferir na qualidade do serviço;
    </p>

    <p>
      - Por danos causados a terceiros em razão de conteúdo gerado pelo
      LICENCIANTE através de SOFTWARE;
    </p>

    <p>
      - Por revisar as INFORMAÇÕES DE CONTA fornecidas pelo LICENCIADO, seja no
      que tange à precisão dos dados, seja quanto à legalidade ou ameaça de
      violação em função do fornecimento destas informações;
    </p>

    <p>
      A LICENCIANTE adota as medidas de segurança adequadas de acordo com os
      padrões de mercado para a proteção das INFORMAÇÕES do LICENCIADO
      armazenadas no SOFTWARE. Contudo, o LICENCIADO reconhece que nenhum
      sistema, servidor ou software está absolutamente imune a ataques e/ou
      invasões de hackers e outros agentes maliciosos, não sendo a LICENCIANTE
      responsável por qualquer exclusão, obtenção, utilização ou divulgação não
      autorizada de INFORMAÇÕES resultantes de ataques que a LICENCIANTE não
      poderia razoavelmente evitar por meio dos referidos padrões de segurança.
    </p>

    <h4>Da Retomada dos Softwares</h4>

    <p>
      A LICENCIANTE se reserva o direito de cancelar imediatamente o acesso do
      LICENCIADO ao SOFTWARE nos casos em que o LICENCIADO usar o SOFTWARE de
      forma diversa daquela estipulada no presente instrumento.
    </p>

    <h4>Das Garantias Limitadas</h4>

    <p>
      Na extensão máxima permitida pela lei em vigor, o SOFTWARE é fornecido "no
      estado em que se encontra" e "conforme a disponibilidade", com todas as
      falhas e sem garantia de qualquer espécie.
    </p>

    <p>
      Não obstante o disposto no item 9.a acima, a LICENCIANTE não garante que
      as funções contidas no SOFTWARE atendam às suas necessidades, que a
      operação do SOFTWARE será ininterrupta ou livre de erros, que qualquer
      serviço continuará disponível, que os defeitos no SOFTWARE serão
      corrigidos ou que o SOFTWARE será compatível ou funcione com qualquer
      SOFTWARE, aplicações ou serviços de terceiros.
    </p>

    <p>
      Além disso, o LICENCIADO reconhece que o SOFTWARE não deve ser utilizado
      ou não é adequado para ser utilizado em situações ou ambientes nos quais a
      falha ou atrasos de, os erros ou inexatidões de conteúdo, dados ou
      informações fornecidas pelo SOFTWARE possam levar à morte, danos pessoais,
      ou danos físicos ou ambientais graves, incluindo, mas não se limitando, à
      operação de instalações nucleares, sistemas de navegação ou de comunicação
      aérea, controle de tráfego aéreo, sistemas de suporte vital ou de armas.
    </p>

    <h4>Limitação de Responsabilidade</h4>

    <p>
      Em nenhum caso a LICENCIANTE será responsável por danos pessoais ou
      qualquer prejuízo incidental, especial, indireto ou consequente,
      incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda
      de dados, falha de transmissão ou recepção de dados, não continuidade do
      negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou
      relacionados ao seu uso ou sua inabilidade em usar o SOFTWARE, por
      qualquer outro motivo. Sob nenhuma circunstância a responsabilidade
      integral da LICENCIANTE com relação ao licenciado por todos os danos
      excederá a quantia correspondente ao plano de licenciamento paga pelo
      LICENCIADO à LICENCIANTE pela obtenção da presente licença de SOFTWARE.
    </p>

    <p>
      Consentimento livre, expresso e informado para acesso a informações
      confidenciais e dados pessoais O LICENCIADO, ao aceitar utilizar o
      SOFTWARE, além de aceitar integralmente este "EULA", também consente,
      livre e expressamente, que a LICENCIANTE colete, use, armazene e faça o
      tratamento de suas INFORMAÇÕES, incluindo seus dados pessoais, os quais
      serão necessários para que o serviço ofertado seja prestado em sua
      integralidade.
    </p>

    <p>
      Para tanto, o LICENCIADO consente, livre e expressamente, em fornecer os
      dados que permitam o acesso às INFORMAÇÕES necessárias para que o SOFTWARE
      execute todas as funções para as quais foi projetado.
    </p>

    <p>
      O LICENCIADO consente que, ao acessar o site da LICENCIANTE, esta poderá
      coletar informações técnicas de navegação, tais como tipo de navegador do
      computador utilizado para acesso ao site, endereço de protocolo de
      Internet, páginas visitadas e tempo médio gasto no site. Tais informações
      poderão ser usadas para orientar o próprio LICENCIADO e melhorar os
      serviços ofertados.
    </p>

    <p>
      O LICENCIADO consente livre e expressamente que suas INFORMAÇÕES poderão
      ser transferidas a terceiros em decorrência da venda, aquisição, fusão,
      reorganização societária ou qualquer outra mudança no controle da
      LICENCIANTE. A LICENCIANTE, contudo, compromete-se, nestes casos, a
      informar o LICENCIADO.
    </p>

    <p>
      O LICENCIADO consente livre e expressamente que a LICENCIANTE utilize
      cookies apenas para controlar a audiência e a navegação em seu site e
      possibilitar a identificação de serviços segmentados e personalizados ao
      perfil do LICENCIADO. A LICENCIANTE garante que estas informações
      coletadas por meio de cookies são estatísticas e não pessoais, bem como
      que não serão utilizadas para propósitos diversos dos expressamente
      previstos neste EULA, comprometendo-se a adotar todas as medidas
      necessárias a fim de evitar o acesso e o uso de tais informações por
      quaisquer terceiros, sem a devida autorização.
    </p>

    <h4>Da Rescisão</h4>

    <p>
      O LICENCIADO poderá rescindir este EULA a qualquer tempo, desde que
      comunique à LICENCIANTE, por escrito, devendo pagar o saldo devedor do
      plano de licenciamento contratado, se existente. Para os planos de
      licenciamento com pagamento antecipado, caso o LICENCIADO decida rescindir
      este EULA antes do término do prazo contratado, o LICENCIANTE não
      restituirá ao LICENCIADO o saldo restante do plano de licenciamento
      contratado. Este valor será retido pela LICENCIANTE para cobrir os custos
      operacionais.
    </p>

    <p>
      A LICENCIANTE poderá rescindir este EULA a qualquer tempo, desde que
      comunique ao LICENCIADO, por escrito, com antecedência mínima de 15
      (quinze) dias, devendo neste caso restituir ao LICENCIADO o saldo devedor
      do plano de licenciamento contratado, se existente. Este prazo de
      antecedência mínima e a obrigação de restituição acima não se aplicarão
      nos casos previstos na cláusula 12 acima e na cláusula 17 (i) abaixo.
    </p>

    <p>
      Para os planos de licenciamento com pagamento antecipado, caso a
      LICENCIANTE decida rescindir este EULA antes do término do prazo
      contratado, a LICENCIANTE restituirá ao LICENCIADO o saldo restante do
      plano de licenciamento contratado.
    </p>

    <p>
      A LICENCIANTE poderá rescindir o EULA a qualquer momento em caso de
      violação pelo LICENCIADO dos termos e condições ora acordados, ou em caso
      de atraso de pagamento não sanado no prazo de 60 (sessenta) dias, conforme
      cláusula 6 acima.
    </p>

    <p>
      No caso de rescisão do presente contrato, os dados pessoais, e demais
      informações do LICENCIADO ficarão disponíveis conforme cláusula 7 deste
      termo, sendo excluídos permanentemente após 60 (sessenta) dias da data da
      rescisão.
    </p>

    <h4>Das disposições Legais</h4>

    <p>
      Caso o LICENCIADO venha a desenvolver um novo módulo ou produto que
      caracterize cópia, de todo ou em parte, quer seja do dicionário de dados
      quer seja do programa, será considerado como parte do SOFTWARE fornecido
      pela LICENCIANTE, ficando, portanto, sua propriedade incorporada pela
      LICENCIANTE e seu uso condicionado a estas cláusulas contratuais;
    </p>

    <p>
      Este EULA obriga as partes e seus sucessores e somente o LICENCIADO possui
      licença não exclusiva para a utilização do SOFTWARE, sendo-lhe,
      entretanto, vedado transferir os direitos e obrigações acordados por este
      instrumento. Tal limitação, no entanto, não atinge a LICENCIANTE, que
      poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e
      obrigações inerentes ao presente EULA;
    </p>

    <p>
      A tolerância de uma parte para com a outra quanto ao descumprimento de
      qualquer uma das obrigações assumidas neste instrumento não implicará em
      novação ou renúncia de direito. A parte tolerante poderá, a qualquer
      tempo, exigir da outra parte o fiel e cabal cumprimento deste instrumento;
    </p>

    <p>
      Não constitui causa de rescisão contratual o não cumprimento das
      obrigações aqui assumidas em decorrência de fatos que independem da
      vontade das partes, tais como os que configuram o caso fortuito ou força
      maior, conforme previsto no artigo 393 do Código Civil Brasileiro;
    </p>

    <p>
      Se qualquer disposição deste EULA for considerada nula, anulável, inválida
      ou inoperante, nenhuma outra disposição deste EULA será afetada como
      consequência disso e, portanto, as disposições restantes deste EULA
      permanecerão em pleno vigor e efeito como se tal disposição nula,
      anulável, inválida ou inoperante não estivesse contida neste EULA;
    </p>

    <p>
      O LICENCIADO concorda que a LICENCIANTE possa divulgar a celebração deste
      instrumento para fins comerciais, fazendo menção ao nome e à marca do
      LICENCIADO em campanhas comerciais, podendo, inclusive, divulgar mensagens
      enviadas de forma escrita ou oral, por telefone, para uso em sites,
      jornais, revistas e outras campanhas, enquanto vigorar o presente EULA. O
      LICENCIADO aceita, ainda, receber comunicações via correio eletrônico
      sobre treinamentos, parcerias e campanhas relacionadas ao SOFTWARE;
    </p>

    <p>
      Neste ato, o LICENCIANTE expressamente autoriza o LICENCIADO a colher e
      utilizar seus dados técnicos e operacionais presentes no SOFTWARE, para
      fins de estudos e melhorias no SOFTWARE;
    </p>

    <p>
      A LICENCIANTE poderá, ao seu exclusivo critério, a qualquer tempo e sem a
      necessidade de comunicação prévia ao LICENCIADO:
    </p>

    <p>
      - Encerrar, modificar ou suspender, total ou parcialmente, o acesso do
      LICENCIADO ao SOFTWARE, quando referido acesso ou cadastro estiver em
      violação das condições estabelecidas neste EULA;
    </p>

    <p>
      - Excluir, total ou parcialmente, as informações cadastradas pelo
      LICENCIADO que não estejam em consonância com as disposições deste EULA;
    </p>

    <p>- Acrescentar, excluir ou modificar o Conteúdo oferecido no site;</p>

    <p>
      - Alterar quaisquer termos e condições deste EULA mediante simples
      comunicação ao LICENCIADO.
    </p>

    <p>
      A LICENCIANTE ainda poderá, a seu exclusivo critério, suspender, modificar
      ou encerrar as atividades do SOFTWARE, mediante comunicação prévia por
      escrito ao LICENCIADO, com antecedência mínima de 15 (quinze) dias,
      disponibilizando formas e alternativas de extrair do Site as informações,
      salvo nas hipóteses de caso fortuito ou força maior.
    </p>

    <p>
      A LICENCIANTE poderá, por meio de comunicação ao endereço eletrônico
      indicado pelo LICENCIADO em seu cadastro ou por meio de aviso no Site,
      definir preços para a oferta de determinados conteúdos e/ou serviços,
      ainda que inicialmente tais serviços tenham sido ofertados de forma
      gratuita, sendo a utilização destes, após o referido aviso, considerada
      como concordância do LICENCIADO com a cobrança de tais preços.
    </p>

    <p>
      Fica certo e entendido pelo LICENCIADO que somente a pessoa cadastrada
      pelo próprio LICENCIADO como administradora de conta poderá solicitar que
      as informações do LICENCIADO inseridas no Software sejam apagadas. Ainda,
      o LICENCIADO declara sua ciência de que uma vez apagadas, estas não
      poderão mais ser recuperadas, ficando a LICENCIANTE isenta de qualquer
      responsabilidade por quaisquer perdas ou danos decorrentes deste
      procedimento solicitado pelo LICENCIADO.
    </p>

    <h4>Da Lei Aplicável</h4>

    <p>
      Este EULA será regido, interpretado e se sujeitará às leis brasileiras e,
      o LICENCIADO e a LICENCIANTE desde logo elegem, de forma irrevogável e
      irretratável, o Foro Central da comarca de Porto Alegre, Estado do Rio
      Grande do Sul, para dirimir quaisquer dúvidas ou controvérsias oriundas
      deste EULA, com a exclusão de qualquer outro, por mais privilegiado que
      seja.
    </p>

    <h4>Das definições</h4>

    <p>
      Os termos utilizados neste instrumento deverão ser interpretados e usados
      conforme definições abaixo, seja no singular ou plural:
    </p>

    <p>
      INFORMAÇÕES PESSOAIS: qualquer informação disponibilizada pelo LICENCIADO
      que o identifique, tais como nome, endereço, data de nascimento, número de
      telefone, fax, endereço eletrônico, número de documentos, etc.
    </p>

    <p>
      INFORMAÇÕES: entende-se todas as informações do LICENCIADO relacionadas a
      INFORMAÇÕES PESSOAIS.
    </p>

    <p>
      LICENCIADO: pessoa física ou jurídica, com plena capacidade de contratar,
      que acessa o SOFTWARE da LICENCIANTE por meio do site, realizando seu
      cadastro, aceitando os termos do presente EULA e usufruindo das
      funcionalidades oferecidos.
    </p>

    <p>
      SOFTWARE: software de propriedade exclusiva da LICENCIANTE, cujas
      funcionalidades e serviços estão disponibilizados pelo site.
    </p>

    <br />

    <p style="text-align: center">Lajeado, 24 de janeiro de 2020.</p>

    <p style="text-align: center">ALVAP Engenharia LTDA.</p>

    <p style="text-align: center">Alberto Valdemeri</p>
  </div>
</template>