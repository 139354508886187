<template>
  <div>
    <div class="form-text text-danger" v-for="(e, i) in error" :key="i">
      {{ getText(e.code, e) }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["error", "custom"],
  methods: {
    getText(code, error) {
      switch (code) {
        case 1: {
          return "Obrigatório";
        }
        case 3: {
          return "O valor já existe";
        }
        case 4: {
          return "O valor não existe";
        }
        case 10: {
          return "Mínimo de " + error.meta + " caracteres";
        }
        case 11: {
          return "Máximo de " + error.meta + " caracteres";
        }
        case 12: {
          return "Valor inválido";
        }
      }
      if (this.custom && this.custom[code]) {
        return this.custom[code];
      }
    },
  },
};
</script>