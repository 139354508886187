<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          name="Parâmetro"
          names="Parâmetro"
          @onformcreate="openNew"
          @filter="$refs.datatable.applyFilters($event)"
          url="/admin/parametro"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/admin/parametro/query'"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Nome <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  readonly
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label"
                  >Valor <span class="text-danger">*</span></label
                >
                <textarea class="form-control" rows="10" v-model="form.crud.form.value"></textarea>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.value"
                ></Error>
              </div>

              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-lg-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.name"
                />
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    Alert,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        name: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        level: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Nome", value: "name" },
        {
          name: "Valor",
          value: "value",
          format: (v, d) => {
            if (v == null) {
              return "";
            }else{
              return v.substring(0, 70);
            }
            
          },
        },

        {
          name: "Atualizado",
          value: "updated",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    openNew(form) {
      form.tags = [];
    },
    openEdit(form, data) {
      if (data.tags == null) {
        data.tags = [];
      }
      form.crud.edit(data);
    },
  },
};
</script>
