<template>
  <div class="grid-container">
    <div v-for="index in 5" :key="index" class="grid-item">
      <div v-for="qrcode in qrcodes" :key="qrcode.hash" class="grid-content">
        <div
          style="
            border-radius: 25px;
            border: 2px solid #73ad21;
            width: 78px;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
            ,
          "
        >
          <img :src="qrcode.link" height="67" width="67" />
          <div style="display: flex; flex-direction: column; align-items: center">
            <label style="font-size: 5px">{{ qrcode.hash }}</label>
            <label style="font-size: 5px">suporte@onagro.com.br</label>
            <label style="font-size: 5px">app.onagro.com.br</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridComponent",
  data() {
    return {
      grouper: null,
      qrcodes: [],
    };
  },
  created() {
    this.grouper = this.$route.params.grouper;
  },
  mounted() {
    this.getQrCodeForPrint(this.grouper);
  },
  methods: {
    getQrCodeForPrint(grouper) {
      this.urlGet(`/QRCode/Reprint/${grouper}`)
        .then((e) => {
          this.qrcodes = e.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.grid-item {
  page-break-inside: avoid;
}
</style>
