Number.prototype.format = function(min = 0, max = 2) {
    return this.toLocaleString(
        undefined, { minimumFractionDigits: min, maximumFractionDigits: max }
    );
}
export default {
    data() {
        return {
            week: [{
                name: 'Domingo',
                abr: 'Dom'
            }, {
                name: 'Segunda-feira',
                abr: 'Seg'
            }, {
                name: 'Terça-feira',
                abr: 'Ter'
            }, {
                name: 'Quarta-feira',
                abr: 'Qua'
            }, {
                name: 'Quinta-feira',
                abr: 'Qui'
            }, {
                name: 'Sexta-feira',
                abr: 'Sex'
            }, {
                name: 'Sábado',
                abr: 'Sab'
            }]
        }
    },
    methods: {
        toGb(d) {
            if (d == null) {
                return '';
            }
            if (d < 1024 * 1024) {
                return (d / (1024)).format(0, 2) + ' KB';
            }
            if (d < 1024 * 1024 * 1024) {
                return (d / (1024 * 1024)).format(0, 2) + ' MB';
            }
            return (d / (1024 * 1024 * 1024)).format(0.2) + ' GB';
        },
    }
}


export function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
