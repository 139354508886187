<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="true"
          @delete="$refs.datatable.update()"
          @create="$refs.datatable.update()"
          @onformcreate="onformcreate"
          @onformedit="onformedit"
          @forceRerender="$refs.datatable.update()"
          name="Sensor"
          names="Sensores"
          button="+"
          btnfilter="false"
          url="/Sensor"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form" v-if="component">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/Sensor/Read'"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:fileUpload="data">
                <div>
                  <img
                    :src="data.row.fileUpload"
                    :alt="data.row.fileUpload"
                    width="50"
                    height="60"
                  />
                </div>
              </template>
            </Datatable>
          </template>

          <template v-slot:form="form">
            <div class="row">
              <!-- Nome do sensor -->
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Nome do sensor *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>

              <!-- Icon Upload -->
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Icon Upload *</label>

                <input
                  type="file"
                  @change="uploadFile"
                  accept="image/*"
                  class="form-control"
                />

                <div
                  style="
                    margin-top: 5px;
                    width: 226px;
                    color: #919191;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 9.9px;
                    word-wrap: break-word;
                  "
                >
                  Tamanho máximo para arquivo 1mb.
                </div>
              </div>

              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Unidade de Medida *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.measure"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.measure"
                ></Error>
              </div>

              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Variavel de Retorno *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.varret"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.varret"
                ></Error>
              </div>

              <div class="mb-3 col-md-12 col-12">
                <label class="form-label">Descritivo *</label>
                <textarea
                  style="resize: none"
                  cols="58"
                  rows="6"
                  v-model="form.crud.form.description"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.description"
                ></Error>
              </div>

              <hr />
            </div>
          </template>
        </Crud>
      </template>
    </Layout>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";

export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    Alert,
  },
  data() {
    return {
      component: true,
      ref_form: null,
      fields: [
        {
          name: "Icon",
          value: "fileUpload",
          style: { width: "70px" },
          type: "slot",
        },
        { name: "Nome", value: "name" },
        {
          name: "Data de criação",
          value: "created",
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          edit: true,
          delete: false,
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    forceRerender() {
      this.component = false;
      this.$nextTick(() => {
        this.component = true;
      });
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const maxSize = 1024 * 1024;

      if (file && file.size > maxSize) {
        alert("Esse Arquivo Excede 1MB.");
        event.target.value = "";
        return;
      }
      this.ref_form.fileUpload = event.target.files[0];
    },
    onformcreate(form) {
      form.fileUpload = null;
      this.ref_form = form;
    },
    onformedit(form) {
      form.fileUpload = null;
      this.ref_form = form;
    },
  },
};
</script>
