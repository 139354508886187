<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <div>
          <Tab
            :values="[
              { name: 'Dados', slot: 'dados', icon: 'info-circle' },
              { name: 'Dispositivos', slot: 'acesso', icon: 'devices' },
              { name: 'Trocar senha', slot: 'troca', icon: 'lock' },
              form.api ? { name: 'API', slot: 'api', icon: 'chip' } : null,
              { name: 'Apagar conta', slot: 'apagar', icon: 'trash' },
            ].filter(item => item !== null)"
            @tabchange="$event == 'acesso' ? $refs.acesso.reload() : null"
            ref="tab"
          >
            <template v-slot:dados>
              <div>
                <div class="row">
                  <div class="mb-3 col-md-12 col-12">
                    <label class="form-label"
                      >Token
                      <i
                        class="tf-icons bx bx-copy text-primary"
                        style="cursor: pointer"
                        @click="copyTextToClipboard(form.token)"
                      ></i
                    ></label>
                    <div class="overflow-x-auto">
                      <span>{{ form.token }}</span>
                    </div>
                  </div>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label"
                      >E-mail <span class="text-danger">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      readonly
                      v-model="form.email"
                    />
                    <Error v-if="error" :error="error.email"></Error>
                  </div>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label"
                      >Nome <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.name"
                    />
                    <Error v-if="error" :error="error.name"></Error>
                  </div>

                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label"
                      >Telefone <span class="text-danger">*</span></label
                    >
                    <input
                      type="tel"
                      class="form-control"
                      v-model="form.telefone"
                    />
                    <Error v-if="error" :error="error.telefone"></Error>
                  </div>

                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label">Inscrição Estadual</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.inscEst"
                    />
                    <Error v-if="error" :error="error.inscEst"></Error>
                  </div>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label">Inscrição Municipal</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.inscMuni"
                    />
                    <Error v-if="error" :error="error.inscMuni"></Error>
                  </div>

                  <div class="mb-3 col-md-4 col-12">
                    <div>
                      Documento:
                      <input
                        style="margin-left: 2%"
                        type="radio"
                        id="cpf"
                        value="CPF"
                        v-model="documento"
                        name="documento"
                      />
                      <label for="cpf">CPF</label>

                      <input
                        type="radio"
                        style="margin-left: 2%"
                        id="cnpj"
                        value="CNPJ"
                        v-model="documento"
                        name="documento"
                      />
                      <label for="CNPJ">CNPJ</label>
                    </div>

                    <input
                      v-if="documento === 'CPF'"
                      type="text"
                      class="form-control"
                      onfocus="this.value=''"
                      v-mask="'###.###.###-##'"
                      v-model="form.documento"
                    />

                    <input
                      v-else
                      type="text"
                      class="form-control"
                      onfocus="this.value=''"
                      v-mask="'##.###.###/####-##'"
                      v-model="form.documento"
                    />
                  </div>
                  <label class="form-label">Endereço Completo</label>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label">UF</label>
                    <input type="text" class="form-control" v-model="form.uf" />
                    <Error v-if="error" :error="error.uf"></Error>
                  </div>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label">Municipio</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.municipio"
                    />
                    <Error v-if="error" :error="error.municipio"></Error>
                  </div>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label">Logradouro</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.logradouro"
                    />
                    <Error v-if="error" :error="error.logradouro"></Error>
                  </div>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label">Bairro</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.bairro"
                    />
                    <Error v-if="error" :error="error.bairro"></Error>
                  </div>
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label">CEP</label>
                    <input
                      type="text"
                      class="form-control"
                      v-mask="'#####-###'"
                      v-model="form.cep"
                    />
                    <Error v-if="error" :error="error.cep"></Error>
                  </div>
                  <div class="mb-3">
                    <label class="form-label"
                      >Tags <span class="text-danger">*</span></label
                    >
                    <div class="row">
                      <div class="col-12">
                        <div class="form-check form-check-inline mb-2">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="produtor"
                            value="PRODUTOR"
                            v-model="form.tags"
                          />
                          <label class="form-check-label" for="produtor"
                            >Produtor</label
                          >
                        </div>

                        <div class="form-check form-check-inline mb-2">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="tecnico"
                            value="TECNICO"
                            v-model="form.tags"
                          />
                          <label class="form-check-label" for="tecnico"
                            >Técnico</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="empresa"
                            value="EMPRESA"
                            v-model="form.tags"
                          />
                          <label class="form-check-label" for="empresa"
                            >Empresa</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="consultor"
                            value="CONSULTOR"
                            v-model="form.tags"
                          />
                          <label class="form-check-label" for="consultor"
                            >Consultor</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="mb-3 col-md-12 col-12" v-if="form.api">
                    <label class="form-label">Recursos disponíveis</label>
                    <div v-if="form.api">
                      <div class="form-check form-check-inline">
                        <i
                          class="tf-icons bx bx-check text-primary"
                          style="font-size: 28px"
                        ></i>
                        <label class="form-check-label"
                          >Uso da api de dados</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="mb-3 col-12 col-lg-6" v-if="form.created">
                    <label class="form-label">Data de criação</label>
                    <div>
                      <span>{{
                        new Date(form.created).toLocaleDateString()
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <LoadButton
                    v-if="alterado()"
                    @click="saveChange"
                    ref="btn_change"
                    class="btn btn-primary"
                    text="Salvar alterações"
                    loadtext="Salvando..."
                  >
                  </LoadButton>
                </div>
              </div>
            </template>
            <template v-slot:acesso>
              <LoadData
                @load="loadAcesso"
                url="/Usuario/AuthSession"
                ref="acesso"
              >
                <template v-slot:body>
                  <div>
                    <h5>
                      Dispositivos na web {{ getDeviceByType("web").length }}/{{
                        this.getUser().max_web_acesso
                      }}
                    </h5>
                    <div v-for="(l, i) in getDeviceByType('web')" :key="i">
                      <div class="d-flex mb-2">
                        <img
                          height="50"
                          width="50"
                          :src="getDevice(l.user_agent)"
                          alt=""
                        />
                        <div class="session-card">
                          <h6 class="m-0">
                            {{ l.user_agent.os.name }} -
                            {{ l.type.toUpperCase() }}
                          </h6>
                          {{}}
                          {{ new Date(l.created).toLocaleString() }}
                        </div>
                        <div>
                          <i
                            @click="logoutDevice(l.token)"
                            class="tf-icons bx bx-exit"
                            title="Desconectar dispositivo"
                            style="
                              font-size: 28px;
                              margin-top: 15px;
                              cursor: pointer;
                            "
                          ></i>
                        </div>
                      </div>
                    </div>
                    <h5 class="mt-3">
                      Dispositivos mobile
                      {{ getDeviceByType("mobile").length }}/{{
                        this.getUser().max_mobile_acesso
                      }}
                    </h5>
                    <div v-for="(l, i) in getDeviceByType('mobile')" :key="i">
                      <div class="d-flex mb-2">
                        <img
                          height="50"
                          width="50"
                          :src="getDevice(l.user_agent)"
                          alt=""
                        />
                        <div class="session-card">
                          <h6 class="m-0">
                            {{ l.user_agent.os.name }} -
                            {{ l.type.toUpperCase() }}
                          </h6>
                          {{}}
                          {{ new Date(l.created).toLocaleString() }}
                        </div>
                        <div>
                          <i
                            @click="logoutDevice(l.token)"
                            class="tf-icons bx bx-exit"
                            title="Desconectar dispositivo"
                            style="
                              font-size: 28px;
                              margin-top: 15px;
                              cursor: pointer;
                            "
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </LoadData>
            </template>
            <template v-slot:troca>
              <div>
                <div class="row">
                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label"
                      >Senha atual <span class="text-danger">*</span></label
                    >
                    <div class="input-group input-group-merge">
                      <input
                        autocomplete="new-password"
                        :type="inpuType"
                        class="form-control"
                        placeholder="Senha"
                        v-model="change.password"
                      />
                      <span
                        class="input-group-text cursor-pointer"
                        @click="
                          inpuType == 'password'
                            ? (inpuType = 'text')
                            : (inpuType = 'password')
                        "
                        ><i
                          class="bx bx-hide"
                          v-if="inpuType == 'password'"
                        ></i>
                        <i class="bx bx-show" v-else></i
                      ></span>
                    </div>
                    <Error
                      v-if="error"
                      :error="error.password"
                      :custom="{ 70: 'Senha incorreta' }"
                    ></Error>
                  </div>

                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label"
                      >Nova senha <span class="text-danger">*</span></label
                    >
                    <div class="input-group input-group-merge">
                      <input
                        autocomplete="new-password"
                        :type="inpuType"
                        class="form-control"
                        placeholder="Senha"
                        v-model="change.new_password"
                      />
                      <span
                        class="input-group-text cursor-pointer"
                        @click="
                          inpuType == 'password'
                            ? (inpuType = 'text')
                            : (inpuType = 'password')
                        "
                        ><i
                          class="bx bx-hide"
                          v-if="inpuType == 'password'"
                        ></i>
                        <i class="bx bx-show" v-else></i
                      ></span>
                    </div>
                    <Error v-if="error" :error="error.new_password"></Error>
                  </div>

                  <div class="mb-3 col-md-4 col-12">
                    <label class="form-label"
                      >Repita a nova senha
                      <span class="text-danger">*</span></label
                    >
                    <div class="input-group input-group-merge">
                      <input
                        autocomplete="new-password"
                        :type="inpuType"
                        class="form-control"
                        placeholder="Senha"
                        v-model="change.repita"
                      />
                      <span
                        class="input-group-text cursor-pointer"
                        @click="
                          inpuType == 'password'
                            ? (inpuType = 'text')
                            : (inpuType = 'password')
                        "
                        ><i
                          class="bx bx-hide"
                          v-if="inpuType == 'password'"
                        ></i>
                        <i class="bx bx-show" v-else></i
                      ></span>
                    </div>
                    <Error v-if="error" :error="error.repita"></Error>
                  </div>
                </div>
                <div class="mt-3">
                  <LoadButton
                    ref="btn_password"
                    @click="changePassword"
                    :disabled="
                      change.new_password != change.repita ||
                      change.new_password == null ||
                      change.new_password.length < 8
                    "
                    class="btn btn-primary"
                    text="Alterar senha"
                    loadtext="Alterando..."
                  >
                  </LoadButton>
                </div>
              </div>
            </template>
            <template v-slot:api v-if="form.api">
              <Tab
                :values="[
                   { name: 'Chave', slot: 'chaves', icon: 'key' },
                  { name: 'Documentação', slot: 'doc', icon: 'file' },
                ]"
                @tabchange="forceRerenderTab"
              >
                <template v-slot:chaves>
                  <Datatable
                    ref="datatable"
                    :fields="fieldsAplicacao"
                    :url="'/Usuario/ReadKeys'"
                    :where="where"
                  >
                    <template v-slot:privateToken="data">
                      <div>
                        <div class="text-center copy-token">
                          {{ data.row.privateToken }}
                        </div>
                        <i
                          class="tf-icons bx bx-copy text-primary"
                          style="cursor: pointer"
                          @click="copyTextToClipboard(data.row.privateToken)"
                        ></i>
                      </div>
                    </template>
                    <template v-slot:publicToken="data">
                      <div>
                        <div class="text-center copy-token">
                          {{ data.row.publicToken }}
                        </div>
                        <i
                          class="tf-icons bx bx-copy text-primary"
                          style="cursor: pointer"
                          @click="copyTextToClipboard(data.row.publicToken)"
                        ></i>
                      </div>
                    </template>
                  </Datatable>
                </template>

                <template v-slot:doc>
                  <UserApi />
                </template>
              </Tab>
            </template>
            <template v-slot:apagar>
              <div>
                <div class="mt-3">
                  <!-- :disabled="true" -->
                  <LoadButton
                    @click="abrirModalDeletarConta"
                    ref="btn_delete"
                    class="btn btn-danger"
                    text="Deletar minha conta onAgro"
                    loadtext="Deletando..."
                  >
                  </LoadButton>
                </div>
                <p class="mt-3">
                  Ao prosseguir com a exclusão da sua conta, é importante que
                  você esteja ciente de que todos os dados relacionados à sua
                  conta serão permanentemente removidos do nosso sistema.
                </p>
              </div>
            </template>
          </Tab>
        </div>
      </template>
    </Layout>

    <div class="modal fade" tabindex="-1" ref="modal_delete">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Deletar a Conta</h5>
            <button
              type="button"
              class="btn-close"
              @click="fecharModalDeletarConta"
            ></button>
          </div>
          <div class="modal-body">
            <span>Deseja realmente deletar sua Conta?</span>
          </div>
          <div class="modal-footer">
            <LoadButton
              @click="deletarConta"
              ref="btn_delete"
              class="btn btn-danger"
              text="Confirmo que estou deletando minha conta onAgro"
              loadtext="Deletando..."
            >
            </LoadButton>
          </div>
        </div>
      </div>
    </div>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
import Tab from "../../components/form/Tab.vue";
import LoadData from "../../components/LoadData.vue";
import LoadButton from "../../components/form/LoadButton.vue";
import { mask } from "vue-the-mask";
import UserApi from "./UserApi.vue";
import Datatable from "../../components/crud/Datatable.vue";

export default {
  directives: { mask },
  components: {
    Layout,
    Error,
    Alert,
    Tab,
    LoadData,
    LoadButton,
    UserApi,
    Datatable,
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.getUser())),
      error: null,
      logs: null,
      change: {},
      inpuType: "password",
      documento: "CPF",
      fieldsAplicacao: [
        { name: "Nome da Chave", value: "nome" },
        { name: "Nome da Chave Publica", value: "publicToken",  type: "slot" },
        { name: "Nome da Chave Privada", value: "privateToken",  type: "slot" },
        {
          name: "Expiração",
          value: "expire",
          style: { width: "100px" },
          format: (v, d) => {
            if (v != null && v != "9999-12-31T23:59:59.9999999" ) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "Nunca";
            }
          },
        },
      ],
    };
  },
  methods: {
    logoutDevice(token) {
      this.urlDelete("/Usuario/Logout/" + token, this.change)
        .then((e) => {
          this.$refs.acesso.reload();
        })
        .catch((e) => {
          this.$refs.alert.show(
            "Erro " + e.response.status,
            "Não foi possível desconectar o dispositivo!",
            null,
            3000,
            "bg-danger"
          );
        })
        .finally(() => {});
    },
    alterado() {
      if (this.form.tags.length > 0 && this.getUser().tags != null) {
        if (this.form.tags.length != this.getUser().tags.length) {
          return true;
        }
        for (let i = 0; i < this.form.tags.length; i++) {
          if (this.form.tags[i] != this.getUser().tags[i]) {
            return true;
          }
        }
      }
      return (
        this.form.name != this.getUser().name ||
        this.form.telefone != this.getUser().telefone ||
        this.form.inscEst != this.getUser().inscEst ||
        this.form.inscMuni != this.getUser().inscMuni ||
        this.form.documento != this.getUser().documento ||
        this.form.uf != this.getUser().uf ||
        this.form.municipio != this.getUser().municipio ||
        this.form.logradouro != this.getUser().logradouro ||
        this.form.bairro != this.getUser().bairro ||
        this.form.cep != this.getUser().cep
      );
    },
    getDeviceByType(type = null) {
      if (!this.logs) {
        return [];
      }
      var itens = [];
      for (const d of this.logs) {
        if (d.type == type) {
          itens.push(d);
        }
      }
      return itens;
    },
    getDevice(data) {
      var name = data.browser.name;
      var device = data.device.model;
      var types = ["firefox", "chrome", "cromium", "opera", "safari"];
      if (name) {
        name = name.toLocaleLowerCase();
      }
      if (device) {
        device = device.toLocaleLowerCase();
      }
      if (device) {
        if (device == "iphone") {
          return "/img/devices/ios.png";
        } else {
          return "/img/devices/android.png";
        }
      }
      for (const t of types) {
        if (t == name) {
          return "/img/devices/" + name + ".png";
        }
      }
      return "/img/devices/default.png";
    },
    abrirModalDeletarConta() {
      $(this.$refs.modal_delete).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    fecharModalDeletarConta() {
      $(this.$refs.modal_delete).modal("hide");
    },
    deletarConta() {
      this.$refs.btn_delete.load = true;
      this.urlDelete("/Usuario/Delete", this.change)
        .then((e) => {
          this.error = null;
          this.$refs.alert.show(
            "Alterado",
            "Conta deletada com sucesso!",
            null,
            5000,
            "bg-primary"
          );

          localStorage.setItem("session", null);
          localStorage.setItem("session_token", null);

          window.location.replace("/");
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível deletar a conta!",
              null,
              3000,
              "bg-danger"
            );
          }
        })
        .finally(() => {
          this.$refs.btn_delete.load = false;
        });
    },
    changePassword() {
      this.$refs.btn_password.load = true;
      this.urlPut("/Usuario/ChangePassword", this.change)
        .then((e) => {
          this.error = null;
          this.change = {};
          this.$refs.alert.show(
            "Alterado",
            "Senha alterada com sucesso!",
            null,
            5000,
            "bg-primary"
          );
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Dados incorretos!",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível alterar a senha!",
              null,
              3000,
              "bg-danger"
            );
          }
        })
        .finally(() => {
          this.$refs.btn_password.load = false;
        });
    },
    saveChange() {
      this.$refs.btn_change.load = true;
      if (this.form.name == "") {
        this.form.name = null;
      }
      if (this.form.telefone == "") {
        this.form.telefone = null;
      }
      this.urlPut("/Usuario/Update", this.form)
        .then((e) => {
          this.updateUser(() => {
            this.$refs.tab.$forceUpdate();
            this.$refs.alert.show(
              "Atualizado",
              "Dados atualizados com sucesso!",
              null,
              5000,
              "bg-primary"
            );
            this.$refs.btn_change.load = false;
            this.error = null;
          });
        })
        .catch((e) => {
          this.$refs.btn_change.load = false;
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Dados incorretos!",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível alterar os dados!",
              null,
              3000,
              "bg-danger"
            );
          }
        })
        .finally(() => {});
    },
    loadAcesso(data) {
      for (const info of data) {
        info.user_agent = this.parseUserAgent(info.user_agent);
      }
      this.logs = data;
    },
  },
  created() {
    if (this.form.tags == null) {
      this.form.tags = [];
    }
  },
};
</script>

<style>
.overflow-x-auto {
  overflow: auto;
}
.session-card {
  width: calc(100% - 80px);
  display: inline-block;
  margin-left: 15px;
  margin-top: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
