<template>
  <div class="h-100 w-100">
    <div class="h-100 d-flex" style="min-height: 80px" v-if="load">
      <div class="m-auto">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Carregando...</span>
        </div>
      </div>
    </div>
    <slot name="body" v-else> </slot>
  </div>
</template>

<script>
export default {
  props: ["url"],
  data() {
    return {
      load: false,
    };
  },
  methods: {
    getData() {
      this.load = true;
      this.urlGet(this.url)
        .then((info) => {
          this.$emit("load", info.data);
        })
        .catch((e) => {})
        .finally(() => {
          this.load = false;
        });
    },
    reload() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>