<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          name="Estação leitura"
          names="Estação leituras"
          @onformcreate="onform"
          @filter="$refs.datatable.applyFilters($event)"
          url="/admin/estacao-leitura"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/admin/estacao-leitura/query'"
              :minlimit="100"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:valida="data">
                <div class="text-center">
                  <i
                    class="menu-icon tf-icons bx bx-check text-success"
                    v-if="data.row.valida"
                  ></i
                  ><i class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
              <template v-slot:bateria="data">
                <div class="text-center">
                  <i
                    class="menu-icon tf-icons bx bx-check text-success"
                    v-if="data.row.valida"
                  ></i
                  ><i class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Temperatura *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.temperatura"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.temperatura"
                ></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Precipitação *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.precipitacao"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.precipitacao"
                ></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Umidade *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.umidade"
                />
                <Error v-if="form.crud.error" :error="form.crud.error.umidade"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Vento velocidade *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.vento_velocidade"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.vento_velocidade"
                ></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Max Vento velocidade *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.vento_max_velocidade"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.vento_max_velocidade"
                ></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Vento direção *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.vento_direcao"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.vento_direcao"
                ></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Pressão *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.pressao_indor"
                />
                <Error v-if="form.crud.error" :error="form.crud.error.pressao_indor"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Bateria *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.bateria"
                />
                <Error v-if="form.crud.error" :error="form.crud.error.bateria"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">UV *</label>
                <input type="number" class="form-control" v-model="form.crud.form.uv" />
                <Error v-if="form.crud.error" :error="form.crud.error.uv"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">UVI *</label>
                <input type="number" class="form-control" v-model="form.crud.form.uvi" />
                <Error v-if="form.crud.error" :error="form.crud.error.uvi"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Luz *</label>
                <input type="number" class="form-control" v-model="form.crud.form.luz" />
                <Error v-if="form.crud.error" :error="form.crud.error.luz"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Origem *</label>
                <select class="form-select" v-model="form.crud.form.origem">
                  <option value="O">O</option>
                  <option value="B">B</option>
                </select>
                <Error v-if="form.crud.error" :error="form.crud.error.origem"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Luz *</label>
                <input type="number" class="form-control" v-model="form.crud.form.luz" />
                <Error v-if="form.crud.error" :error="form.crud.error.luz"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Latitude *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.latitude"
                />
                <Error v-if="form.crud.error" :error="form.crud.error.latitude"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Longitude *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.longitude"
                />
                <Error v-if="form.crud.error" :error="form.crud.error.longitude"></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">Temperatura indor *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.temperatura_indor"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.temperatura_indor"
                ></Error>
              </div>
              <div class="mb-3 col-md-2 col-12">
                <label class="form-label">RFM rssi *</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.rfm_rssi"
                />
                <Error v-if="form.crud.error" :error="form.crud.error.rfm_rssi"></Error>
              </div>

              <div class="mb-3 col-md-3 col-12">
                <label class="form-label">Estação</label>
                <SelectData
                  url="/admin/estacao/query"
                  field="id"
                  label="nome"
                  empty="Selecione"
                  type="slot"
                  :value="
                    form.crud.form.estacao_id == undefined
                      ? null
                      : form.crud.form.estacao_id
                  "
                  @value="form.crud.form.estacao_id = $event"
                >
                  <template v-slot:body="data">
                    <span
                      >{{ data.row.id + " - " + data.row.token + " / " + data.value }}
                    </span>
                  </template>
                </SelectData>
              </div>
              <div class="mb-3 col-md-3 col-12 text-center">
                <label class="form-label"
                  >Leitura válida <span class="text-danger">*</span></label
                >
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input check-size"
                      type="checkbox"
                      id="premium"
                      v-model="form.crud.form.valida"
                    />
                    <label class="form-check-label" for="premium">válida</label>
                  </div>
                </div>
              </div>
              <hr />
              <div class="mb-3 col-md-4 col-12" v-if="form.crud.form.data">
                <label class="form-label">Data da leitura</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="form.crud.form.data"
                />
              </div>
              <div class="mb-3 col-md-4 col-12" v-if="form.crud.form.ativado">
                <label class="form-label">Ativado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  v-model="form.crud.form.ativado"
                />
              </div>

              <div class="mb-3 col-12 col-md-4" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-md-4" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">A parti da data</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="data.filters.data"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Estação</label>
                <SelectData
                  url="/admin/estacao/query"
                  field="id"
                  label="nome"
                  empty="Selecione"
                  type="slot"
                  :value="
                    data.filters.estacao_id == undefined ? null : data.filters.estacao_id
                  "
                  @value="data.filters.estacao_id = $event"
                >
                  <template v-slot:body="data">
                    <span
                      >{{ data.row.id + " - " + data.value + " / " }}
                      <span class="text-primary">{{ data.row.token }}</span></span
                    >
                  </template>
                </SelectData>
              </div>
              <!-- <div class="mb-3">
                <label class="form-label">Antes da data</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="data.filters.data"
                />
              </div> -->
              <div class="mb-3">
                <label class="form-label">Origem</label>
                <select class="form-select" v-model="data.filters.origem">
                  <option :value="undefined">Todas</option>
                  <option value="O">O</option>
                  <option value="B">B</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
import SelectData from "../../components/crud/input/SelectData.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    Alert,
    SelectData,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        origem: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        data: {
          operator: ">",
        },
        estacao_id: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "EID", value: "estacao_id", title: "Estação ID" },
        { name: "Temp", value: "temperatura", title: "Temperatura" },
        { name: "Prec", value: "precipitacao", title: "Precipitação" },
        { name: "Umi", value: "umidade", title: "Umidade" },
        { name: "VV", value: "vento_velocidade", title: "Vento velocidade" },

        {
          name: "VMV",
          value: "vento_max_velocidade",
          title: "Vento max velocidade",
        },
        { name: "VD", value: "vento_direcao", title: "Vento direção" },
        { name: "Pre", value: "pressao_indor", title: "Pressão" },
        { name: "Bat", value: "bateria", title: "Bateria", type: "slot" },
        { name: "UV", value: "uv", title: "UV" },
        { name: "UVI", value: "uvi", title: "UVI" },
        { name: "Luz", value: "luz", title: "Luz" },
        { name: "Or", value: "origem", title: "Origem" },
        { name: "Val", value: "valida", type: "slot" },
        {
          name: "Data",
          value: "data",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString();
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          style: { minWidth: "100px", width: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    onform(form) {
      form.situacao = "ATIVA";
      form.status = "CRIADA";
      form.limite_acesso = 4;
      form.detalhado = true;
      form.salvar = false;
    },
  },
};
</script>
