var GLOBAL = {
    clima: {
        pontos: null,
        request: null,
    },
    estacao: {
        pontos: null,
        request: null,
    }
};

export default {
    data() {
        return {
            clima: {
                pontos: null,
            },
            estacao:{
                pontos: null,
            },
        }
    },
    methods: {
        getPontosFiltrado(data, info, max = null, selected) {
            var res = [];
            for (const ponto of data) {
                if (selected && selected.id == ponto.id) {
                    res.push(ponto);
                } else if (info.latitude_min <= ponto.latitude && info.latitude_max >= ponto.latitude && info.longitude_min <= ponto.longitude && info.longitude_max >= ponto.longitude) {
                    if (!(max != null && max < res.length)) {
                        res.push(ponto);
                    }
                }
            }
            return res;
        },
        getClimaPontos() {
            return new Promise((resolve, reject) => {
                this.urlGet('/WeatherForecast/GetAllWeatherForecast').then((e) => {
                   this.clima.pontos = e.data
                   resolve(e.data);
                }).catch((e) => {
                    reject(e);
                });
            });
        },
        getEstacaoPontos() {
            return new Promise((resolve, reject) => {
                this.urlGet('/Station/GetAllStations').then((e) => {
                    this.estacao.pontos = e.data;
                    resolve(e.data);
                }).catch((e) => {
                    reject(e);
                });
            });
        }
    },
    mounted() {
        if (GLOBAL.clima.pontos == null && GLOBAL.clima.request == null) {
            GLOBAL.clima.request = this.getClimaPontos();
        }
        
        if (GLOBAL.estacao.pontos == null && GLOBAL.estacao.request == null) {
            GLOBAL.estacao.request = this.getEstacaoPontos();
        }

    }
}