<template>
  <div class="h-100 w-100 bg-primary painel-fundo">
    <div class="container-xxl container-p-y">
      <div class="misc-wrapper">
        <div class="card p-5">
          <div class="p-1">
            <div class="text-center">
              <img src="/img/logo.png" alt="" width="150" />
            </div>
          </div>
          <div v-if="!load">
            <div v-if="!error">
              <h2 class="mb-2 mx-2">E-mail confirmado</h2>
              <p class="mb-4 mx-2">Seu e-mail foi confirmado com sucesso!</p>
              <div class="mb-4">
                <i
                  class="bx bx-check text-white bg-success"
                  style="font-size: 100px; border-radius: 50%"
                ></i>
              </div>
              <button class="btn btn-primary" @click="$router.push('/login')">
                Acessar o site
              </button>
            </div>
            <div v-else>
              <div v-if="error != 401">
                <h5 class="mb-4 text-danger">Falha ao confirmar o email!</h5>
                <button class="btn btn-primary" @click="validate">
                  Tentar novamente
                </button>
              </div>

              <div v-else>
                <h5 class="mb-4 text-danger">Link expirado</h5>
                <button class="btn btn-primary" @click="$router.push('/login')">
                  Acessar o site
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <h4 class="mb-4">Verificando...</h4>
            <div
              class="spinner-border text-primary spinner-border-lg"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      load: true,
      error: null,
      key: null,
    };
  },
  methods: {
    validate() {
      this.load = true;
      this.error = null;
      this.urlPost("/Usuario/CheckEmail", {
        key: this.key,
      })
        .then((e) => {
          //debugger;
        })
        .catch((e) => {
          this.error = e.response.status;
        })
        .finally((e) => {
          this.load = false;
        });
    },
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.key = urlParams.get("key");
    if (this.key == null) {
      this.$router.push("/404");
    } else {
      this.validate();
    }
  },
};
</script>

<style >
.painel-fundo {
  background-image: url("/img/login.png");
  background-size: cover;
}
</style>