<template>
  <div
    :class="
      'bs-toast toast toast-placement-ex m-2 bottom-0 end-0 ' +
      color +
      (timer ? ' show' : '')
    "
  >
    <div class="toast-header">
      <i class="bx bx-info me-2"></i>
      <div class="me-auto fw-semibold">{{ title }}</div>
      <small>{{ info }}</small>
      <button type="button" class="btn-close" @click="clear"></button>
    </div>
    <div class="toast-body">{{ body }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      body: "",
      info: "",
      timer: null,
      color: "",
    };
  },
  methods: {
    clear() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    show(title, body, info, time, color) {
      this.clear();
      this.title = title;
      this.body = body;
      this.info = info;
      this.color = color;
      this.timer = setTimeout(() => {
        this.clear();
      }, time);
    },
    primary(title, body, info, time) {
      this.show(title, body, info, time, "bg-primary");
    },
  },
};
</script>