<template>
  <div class="h-100 w-100">
    <div class="offline" v-if="showDc">
      <i class="menu-icon tf-icons bx bx-wifi-off"></i>
      Desconectado
    </div>
    <router-view />
  </div>
</template>

<style>
</style>

<script>
export default {
  data() {
    return {
      showDc: false,
    };
  },
  mounted() {
    window.addEventListener("offline", (e) => {
      this.showDc = true;
    });

    window.addEventListener("online", (e) => {
      this.showDc = false;
    });
    this.addEvent("logout", this.onLogout);
    setTimeout(() => {
      this.checkSession();
    }, 10);
  },
  methods: {
    onLogout() {
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.offline {
  position: fixed;
  top: 10px;
  background-color: red;
  color: white;
  z-index: 100000;
  padding: 10px;
  border-radius: 10px;
  transform: translateX(calc(50vw + 50%));
}
.md-d-none {
  display: none !important;
}
@media (max-width: 1199.98px) {
  .offline {
    top: 50px;
    transform: translateX(calc(50vw - 50%));
  }
  .termos-politica {
    margin-top: 50px;
  }
  .md-d-none {
    display: unset !important;
  }
}
</style>
