<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Tab
          :values="[
            { name: 'Urbana', slot: 'urbana' },
            { name: 'Rural', slot: 'rural' },
          ]"
          @tabchange="forceRerenderTab"
          ref="tab"
        >
          <template v-slot:urbana>
            <Crud
              :modal="false"
              @delete="$refs.datatable.update()"
              name="Propriedade"
              names="Propriedades"
              @filter="$refs.datatable.applyFilters($event)"
              :btnfilter="false"
              button="+"
              @oncreate="oncreate"
              @onformcreate="onformcreate"
              url="/PropriedadeAgricola"
              :iniorderby="{ column: 'created', type: 'DESC' }"
              :is-tab="true"
            >
              <template v-slot:list="form">
                <Datatable
                  v-if="tabComponent"
                  ref="datatable"
                  :fields="fields"
                  url="/PropriedadeAgricola/ReadUrban"
                  @edit="form.crud.edit($event)"
                  @delete="form.crud.delete($event)"
                  :where="where"
                >
                </Datatable>
              </template>
              <template v-slot:form="form">
                <PropriedadeAgricolaForm :form="form" />
              </template>
              <template v-slot:filter="data">
                <div>
                  <div class="mb-3">
                    <label class="form-label">Nome</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.filters.nome"
                    />
                  </div>
                </div>
              </template>
            </Crud>
          </template>
          <template v-slot:rural>
            <Crud
              :modal="false"
              :big-modal="true"
              @delete="$refs.datatable.update()"
              name="Propriedade"
              names="Propriedades"
              @filter="$refs.datatable.applyFilters($event)"
              :btnfilter="false"
              button="+"
              @oncreate="oncreate"
              @onformcreate="onformcreate"
              url="/PropriedadeAgricola"
              :iniorderby="{ column: 'created', type: 'DESC' }"
              :is-tab="true"
            >
              <template v-slot:list="form">
                <Datatable
                  v-if="tabComponent"
                  ref="datatable"
                  :fields="fields"
                  url="/PropriedadeAgricola/ReadRural"
                  @edit="form.crud.edit($event)"
                  @delete="form.crud.delete($event)"
                  :where="where"
                >
                </Datatable>
              </template>
              <template v-slot:form="form">
                <PropriedadeAgricolaForm :form="form" />
              </template>
              <template v-slot:filter="data">
                <div>
                  <div class="mb-3">
                    <label class="form-label">Nome</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.filters.nome"
                    />
                  </div>
                </div>
              </template>
            </Crud>
          </template>
        </Tab>
      </template>
    </Layout>
    <Alert ref="alert"></Alert>
  </div>
</template>

<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Alert from "../../components/Alert.vue";
import Tab from "../../components/form/Tab.vue";
import { mask } from "vue-the-mask";
import PropriedadeAgricolaForm from "../../components/form/PropriedadeAgricolaForm.vue";

export default {
  directives: { mask },
  components: {
    Crud,
    Layout,
    Tab,
    Datatable,
    Alert,
    PropriedadeAgricolaForm,
  },

  data() {
    return {
      tabComponent: true,
      fields: [
        { name: "Nome", value: "nome", style: { width: "100px" } },
        {
          name: "Endereço",
          value: "logradouro",
          style: { textAlign: "center" },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          edit: true,
          delete: false,
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    forceRerenderTab() {
      this.tabComponent = false;
      this.$nextTick(() => {
        this.tabComponent = true;
      });
    },
    onformcreate(form) {
      form.culturaPropriedades = [];
    },
    openEdit(form, data) {
      if (data.culturaPropriedades == null) {
        data.culturaPropriedades = [];
      }
      form.crud.edit(data);
    },
    oncreate(form) {
      if (form.expire == 0) {
        form.expire = null;
        return;
      }
      var expire = new Date();
      expire.setDate(expire.getDate() + form.expire);
      form.expire = expire;
    },
    clearProperty(form) {
      form.crud.form.culturaPropriedades = [];
    },
    clearTypeOrganization(form) {
      form.crud.form.tipoOrganizacao = null;
    },
    abrirModalAplicativo() {
      $(this.$refs.modal_aplicativo).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    fecharModalAplicativo() {
      $(this.$refs.modal_aplicativo).modal("hide");
    },
    abrirModalFinalizar(data) {
      $(this.$refs.modal_finalizar).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.id = data;
    },
    fecharModalFinalizar() {
      $(this.$refs.modal_finalizar).modal("hide");
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"/>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.3s;
}
.switch::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

.checkbox:checked + .switch::after {
  left: 20px;
}
.checkbox:checked + .switch {
  background-color: #006035;
}
.checkbox {
  display: none;
}
</style>