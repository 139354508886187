<template>
  <div class="h-100 w-100 bg-primary painel-fundo">
    <div class="container-xxl container-p-y">
      <div class="misc-wrapper" style="text-align: unset !important">
        <div class="card p-5">
          <div class="mb-5 text-center">
            <img src="/img/logo.png" alt="logo" width="200" class="img-fluid" />
          </div>

          <div v-if="!load">
            <div v-if="!success">
              <div v-if="error != 403">
                <h2 class="mb-3 text-center">Trocar a senha de acesso</h2>
                <div class="d-block">
                  <div class="mb-3">
                    <div class="form-password-toggle">
                      <label class="form-label"
                        >Defina uma senha
                        <span class="text-danger">*</span></label
                      >
                      <div class="input-group input-group-merge">
                        <input
                          ref="senha"
                          autocomplete="new-password"
                          :type="inpuType"
                          class="form-control"
                          placeholder="Senha"
                          v-model="form.senha"
                          @keyup.enter="logar"
                        />
                        <span
                          class="input-group-text cursor-pointer"
                          @click="
                            inpuType == 'password'
                              ? (inpuType = 'text')
                              : (inpuType = 'password')
                          "
                          ><i
                            class="bx bx-hide"
                            v-if="inpuType == 'password'"
                          ></i>
                          <i class="bx bx-show" v-else></i
                        ></span>
                      </div>
                    </div>
                    <div
                      class="form-text text-danger px-1"
                      v-if="erro && erro.password"
                    >
                      <span v-if="getError(erro.password, 12)" class="d-block"
                        >A senha deve apenas conter números e letras</span
                      >
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-password-toggle">
                      <label class="form-label"
                        >Repita a senha
                        <span class="text-danger">*</span></label
                      >
                      <div class="input-group input-group-merge">
                        <input
                          autocomplete="new-password"
                          :type="inpuType"
                          class="form-control"
                          placeholder="Senha"
                          v-model="form.rsenha"
                          @keyup.enter="logar"
                        />
                        <span
                          class="input-group-text cursor-pointer"
                          @click="
                            inpuType == 'password'
                              ? (inpuType = 'text')
                              : (inpuType = 'password')
                          "
                          ><i
                            class="bx bx-hide"
                            v-if="inpuType == 'password'"
                          ></i>
                          <i class="bx bx-show" v-else></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button class="btn btn-primary mt-3 w-100" @click="send">
                    Trocar senha
                  </button>
                </div>
              </div>
              <div v-else class="text-center">
                <h2 class="mb-2 mx-2 text-danger">O link foi expirado</h2>
                <p class="mb-4 mx-2">Gere uma nova solicitação</p>
                <button class="btn btn-primary" @click="$router.push('/login')">
                  Acessar o site
                </button>
              </div>
            </div>
            <div v-else class="text-center">
              <h2 class="mb-2 mx-2">Senha alterada</h2>
              <p class="mb-4 mx-2">Sua senha foi alterada com sucesso</p>
              <div class="mb-4">
                <i
                  class="bx bx-check text-white bg-success"
                  style="font-size: 100px; border-radius: 50%"
                ></i>
              </div>
              <button class="btn btn-primary" @click="$router.push('/login')">
                Acessar o site
              </button>
            </div>
          </div>
          <div class="p-3 text-center" v-else>
            <h4 class="mb-4 text-primary">Enviando...</h4>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      key: null,
      load: false,
      form: {
        senha: null,
        rsenha: null,
      },
      inpuType: "password",
      error: null,
      success: false,
    };
  },
  methods: {
    validate() {
      if (this.form.senha == null) {
        this.$refs.senha.setCustomValidity("Digite uma senha");
        this.$refs.senha.reportValidity();
        this.$refs.senha.focus();
      }
      if (this.form.senha.length < 8) {
        this.$refs.senha.setCustomValidity(
          "A senha deve ter no mínimo 8 caracteres"
        );
        this.$refs.senha.reportValidity();
        this.$refs.senha.focus();
        return false;
      }
      if (this.form.senha != this.form.rsenha) {
        this.$refs.senha.setCustomValidity("As senhas não são iguais");
        this.$refs.senha.reportValidity();
        this.$refs.senha.focus();
        return false;
      }
      return true;
    },
    send() {
      if (!this.validate()) {
        return;
      }
      this.load = true;
      this.success = false;
      this.urlPost("/Usuario/ChangePassword", {
        password: this.form.senha,
        key: this.key,
      })
        .then((e) => {
          this.success = true;
        })
        .catch((e) => {
          if (e.response.status) {
            this.error = e.response.status;
          } else {
            this.erro = e.response.data;
          }
        })
        .finally((e) => {
          this.load = false;
        });
    },
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.key = urlParams.get("key");
    if (this.key == null) {
      this.$router.push("/404");
    }
  },
};
</script>

<style >
.painel-fundo {
  background-image: url("/img/login.png");
  background-size: cover;
}
</style>