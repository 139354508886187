<template>
  <div class="h-100">
    <div class="row m-0 h-100 bg-primary">
      <div class="col-12 col-md-3 register-card">
        <div class="">
          <div class="px-3 mt-3 w-100 md-float-material form-material">
            <div class="card-block h-100">
              <div class="py-3">
                <div class="text-center">
                  <img src="/img/logol.svg" alt="" class="w-75" @click="$router.push('/login')" style="cursor: pointer" />
                </div>
              </div>

              <h4 class="text-white text-center my-1" style="font-size: 16px">
                Nova conta
              </h4>
              <div v-if="!load">
                <div v-if="!created">
                  <div class="mb-3">
                    <label class="form-label text-white"
                      >Nome <span class="text-danger">*</span></label
                    >
                    <input
                      ref="name"
                      type="text"
                      class="form-control"
                      v-model="form.name"
                      required
                      placeholder=""
                    />
                    <div
                      class="form-text text-danger px-1"
                      v-if="erro && erro.name"
                    >
                      <span v-if="getError(erro.name, 12)" class="d-block"
                        >Nome inválido</span
                      >
                    </div>
                  </div>

                  <!-- <div class="mb-3">
                    <label class="form-label text-white"
                      >Telefone <span class="text-danger">*</span></label
                    >
                    <input
                      type="tel"
                      class="form-control"
                      ref="telefone"
                      v-model="form.telefone"
                      placeholder="(51) 9 9999-9999"
                    />
                    <div
                      class="form-text text-danger px-1"
                      v-if="erro && erro.telefone"
                    >
                      <span v-if="getError(erro.telefone, 12)" class="d-block"
                        >Telefone inválido</span
                      >
                    </div>
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label text-white"
                      >E-mail <span class="text-danger">*</span></label
                    >
                    <input
                      ref="email"
                      type="email"
                      class="form-control"
                      v-model="form.email"
                      placeholder="email@gmail.com"
                    />
                    <div
                      class="form-text text-danger px-1"
                      v-if="erro && erro.email"
                    >
                      <span v-if="getError(erro.email, 12)" class="d-block"
                        >E-mail inválido</span
                      >
                      <span v-if="getError(erro.email, 3)" class="d-block"
                        >E-mail já registrado</span
                      >
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-password-toggle">
                      <label class="form-label text-white"
                        >Defina uma senha
                        <span class="text-danger">*</span></label
                      >
                      <div class="input-group input-group-merge">
                        <input
                          ref="password"
                          autocomplete="new-password"
                          :type="inpuType"
                          class="form-control"
                          placeholder="Senha"
                          v-model="form.password"
                          @keyup.enter="logar"
                        />
                        <span
                          class="input-group-text cursor-pointer"
                          @click="
                            inpuType == 'password'
                              ? (inpuType = 'text')
                              : (inpuType = 'password')
                          "
                          ><i
                            class="bx bx-hide"
                            v-if="inpuType == 'password'"
                          ></i>
                          <i class="bx bx-show" v-else></i
                        ></span>
                      </div>
                    </div>
                    <div
                      class="form-text text-danger px-1"
                      v-if="erro && erro.password"
                    >
                      <span v-if="getError(erro.password, 12)" class="d-block"
                        >A senha deve apenas conter números e letras</span
                      >
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="form-password-toggle">
                      <label class="form-label text-white"
                        >Repita a senha
                        <span class="text-danger">*</span></label
                      >
                      <div class="input-group input-group-merge">
                        <input
                          autocomplete="new-password"
                          :type="inpuType"
                          class="form-control"
                          placeholder="Senha"
                          v-model="form.rsenha"
                          @keyup.enter="logar"
                        />
                        <span
                          class="input-group-text cursor-pointer"
                          @click="
                            inpuType == 'password'
                              ? (inpuType = 'text')
                              : (inpuType = 'password')
                          "
                          ><i
                            class="bx bx-hide"
                            v-if="inpuType == 'password'"
                          ></i>
                          <i class="bx bx-show" v-else></i
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label text-white"
                      >Eu sou <span class="text-danger">*</span></label
                    >
                    <div class="row">
                      <div class="col-6">
                        <div class="form-check form-check-inline mb-2">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="produtor"
                            value="PRODUTOR"
                            v-model="form.tags"
                          />
                          <label
                            class="form-check-label text-white"
                            for="produtor"
                            >Produtor</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="empresa"
                            value="EMPRESA"
                            v-model="form.tags"
                          />
                          <label
                            class="form-check-label text-white"
                            for="empresa"
                            >Empresa</label
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-check form-check-inline mb-2">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="tecnico"
                            value="TECNICO"
                            v-model="form.tags"
                          />
                          <label
                            class="form-check-label text-white"
                            for="tecnico"
                            >Técnico</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input check-size"
                            type="checkbox"
                            id="consultor"
                            value="CONSULTOR"
                            v-model="form.tags"
                          />
                          <label
                            class="form-check-label text-white"
                            for="consultor"
                            >Consultor</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mb-3 text-white d-flex"
                    style="text-align: center"
                  >
                    <input
                      type="checkbox"
                      required="required"
                      class="form-check-input check-size"
                      style="min-width: 20px; min-height: 20px"
                      ref="termo"
                      v-model="form.termo"
                    />
                    <span style="font-size: 12px">
                      Eu li e concordo com os&nbsp;
                      <a href="/termo" target="_blank" class="text-success"
                        >Termos e Condições</a
                      >
                      &nbsp;do onAgro&nbsp;e a&nbsp;
                      <a href="/politica" target="_blank" class="text-success"
                        >Política de Privacidade</a
                      >
                    </span>
                  </div>
                  <div class="mb-4">
                    <button
                      class="btn btn-success d-grid w-100"
                      @click="sendData"
                    >
                      Registrar
                    </button>
                  </div>

                  <hr />
                  <!-- <p class="text-white pt-3">
                  Registre-se e tenha acesso a todas as funcionalidades da
                  plataforma onAgro.
                </p> -->
                  <div class="mb-5 mt-4">
                    <button
                      class="btn btn-success d-grid w-100"
                      @click="
                        form.password = null;
                        form.rsenha = null;
                        $router.push('/login');
                      "
                    >
                      Já possuo conta
                    </button>
                  </div>
                </div>
                <div
                  class="w-100 d-flex text-center"
                  style="margin-top: 20vh"
                  v-else
                >
                  <div class="m-auto">
                    <h4 class="mb-4 text-white">Conta criada com sucesso</h4>
                    <h5 class="mb-3 text-white">
                      Um e-mail de verificação foi enviado para
                    </h5>
                    <h4 class="text-success mb-5">{{ form.email }}</h4>
                    <div class="">
                      <button
                        class="btn btn-success d-grid w-100"
                        @click="
                          form.password = null;
                          form.rsenha = null;
                          $router.push('/login');
                        "
                      >
                        Acessar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100 d-flex text-center"
                style="margin-top: 20vh"
                v-else
              >
                <div class="m-auto">
                  <h5 class="mb-4 text-white">Criando...</h5>

                  <Load></Load>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-9 px-0 h-100 d-none d-md-flex"
        style="background-color: #417240"
      >
        <img src="img/login.png" alt="" class="h-100 p-5 pb-0 m-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import Load from "../../components/Load.vue";
export default {
  components: {
    Load,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        telefone: "",
        rsenha: "",
        termo: false,
        tags: [],
      },
      inpuType: "password",
      erro: null,
      load: false,
      created: false,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    validar() {
      if (this.form.name == null || this.form.name == "") {
        this.$refs.name.setCustomValidity("Digite um nome");
        this.$refs.name.reportValidity();
        this.$refs.name.focus();
        return false;
      }
      if (this.form.email == null || this.form.email == "") {
        this.$refs.email.setCustomValidity("Digite um email");
        this.$refs.email.reportValidity();
        this.$refs.email.focus();
        return false;
      }
      // if (this.form.telefone == null || this.form.telefone == "") {
      //   this.$refs.telefone.setCustomValidity("Digite um telefone");
      //   this.$refs.telefone.reportValidity();
      //   this.$refs.telefone.focus();
      //   return false;
      // } else {
      //   this.form.telefone = this.form.telefone.replace(/\D/g, "");
      // }
      if (this.form.password == null || this.form.password == "") {
        this.$refs.password.setCustomValidity("Digite uma senha");
        this.$refs.password.reportValidity();
        this.$refs.password.focus();
        return false;
      }
      if (this.form.password.length < 8) {
        this.$refs.password.setCustomValidity(
          "A senha deve ter no mínimo 8 caracteres"
        );
        this.$refs.password.reportValidity();
        this.$refs.password.focus();
        return false;
      }
      if (this.form.password != this.form.rsenha) {
        this.$refs.password.setCustomValidity("As senhas não são iguais");
        this.$refs.password.reportValidity();
        this.$refs.password.focus();
        return false;
      }
      if (!this.form.termo) {
        this.$refs.termo.setCustomValidity(
          "Os termos devem ser lidos e aceitos"
        );
        this.$refs.termo.reportValidity();
        this.$refs.termo.focus();
        return false;
      }
      return true;
    },
    sendData() {
      if (!this.validar()) {
        return;
      }
      this.load = true;
      this.erro = null;
      this.urlPost("/Usuario/Create", this.form)
        .then((e) => {
          this.created = true;
        })
        .catch((e) => {
          // debugger;
          this.erro = e.response.data;
        })
        .finally((e) => {
          this.load = false;
        });
    },
  },
};
</script>

<style>
.register-card {
  background-color: #006035;
  animation: show-card 0.3s ease-in-out;
  max-height: 100%;
  overflow: auto;
}
@keyframes show-card {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
.check-size {
  width: 20px;
  height: 20px;
}
</style>