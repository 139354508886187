<template>
  <div :class="load ? 'd-flex' : ''">
    <div class="pt-2" v-show="!load">
      <div class="clima-card" v-if="info">
        <div class="text-center px-1 mt-0">
          <div
            v-for="(d, i) in info.days"
            :key="i"
            :style="
              'display: inline-block; width: ' + 100 / info.days.length + '%;'
            "
          >
            <button
              title="Quinta-feira"
              :class="'botao-tempo-dia px-0' + (i == active ? ' ativo' : '')"
              @click="active = i"
            >
              <span style="font-size: 11px"
                >{{ week[new Date(d.cidade_clima.data).getDay()].abr }}
                {{ new Date(d.cidade_clima.data).getDate() }}</span
              >
              <span class="d-block" style="font-size: 16px"
                >{{
                  getPrecipitacaoFaltante(
                    d.cidade_clima.precipitacao_total, i
                  ).format(0, 1)
                }}
              </span>
              <span class="d-block" style="font-size: 10px; margin-top: -7px"
                >mm</span
              >
              <div class="row mx-0" style="margin-top: -3px">
                <div class="col-6 px-0">
                  <span style="font-size: 13px"
                    >{{ d.cidade_clima.temperatura_max.format(0, 0) }}º</span
                  >
                </div>
                <div class="col-6 px-0">
                  <span style="font-size: 13px;"
                    >{{ d.cidade_clima.temperatura_min.format(0, 0) }}º</span
                  >
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="clima-card" v-if="info">
        <div>
          <div
            style=""
            v-for="(d, i) in info.days"
            :key="i"
            v-show="i == active && hour"
          >
            <span class="temperatura-atual-texto text-center" v-if="hour"
              >Previsão para
              {{ new Date(hour.data).toLocaleString('pt-BR').substring(0, 17) }}</span
            >
            <div v-if="hour">
              <div class="row mx-0 my-2">
                <div class="col-12">
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-cloud-showers-heavy fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{
                            getPrecipitacaoFaltante(hour.precipitacao, i).format(
                              0,
                              1
                            )
                          }}
                          mm</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-wind fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{ hour.vento_velocidade.format(0, 1) }} km/h</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-tint fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{ hour.umidade.format(0, 0) }} %</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-flag fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima">{{
                          hour.vento_vetorial.replaceAll("W", "O")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="previsao-item-clima">
                    <div class="mini-card dados-clima-inferior px-0">
                      <div class="text-center">
                        <font-awesome-icon
                          class="icon-clima"
                          icon="fa fa-clock fa-2x"
                        />
                      </div>
                      <div class="text-center">
                        <span class="texto-dados-clima"
                          >{{ hour.pressao.format(0, 0) }} hPa</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="row mini-card m-0">
                  <div class="col-6 px-1">
                    <div class="p-0 d-flex">
                      <span class="temperatura-atual m-auto"
                        >{{ hour.temperatura.format(0, 0) }}º</span
                      >
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div>
                      <div class="d-flex m-0">
                        <div class="dados-clima-max-min">
                          <div class="d-flex h-100">
                            <font-awesome-icon
                              class="icon-clima m-auto"
                              style="color: red"
                              icon="fa fa-thermometer-full fa-2x"
                            />
                          </div>
                        </div>
                        <div class="dados-clima-max-min">
                          <span class="texto-clima-min-max">Máxima</span>
                          <span class="texto-dados-clima-min-max" 
                            >{{
                              d.cidade_clima.temperatura_max.format(0, 0)
                            }}º</span
                          >
                        </div>
                      </div>
                      <div class="d-flex m-0">
                        <div class="dados-clima-max-min">
                          <div class="d-flex h-100">
                            <font-awesome-icon
                              class="icon-clima m-auto"
                              style="color: #82b1ff"
                              icon="fa fa-thermometer-empty fa-2x"
                            />
                          </div>
                        </div>
                        <div class="dados-clima-max-min">
                          <span class="texto-clima-min-max">Mínima</span>
                          <span class="texto-dados-clima-min-max"
                            >{{
                              d.cidade_clima.temperatura_min.format(0, 0)
                            }}º</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clima-card text-center" v-show="info">
        <div class="mt-2" v-if="info">
          <div style="width: 18%; display: inline-block">
            <button
              title="Temperatura"
              @click="activeClima = 'temperatura'"
              :class="
                'botao-tempo-dia-grafico px-0' +
                (activeClima == 'temperatura' ? ' ativo' : '')
              "
            >
              <font-awesome-icon
                class="icon-clima"
                icon="fa fa-thermometer-full"
              />
            </button>
          </div>
          <div style="width: 18%; display: inline-block">
            <button
              title="Chuva"
              @click="activeClima = 'precipitacao'"
              :class="
                'botao-tempo-dia-grafico px-0' +
                (activeClima == 'precipitacao' ? ' ativo' : '')
              "
            >
              <font-awesome-icon
                class="icon-clima"
                icon="fa fa-cloud-showers-heavy"
              />
            </button>
          </div>
          <div style="width: 18%; display: inline-block">
            <button
              title="Vento"
              @click="activeClima = 'vento_velocidade'"
              :class="
                'botao-tempo-dia-grafico px-0' +
                (activeClima == 'vento_velocidade' ? ' ativo' : '')
              "
            >
              <font-awesome-icon class="icon-clima" icon="fa fa-wind" />
            </button>
          </div>
          <div style="width: 18%; display: inline-block">
            <button
              title="Umidade"
              @click="activeClima = 'umidade'"
              :class="
                'botao-tempo-dia-grafico px-0' +
                (activeClima == 'umidade' ? ' ativo' : '')
              "
            >
              <font-awesome-icon class="icon-clima" icon="fa fa-tint" />
            </button>
          </div>
          <div style="width: 18%; display: inline-block">
            <button
              title="Pressão"
              @click="activeClima = 'pressao'"
              :class="
                'botao-tempo-dia-grafico px-0' +
                (activeClima == 'pressao' ? ' ativo' : '')
              "
            >
              <font-awesome-icon class="icon-clima" icon="fa fa-clock" />
            </button>
          </div>
        </div>
        <div class="mt-4 mb-0 mini-card">
          <canvas ref="grafico_clima" width="310" height="175"></canvas>
        </div>
      </div>
      <!---->
      <div class="clima-card" v-if="info">
        <div class="mini-card mt-2">
          <div class="text-center m-0">
            <div class="row m-0 px-1">
              <div class="d-inline clima-celula">
                <i class="fa fa-clock d-block"></i>
                Hora
                <span class="d-block">(hs)</span>
              </div>
              <div class="d-inline clima-celula">
                <i class="fa fa-thermometer-full d-block"></i>
                Temp
                <span class="d-block">(ºC)</span>
              </div>
              <div class="d-inline clima-celula">
                <i class="fa fa-cloud-showers-heavy d-block"></i>
                Chuva
                <span class="d-block">(mm)</span>
              </div>
              <div class="d-inline clima-celula">
                <i class="fa fa-wind d-block"></i>
                Vento
                <span class="d-block">(km/h)</span>
              </div>
              <div class="d-inline clima-celula final">
                <i class="fa fa-tint d-block"></i>
                Umid
                <span class="d-block">(%)</span>
              </div>
            </div>
            <div
              class="row m-0 px-1"
              v-for="(d, i) in getFilterData(info.days[this.active].data)"
              :key="i"
            >
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ new Date(d.data).toLocaleTimeString('pt-BR').substring(0, 5) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.temperatura.format(0, 0) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.precipitacao.format(0, 1) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.vento_velocidade.format(0, 1) }}
              </div>
              <div
                :class="
                  'd-inline clima-celula final' +
                  (i == info.days[this.active].data.length - 1 ? ' baixo' : '')
                "
                style="padding: 2px"
              >
                {{ d.umidade.format(0, 0) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="hour"
          class="w-100"
          style="text-align: center; color: rgb(0, 96, 53)"
        >
          <small
            >Ultima atualização:
            {{ new Date(hour.created).toLocaleString() }}</small
          >
        </div>
        <div class="mt-3 text-center px-3">
          <small style="color: rgb(0, 96, 53)">weatherapi</small>
        </div>
      </div>
      <!---->
    </div>
    <div v-if="load" class="text-center m-auto p-5">
      <Load color="#006035"></Load>
    </div>
  </div>
</template>
<script>
import Load from "../Load.vue";
export default {
  components: {
    Load,
  },
  data() {
    return {
      active: null,
      activeClima: "temperatura",
      info: null,
      hour: null,
      grafico: null,
      load: true,
      data: null,
    };
  },
  methods: {
    getFilterData(d) {
      if (this.data != null) {
        var nd = [];
        var now = new Date(this.data).getTime();

        for (const da of d) {
          // debugger;
          var di = new Date(da.data).getTime();
          if (di >= now) {
            nd.push(da);
          }
        }
        return nd;
      } else {
        return d;
      }
    },
    getPrecipitacaoFaltante(total, tab) {
      // debugger
      var now = new Date(this.data).getTime();

      var horas = this.info.days[tab].data;
      for (const da of horas) {
        var di = new Date(da.data).getTime();

        if (di < now) {
          total -= da.precipitacao;
        }
      }
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    getData(cidadeId, data) {
      clearTimeout(this.next);
      this.data = data;
      this.load = true;
      this.next = setTimeout(() => {
        if (this.grafico) {
          this.grafico.destroy();
          this.grafico = null;
        }
        this.info = null;
        this.active = null;
        this.urlGet("/WeatherForecast/GetWeatherForecastById/" + cidadeId)
          .then((e) => {
            this.info = e.data;
            var now = new Date(data).getDate();




            for (let i = 0; i < this.info.days.length; i++) {
              var d = new Date(this.info.days[i].cidade_clima.data).getDate();
              if (d == now) {
                this.active = i;
                break;
              }
            }
            if (this.active == null) {
              this.active = 0;
            }

            this.updateHour();
          })
          .catch((e) => {})
          .finally(() => {
            this.load = false;
          });
      }, 300);
    },
    updateChart() {
      var cor = "#727272";
      var label = "Temperatura (Cº)";
      var type = "line";
      var labels = [];
      var data = [];
      var field = "temperatura";
      var min = 0;
      switch (this.activeClima) {
        case "temperatura": {
          cor = "#F49C18";
          break;
        }
        case "precipitacao": {
          type = "bar";
          field = "precipitacao";
          label = "Chuva (mm)";
          cor = "#BBD1FF";
          break;
        }
        case "vento_velocidade": {
          field = "vento_velocidade";
          label = "Vento (km/h)";
          break;
        }
        case "umidade": {
          field = "umidade";
          label = "Umidade (%)";
          cor = "#3E97FF";
          break;
        }
        case "pressao": {
          field = "pressao";
          label = "Pressão (hPa)";
          cor = "#FF3E3E";
          min = null;
          break;
        }
      }

      for (const h of this.info.days[this.active].data) {
        labels.push(new Date(h.data).toLocaleTimeString('pt-BR').substring(0, 2));
        data.push(h[field]);
      }
      //  debugger;
      if (!this.grafico) {
        try {
          this.grafico = new Chart(this.$refs.grafico_clima, {
            type: type,
            data: {
              labels: labels,
              datasets: [
                {
                  fill: true,
                  borderColor: cor,
                  backgroundColor: cor + "99",
                  label: label,
                  data: data,
                },
              ],
            },
            options: {
              animation: {
                duration: 300,
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: min == 0,
                      min: min == null ? undefined : min,
                    },
                  },
                ],
              },
            },
          });
        } catch (ex) {}
      } else {
        this.grafico.config.type = type;
        this.grafico.data.labels = labels;
        this.grafico.data.datasets[0].label = label;
        this.grafico.data.datasets[0].data = data;
        this.grafico.data.datasets[0].borderColor = cor;
        this.grafico.data.datasets[0].backgroundColor = cor + "99";
        this.grafico.options.scales.yAxes[0].ticks.beginAtZero = min == 0;
        this.grafico.options.scales.yAxes[0].ticks.min =
          min == null ? undefined : min;
        this.grafico.update();
      }
    },
    updateHour() {
      var now = new Date().getHours();
      var d = null;
      for (const day of this.info.days[this.active].data) {
        var t = new Date(day.data).getHours();
        if (t > now) {
          break;
        }
        d = day;
      }
      if (d == null) {
        d = this.info.days[this.active].data[0];
      }
      this.hour = d;
      setTimeout(() => {
        this.updateChart();
      }, 10);
    },
    beforeUnmount() {
      setTimeout(() => {
        if (this.grafico) {
          this.grafico.destroy();
          this.grafico = null;
        }
      }, 300);
    },
  },
  watch: {
    activeClima() {
      this.updateChart();
    },
    active(a) {
      if (a != null) {
        this.updateHour();
      }
    },
  },
};
</script>

<style>
.clima-card {
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  /* border: 1px solid rgb(150, 149, 149); */
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /* box-shadow: 0px 0px 0.2em #000000; */
}

.botao-tempo-dia {
  border: 1px solid #989998;
  background-color: #fff;
  height: auto;
  width: 58px;
  /* box-shadow: 0px 0px 8px 7px rgb(30 30 30 / 10%); */
  cursor: pointer;
  border-radius: 10%;
  color: #989998;
  font-size: 18px;
  font-weight: 510;
  outline: none !important;
  box-shadow: 0px 0px 0.1em #000000;
}

.temperatura-atual-mm {
  font-size: 18px;
  color: #006035;
  text-align: left;
  display: inline;
}

.temperatura-atual-texto {
  font-size: 14px;
  color: #006035;
  display: block;
  font-weight: 600;
}
.mini-card {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  /*box-shadow: 0px 0px 0.2em #000000;*/
}

.dados-clima-inferior {
  width: 95%;
  display: inline-block;
  color: #0e9d27;
}

.botao-tempo-dia.ativo {
  color: #ffff;
  background-color: #006035;
  border: 1px solid #006035;
}
.temperatura-atual {
  color: #006035;
  text-align: center;
  font-size: 60px;
  font-weight: 800;
  /* text-shadow: 3px 3px #00603544; */
}
.clima-celula.top {
  border-top: 1px solid #006035;
}

.clima-celula {
  width: 20%;
  border-bottom: 1px solid #006035;
  border-right: 1px solid #006035;
  font-size: 12px;
  color: #006035;
}

.clima-celula i {
  font-size: 16px;
}

.clima-celula.final {
  border-right: 0px;
}

.clima-celula.baixo {
  border-bottom: 0px;
}

.texto-dados-clima-min-max {
  font-size: 14px;
  display: block;
  margin-left: 5px;
  color: #006035;
  font-weight: 600;
}

.texto-clima-min-max {
  font-size: 14px;
  display: block;
  margin-left: 5px;
  color: #006035;
  font-weight: 600;
}
.dados-clima-max-min {
  font-size: 20px;
  display: block;
  margin-left: 5px;
  color: rgb(15, 157, 39);
}
.icon-clima {
  font-size: 24px;
}
.previsao-item-clima {
  width: 20%;
  display: inline-block;
}
.texto-dados-clima {
  font-size: 11px;
}
.botao-tempo-dia-grafico {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 10%;
  color: #989998;
  font-size: 18px;
  font-weight: 510;
  outline: none !important;
  box-shadow: 0px 0px 0.1em #000000;
  border: 1px solid #989998;
  background-color: #fff;
}
.botao-tempo-dia-grafico.ativo {
  color: #ffff;
  background-color: #006035;
  border: 1px solid #006035;
}
</style>