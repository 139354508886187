<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <div>
          <div class="row">
            <div
              v-for="(c, i) in cards"
              @click="$router.push(c.link)"
              :key="i"
              class="col-lg-3 col-12 col-sm-6 col-md-4"
            >
              <div
                :class="
                  'card mb-4 card-link' +
                  (c.disabled ? ' bg-secondary text-white' : '')
                "
              >
                <div class="card-header">
                  <h5 class="card-title">{{ c.name }}</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4 text-center">
                      <i
                        :class="'card-icon menu-icon tf-icons bx bx-' + c.icon"
                      ></i>
                    </div>
                    <div class="col-8 text-center card-total px-0">
                      {{ c.total != null ? c.total.format(0) : "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "../../components/layout/Tema.vue";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      cards: [
        {
          name: "Usuários",
          value: "usuario",
          link: "/administracao/usuarios",
          icon: "user",
          disabled: false,
        },
        {
          name: "Dispositivos",
          value: "adminDispositivos",
          link: "/administracao/dispositivos",
          icon: "chip",
          disabled: false,
        },
        // {
        //   name: "Parâmetros",
        //   value: "parametro",
        //   link: "/administracao/parametros",
        //   icon: "cog",
        //   disabled: false,
        // },
        // {
        //   name: "Token",
        //   value: "token",
        //   link: "/administracao/tokens",
        //   icon: "qr",
        //   disabled: true,
        // },
        {
          name: "Api token",
          value: "api_token",
          link: "/administracao/api-tokens",
          icon: "key",
          disabled: false,
        },
        // {
        //   name: "Cidades",
        //   value: "cidade",
        //   link: "/administracao/cidades",
        //   icon: "map",
        //   disabled: true,
        // },
        // {
        //   name: "Estados",
        //   value: "estado",
        //   link: "/administracao/estados",
        //   icon: "map-alt",
        //   disabled: true,
        // },
        // {
        //   name: "Região",
        //   value: "regiao",
        //   link: "/administracao/regioes",
        //   icon: "sitemap",
        //   disabled: true,
        // },
        // {
        //   name: "Propriedade agricola",
        //   value: "propriedade_agricola",
        //   link: "/administracao/propriedades-agricola",
        //   icon: "map-pin",
        //   disabled: true,
        // },
        // {
        //   name: "Local",
        //   value: "local",
        //   link: "/administracao/locais",
        //   icon: "pin",
        //   disabled: true,
        // },
        // {
        //   name: "Talhão",
        //   value: "talhao",
        //   link: "/administracao/talhoes",
        //   icon: "area",
        //   disabled: true,
        // },
        // {
        //   name: "Empresa",
        //   value: "empresa",
        //   link: "/administracao/empresas",
        //   icon: "buildings",
        //   disabled: true,
        // },
        // {
        //   name: "Leituras das estações",
        //   value: "estacao_leitura",
        //   link: "/administracao/estacao-leituras",
        //   icon: "file",
        //   disabled: false,
        // },
        {
          name: "Firmware",
          value: "firmware",
          link: "/administracao/firmwares",
          icon: "code-alt",
          disabled: false,
        },
        // {
        //   name: "Técnicos",
        //   value: "tecnico",
        //   link: "/administracao/tecnicos",
        //   icon: "group",
        //   disabled: true,
        // },
        // {
        //   name: "Visita técnica",
        //   value: "visita_tecnica",
        //   link: "/administracao/visitas-tecnica",
        //   icon: "trip",
        //   disabled: true,
        // },
        // {
        //   name: "Suporte",
        //   value: "suporte",
        //   link: "/administracao/suporte",
        //   icon: "help-circle",
        //   disabled: true,
        // },
        // {
        //   name: "Categoria de produto",
        //   value: "categoria_produto",
        //   link: "/administracao/categorias-produto",
        //   icon: "package",
        //   disabled: true,
        // },
        // {
        //   name: "Tipos de identificação",
        //   value: "tipo_identificacao",
        //   link: "/administracao/tipos-identificacao",
        //   icon: "id-card",
        //   disabled: true,
        // },
        {
          name: "Sensores",
          value: "sensores",
          link: "/administracao/sensores",
          icon: "id-card",
        },
        {
          name: "Modulos",
          value: "modulos",
          link: "/administracao/modulos",
          icon: "id-card",
        },
      ],
    };
  },
  methods: {
    getTotals() {
      this.urlGet("/Counter")
        .then((e) => {
          for (const c of this.cards) {
            var value = e.data[c.value];
            if (value != null) {
              c.total = value;
            }
          }
        })
        .catch((e) => {});
    },
  },
  mounted() {
    this.getTotals();
  },
};
</script>

<style>
.card-icon {
  font-size: 50px;
}
.card-total {
  font-size: 30px;
  font-weight: bold;
}
.card-link {
  cursor: pointer;
}
</style>