<template>
  <div>
    <div
      style="border: 2px dashed #006035; height: 100px; display: flex"
      class="file-drop"
      @click="$refs.input.click()"
      @drop="
        $event.preventDefault();
        files($event.dataTransfer.files);
      "
      @dragover="$event.preventDefault()"
    >
      <div style="margin: auto">
        <div style="padding: 10px">
          <h4 v-if="file" style="text-align: center">{{ file }}</h4>
          <h5 v-else style="text-align: center">
            {{ title ? title : "" }}
          </h5>
        </div>
      </div>
    </div>

    <input
      ref="input"
      type="file"
      style="display: none"
      :accept="accept"
      @change="
        $event.preventDefault();
        files($event.target.files);
      "
    />
  </div>
</template>

<script>
export default {
  props: ["open", "uri", "title", "accept"],
  data() {
    return {
      file: null,
    };
  },
  mounted() {
    if (this.open === true) {
      this.$refs.input.click();
    }
  },
  methods: {
    files(file) {
      this.file = file[0].name;
      this.$emit("data", file[0]);
    },
  },
};
</script>

<style>
.file-drop {
  background-color: #fff;
}
</style>
