<template>
  <div>
    <select :disabled="disabled" class="form-select" v-model="valueUsed">
      <option :value="null" v-if="empty">{{ empty }}</option>
      <option :value="v[field]" v-for="(v, i) in values" :key="i">
        <slot
          name="body"
          v-if="type == 'slot'"
          :value="v[label]"
          :row="v"
        ></slot>
        <span v-else> {{ v[label] }}</span>
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["url", "label", "field", "type", "value", "empty", "first", "disabled"],
  data() {
    return {
      values: [],
      valueUsed: this.value,
    };
  },
  methods: {
    getData() {
      this.urlPost(this.url).then((res) => {
        this.values = res.data.itens;
        if (this.first && this.values.length > 0) {
          this.valueUsed = this.values[0][this.field];
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    valueUsed(v) {
      this.$emit("value", v);
    },
  },
};
</script>