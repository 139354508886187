<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          name="Usuário"
          names="Usuários"
          button="+"
          @onformcreate="openNew"
          @filter="$refs.datatable.applyFilters($event)"
          url="/AdminUser/User"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/AdminUser/Read'"
              @edit="openEdit(form, $event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:premium="data">
                <div class="text-center">
                  <i
                    class="menu-icon tf-icons bx bx-check text-success"
                    v-if="data.row.premium"
                  ></i
                  ><i class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
              <template v-slot:online="data">
                <div class="text-center">
                  <i
                    class="menu-icon tf-icons bx bx-check text-success"
                    v-if="data.row.online"
                  ></i
                  ><i class="menu-icon tf-icons bx bx-x text-danger" v-else></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Nome <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >E-mail <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  v-model="form.crud.form.email"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.email"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Login <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.login"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.login"
                ></Error>
              </div>

              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Senha  <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.password"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.password"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label"
                  >Telefone <span class="text-danger">*</span></label
                >
                <input
                  type="tel"
                  class="form-control"
                  v-model="form.crud.form.telefone"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.telefone"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label"
                  >Nível de acesso <span class="text-danger">*</span></label
                >
                <select class="form-select" v-model="form.crud.form.level">
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.level"
                ></Error>
              </div>
              <div class="mb-3 col-md-4 col-12">
                <label class="form-label"
                  >Token <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.token"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.token"
                ></Error>
              </div>

              <div class="mb-3">
                <label class="form-label"
                  >Tags</label
                >
                <div class="row">
                  <div class="col-12">
                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="produtor"
                        value="PRODUTOR"
                        v-model="form.crud.form.tags"
                      />
                      <label class="form-check-label" for="produtor"
                        >Produtor</label
                      >
                    </div>

                    <div class="form-check form-check-inline mb-2">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="tecnico"
                        value="TECNICO"
                        v-model="form.crud.form.tags"
                      />
                      <label class="form-check-label" for="tecnico"
                        >Técnico</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="empresa"
                        value="EMPRESA"
                        v-model="form.crud.form.tags"
                      />
                      <label class="form-check-label" for="empresa"
                        >Empresa</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input check-size"
                        type="checkbox"
                        id="consultor"
                        value="CONSULTOR"
                        v-model="form.crud.form.tags"
                      />
                      <label class="form-check-label" for="consultor"
                        >Consultor</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label"
                  >Uso da api</label
                >
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input check-size"
                      type="checkbox"
                      id="api"
                      v-model="form.crud.form.api"
                    />
                    <label class="form-check-label" for="api">Uso da api</label>
                  </div>
                </div>
              </div>

              <div class="mb-3 col-12 col-lg-6">
                <label class="form-label"
                  >Número de acesso web
                  <span class="text-danger">*</span></label
                >
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.max_web_acesso"
                />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <label class="form-label"
                  >Número de acesso mobile
                  <span class="text-danger">*</span></label
                >
                <input
                  type="number"
                  class="form-control"
                  v-model="form.crud.form.max_mobile_acesso"
                />
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">E-mail verificado</label>
                <input
                  type="datetime-local"
                  max="9999-12-31T23:59"
                  class="form-control"
                  v-model="form.crud.form.verified"
                />
              </div>
              <hr />
              <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    Alert,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        name: {
          operator: "LIKE",
          replace: "%{value}%",
        },
        level: {
          operator: "=",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Nome", value: "name" },
        { name: "Login", value: "login" },
        { name: "E-mail", value: "email" },
        {
          name: "Level",
          value: "level",
          style: { width: "100px" },
          format: (v, d) => {
            if (v == 10) {
              return "Admin";
            }
            if (v == 5) {
              return "Suporte";
            }
            if (v == 2) {
              return "Especial";
            }
            if (v == 0) {
              return "Normal";
            }
          },
        },
        {
          name: "Último acesso",
          value: "ultimoAcesso",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != "0001-01-01T00:00:00") {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "Não Acessado";
            }
          },
        },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
        {
          name: "Online",
          value: "online",
          style: { width: "50px" },
          type: "slot",
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          edit: true,
          delete: true,
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    openNew(form) {
      form.tags = [];
    },
    openEdit(form, data) {
      if (data.tags == null) {
        data.tags = [];
      }
      form.crud.edit(data);
    },
  },
};
</script>
