import axios from 'axios'
import env from '../../env.json'
const UAParser = require('ua-parser-js');
var SESSION = localStorage.getItem('session');
const API_URL = env.dev ? env.api_test_url : env.api_url;
var HEADERS = {

};
var SESSION_TOKEN = localStorage.getItem('session_token');
function randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
function randomSession() {
    SESSION_TOKEN = randomString(255);
    localStorage.setItem('session_token', SESSION_TOKEN);
    return SESSION_TOKEN;
}

if (SESSION_TOKEN == null) {
    randomSession();
}
if (SESSION != null) {
    SESSION = JSON.parse(SESSION);
    try {
        HEADERS['Authorization'] = 'Bearer ' + SESSION.auth.token;
    } catch (ex) {
        SESSION = null;
        localStorage.removeItem('session');
    }
}
if (SESSION == null) {
    HEADERS['Authorization'] = 'Basic ' + SESSION_TOKEN;
}

function parseUrl(url, origin = false) {
    if (origin) {
        return (env.dev ? '' : window.location.origin) + API_URL + url;
    }
    return API_URL + url;
}



var GLOBAL = {
    connection: null,
    events: {},
    sessionTimeout: null,
};

export default {
    data() {
        return {
            version: env.version,
            appName: 'OnAgro',
            appFullName: 'OnAgro',
            apiUrl: API_URL,
            session: SESSION_TOKEN

        }
    },
    methods: {
        getCaptcha(data, succes, error) {
            return this.urlPost('/captcha/image', data).then((e) => {
                if (succes) {
                    succes(e);
                }
            }).catch((e) => {
                if (error) {
                    error(e);
                }
            });
        },
        downloadLink(url, fileName) {
            if (SESSION != null) {
                url += '?token=' + SESSION.auth.token
            }
            const downloadLink = document.createElement("a");
            downloadLink.target = '_blank';
            downloadLink.href = parseUrl(url);
            document.body.appendChild(downloadLink);
            //   downloadLink.download = fileName;
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        downloadBase64File(base64Data, fileName, contentType = 'application/octet-stream') {
            const linkSource = `data:${contentType};base64,${base64Data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        globalEvent(type, data) {
            if (GLOBAL.events && GLOBAL.events[type]) {
                for (const func of GLOBAL.events[type]) {
                    try {
                        func(data);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },
        addEvent(type, func) {
            if (GLOBAL.events[type] == null) {
                GLOBAL.events[type] = [];
            }
            GLOBAL.events[type].push(func);
        },
        removeEvent(type, func) {
            if (GLOBAL.events[type]) {
                GLOBAL.events[type] = GLOBAL.events[type].filter(fu => fu != func);
            }
        },
        createWebsocket(url, event, reconnect = 10000) {
            return;
            var urlParse = parseUrl(url, true);
            urlParse = urlParse.replace('http', 'ws');
            const socket = new WebSocket(urlParse);
            socket.addEventListener('open', (ev) => {
                if (event && event.open) {
                    event.open(ev);
                }
            });
            socket.addEventListener('close', (ev) => {
                if (reconnect && (event == null || (event.close == null || event.close(ev)))) {
                    setTimeout(() => {
                        this.createWebsocket(url, event, reconnect);
                    }, reconnect);
                }
            });
            socket.addEventListener('message', (ev) => {
                if (ev.data == 'PING') {
                    socket.send('PONG');
                    return;
                }
                if (event && event.message) {
                    event.message(ev);
                }
            });
            socket.addEventListener('error', (ev) => {
                socket.close();
                if (event && event.error) {
                    event.error(ev);
                }
            });
            return socket;
        },
        sessionWebsocket() {
            if (SESSION == null) {
                return;
            }
            if (GLOBAL.connection) {
                GLOBAL.connection.close();
            }
            var main = this;
            const socket = this.createWebsocket('/session/connection?token=' + SESSION.auth.token, {
                message(event) {
                    if (event.data == 'LOGOUT') {
                        main.logout(false);
                        main.globalEvent('logout');
                        return;
                    }
                    if (event.data == 'UPDATE_USER') {
                        main.updateUser()
                        return;
                    }
                },
                close() {
                    GLOBAL.connection = null;
                    return SESSION != null;
                },
                open() {
                    main.updateUser();
                }
            });

            GLOBAL.connection = socket;
        },
        isMobile() {
            var isMobile = {
                Android: function () {
                    return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/android-/i);
                },
                BlackBerry: function () {
                    return navigator.userAgent.match(/BlackBerry/i);
                },
                iOS: function () {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/ios-/i);
                },
                Opera: function () {
                    return navigator.userAgent.match(/Opera Mini/i);
                },
                Windows: function () {
                    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
                },
                any: function () {
                    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
                }
            }
            return isMobile.any() != null;
        },
        getError(erros, code) {
            if (erros == null) {
                return null;
            }
            for (const erro of erros) {
                if (erro.code == code) {
                    return erro;
                }
            }
            return null;
        },
        crudCreate(type, data) {
            return this.urlPost(type, data);
        },
        crudUpdate(type, data) {
            return this.urlPut(type, data);
        },
        crudDelete(type, id) {
            return this.urlDelete(type + '/' + id);
        },
        crudList(type, filter) {
            HEADERS["Content-Type"] = "application/json";
            return axios.post(parseUrl(type), filter, { headers: HEADERS });
        },
        urlGet(url) {
            return axios.get(parseUrl(url), { headers: HEADERS });
        },
        urlPost(url, data, config = {}) {

            if(data !== undefined && data.fileUpload !== undefined){
                HEADERS["Content-Type"] = "multipart/form-data";
            }

            return axios.post(parseUrl(url), data, { ...config, headers: HEADERS });
        },
        urlDelete(url) {
            return axios.delete(parseUrl(url), { headers: HEADERS });
        },
        urlPut(url, data) {
            
            if(data !== undefined && data.fileUpload !== undefined){
                HEADERS["Content-Type"] = "multipart/form-data";
            }

            return axios.put(parseUrl(url), data, { headers: HEADERS });
        },
        login(data, success, error) {
            this.urlPost('/Usuario/Login', data).then((res) => {
                SESSION = res.data;
                try {
                    if(JsReceiver){
                        JsReceiver.postMessage('login', JSON.stringify(SESSION));
                    }
                } catch(e){
                    console.log(e);
                }
                HEADERS['Authorization'] = 'Bearer ' + SESSION.auth.token;
                localStorage.setItem('session', JSON.stringify(SESSION));
                this.sessionWebsocket();
                this.setSessionTimeout();
                success(res.data);

            }).catch((e) => {
                error(e.response)
            });
        },
        setSessionTimeout() {
            if (!SESSION) {
                clearTimeout(GLOBAL.sessionTimeout);
                return;
            }

            if (!GLOBAL.sessionTimeout) {

                var now = new Date().getTime();
                var expire = new Date(SESSION.auth.expire).getTime();
                var expire = expire - now;

                if (expire <= 0) {
                    this.logout();
                } else {
                    if (expire < 2147483647) {
                        GLOBAL.sessionTimeout = setTimeout(() => {
                            this.logout(false);
                            this.checkSession();
                        }, expire);
                    }

                }

            }
        },
        updateUser(suc, err, fin) {
            this.urlGet('/Usuario/GetUser').then((e) => {
                SESSION.user = e.data;
                localStorage.setItem('session', JSON.stringify(SESSION));
                this.setSessionTimeout();
                this.globalEvent('update_user');
                if (suc) {
                    suc(e);
                }
            }).catch((e) => {
                if (err) {
                    suc(e);
                }
                if (e.response.status) {
                    this.logout();
                    this.$router.push('/login');
                }
            }).finally(() => {
                if (fin) {
                    fin();
                }
            });
        },
        getUser() {
            return SESSION != null && SESSION.user != null ? SESSION.user : null
        },
        userLevel(level) {
            var user = this.getUser();
            return user && user.level >= level;
        },
        logout(send = true) {
            if (send) {
                this.urlDelete('/Usuario/Logout');
            }
            try {
                if(JsReceiver){
                    JsReceiver.postMessage('logout');
                }
            } catch(e){
                console.log(e);
            }
            //delete HEADERS['Authorization'];
            this.session = randomSession();
            HEADERS['Authorization'] = 'Basic ' + SESSION_TOKEN;
            SESSION = null;
            localStorage.removeItem('session');
            if (GLOBAL.connection) {
                GLOBAL.connection.close();
            }
        },
        checkSession() {
            if (SESSION != null) {
                this.updateUser();
                if (GLOBAL.connection == null) {
                    this.sessionWebsocket();
                }
                return true;
            } else {
                if (!this.$route.meta.public) {
                    this.$router.push("/login");
                }
                return false;
            }
        },
        copyTextToClipboard(text) {
            navigator.clipboard.writeText(text).then(function () {
                console.log('Async: Copying to clipboard was successful!');
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        parseUserAgent(user) {
            let parser = new UAParser(user);
            return parser.getResult();
        },
        getRun(url, action, time) {
            var run = true;
            this.urlGet(url).then((data) => {
                action(data);
            }).catch(() => {

            }).finally(() => {
                run = false;
            })
            return setInterval(() => {
                if (!run) {
                    run = true;
                    this.urlGet(url).then((data) => {
                        action(data);
                    }).catch(() => {

                    }).finally(() => {
                        run = false;
                    })
                }
            }, time)
        }
    },
    created() {

    },
    unmounted() {

    }
}