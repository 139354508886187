<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          name="Dispositivo Administação"
          names="Dispositivos Administação"
          @onformcreate="onform"
          @filter="$refs.datatable.applyFilters($event)"
          url="/AdminDevices"
          :cancreate="false"
          :closeoncreate="false"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/AdminDevices/Read'"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:status="data">
                <div style="text-align: center;" v-if="data.row.status == 1"><img src="../../../public/img/pending.svg" alt="Pendente"></div>
                <div style="text-align: center;" v-if="data.row.status == 2"><img src="../../../public/img/offline.svg" alt="Offline"></div>
                <div style="text-align: center;" v-if="data.row.status == 3"><img src="../../../public/img/ok.svg" alt="Online"></div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <DispositivosForm :form="form" />
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.nome"
                />
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>
    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Alert from "../../components/Alert.vue";
import DispositivosForm from "../../components/form/DispositivosForm.vue";

export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Alert,
    DispositivosForm,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      where: {
        nome: {
          operator: "LIKE",
          replace: "%{value}%",
        },
      },

      fields: [
        {
          name: "Status",
          value: "status",
          style: { width: "70px" },
          type: "slot",
        },
        {
          name: "Proprietário",
          value: "usuario",
        },
        {
          name: "Nome Dispositivo",
          value: "nome",
        },
        {
          name: "Modelo",
          value: "modelo",
        },
        {
          name: "QR CODE",
          value: "qrcode",
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          edit: true,
          delete: false,
          style: { width: "100px", textAlign: "center" },
        },
      ],
    };
  },
};
</script>
