<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="!isMobile()"
          @create="!isMobile() ? $refs.datatable.update() : null"
          @delete="$refs.datatable.update()"
          @forceRerender="$refs.datatable.update()"
          @onformcreate="onformcreate"
          @onformedit="onformedit"
          name="Firmware"
          names="Firmwares"
          button="+"
          @filter="$refs.datatable.applyFilters($event)"
          url="/AdminFirmware/Firmware"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form" v-if="loading">
            <Datatable
              ref="datatable"
              :fields="fields"
              url="/AdminFirmware/Read"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:file_size="data">
                <div class="text-center" v-if="data.row.fileSize">
                  {{ toGb(data.row.fileSize) }}
                  <i
                    class="menu-icon tf-icons bx bx-download"
                    style="cursor: pointer; margin-left: 10px"
                    @click="download(data.row)"
                  ></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Nome *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Versao *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.version"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.version"
                ></Error>
              </div>
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label">File *</label>
                <DropFile
                  v-if="!form.crud.form.fileSize"
                  @data="
                    form.crud.form.fileUpload = $event;
                    debugger;
                  "
                  accept=".bin"
                  :title="
                    'Selecione ou arraste o arquivo binário. Máximo ' +
                    toGb(104857600)
                  "
                ></DropFile>
                <div v-else>
                  <div
                    class="card shadow-none bg-transparent border border-primary mb-3"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          {{ toGb(form.crud.form.fileSize) }}
                          <i
                            class="menu-icon tf-icons bx bx-download"
                            style="cursor: pointer; margin-left: 10px"
                            @click="download(form.crud.form)"
                          ></i>
                        </div>
                        <div
                          class="col-6 text-danger"
                          style="text-align: right"
                        >
                          <i
                            class="menu-icon tf-icons bx bx-x"
                            style="cursor: pointer; margin-left: 10px"
                            @click="form.crud.form.fileSize = null"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.file_size"
                ></Error>
              </div>
              <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.name"
                />
              </div>
            </div>
          </template>
        </Crud>
      </template>
    </Layout>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import DropFile from "../../components/crud/input/DropFile.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    Alert,
    DropFile,
  },
  data() {
    return {
      loading: true,
      showToken: null,
      numeros: null,
      where: {
        nome: {
          operator: "LIKE",
          replace: "%{value}%",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Nome", value: "name" },
        { name: "Versão", value: "version" },
        {
          name: "Arquivo",
          value: "file_size",
          type: "slot",
        },
        {
          name: "Criado",
          value: "created",
          style: { minWidth: "200px", width: "200px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          edit: true,
          delete: true,
          value: "status",
          style: { minWidth: "100px", width: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    uploadFile(event) {
      const file = event.target.files[0];
      const maxSize = 1024 * 1024 * 10;

      if (file && file.size > maxSize) {
        alert("Esse Arquivo Excede 10MB.");
        event.target.value = "";
        return;
      }
    },
    forceRerender() {
      this.component = false;
      this.$nextTick(() => {
        this.component = true;
      });
    },
    download(f) {
      this.urlPost("/AdminFirmware/FirmwareDownload/" + f.id)
        .then((e) => {
          this.error = null;
          this.$refs.alert.show(
            "Baixando",
            "Firmware Baixado com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          var byteCharacters = atob(e.data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "application/octet-stream",
          });

          var url = window.URL.createObjectURL(blob);

          var link = document.createElement("a");
          link.href = url;
          link.download = f.name + ".bin"; 
          document.body.appendChild(link);
          link.click();

          // Clean up
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível Fazer o Download!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
    onformcreate(form) {
      form.fileUpload = null;
      this.ref_form = form;
    },
    onformedit(form) {
      form.fileUpload = null;
      this.ref_form = form;
    },
  },
};
</script>
