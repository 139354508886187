import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import api from './mixin/api';
import onagro from './mixin/onagro';
import util from './mixin/util';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

window.getToken = function () {
    return localStorage.getItem('session');
}
createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).use(VueAxios, axios).mixin(api).mixin(onagro).mixin(util).mount('#app')