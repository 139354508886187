<template>
  <div class="h-100">
    <div class="row m-0 h-100 bg-primary">
      <div class="col-12 col-md-3 login-card d-flex">
        <div class="m-auto w-100">
          <div class="px-3 w-100 md-float-material form-material">
            <div class="card-block">
              <div class="py-3">
                <div class="text-center">
                  <img src="/img/logol.svg" alt="" class="w-75" />
                </div>
              </div>
              <div v-if="!load">
                <div v-if="!success">
                  <div>
                    <div class="mb-3">
                      <label class="form-label text-white"
                        >E-mail <span class="text-danger">*</span></label
                      >
                      <input
                        type="email"
                        class="form-control"
                        placeholder="e-mail"
                        v-model="form.email"
                      />
                      <div class="form-text text-danger px-1" v-if="erro">
                        <span class="d-block">E-mail não encontrado</span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 mt-3">
                    <button
                      class="btn btn-success d-grid w-100"
                      @click="recovery"
                    >
                      Recuperar
                    </button>
                  </div>
                  <hr />
                  <p class="text-white pt-3">
                    Um e-mail de recuperação será enviado.
                  </p>
                  <div class="pt-4">
                    <button
                      class="btn btn-success d-grid w-100"
                      @click="$router.push('/login')"
                    >
                      Voltar para o acesso
                    </button>
                  </div>
                </div>
                <div
                  class="w-100 d-flex text-center"
                  style="margin-top: 20vh"
                  v-else
                >
                  <div class="m-auto">
                    <h4 class="mb-4 text-white">E-mail enviado com sucesso</h4>
                    <h5 class="mb-3 text-white">
                      Um e-mail de recuperação foi enviado para
                    </h5>
                    <h4 class="text-success mb-5">{{ form.email }}</h4>
                    <div class="">
                      <button
                        class="btn btn-success d-grid w-100"
                        @click="
                          form.senha = null;
                          form.rsenha = null;
                          $router.push('/login');
                        "
                      >
                        Voltar ao acesso
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100 d-flex text-center"
                style="margin-top: 10vh"
                v-else
              >
                <div class="m-auto">
                  <Load></Load>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-9 px-0 h-100 d-none d-md-flex"
        style="background-color: #417240"
      >
        <img src="img/login.png" alt="" class="h-100 p-5 pb-0 m-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import Load from "../../components/Load.vue";
export default {
  components: {
    Load,
  },
  data() {
    return {
      form: {
        email: "",
      },
      erro: null,
      load: false,
      success: false,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    recovery() {
      this.erro = null;
      this.load = true;
      this.urlPost("/Usuario/Recover", this.form)
        .then((e) => {
          this.success = true;
        })
        .catch((e) => {
          debugger;
          this.erro = true;
        })
        .finally((e) => {
          this.load = false;
        });
    },
  },
};
</script>

<style>
.login-card {
  background-color: #006035;
}
</style>
