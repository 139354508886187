<template>
  <div class="h-100 w-100">
    <div v-bind:class="isTab ? 'h-100' :'card h-100'">
      <div v-bind:class="isTab ? 'd-flex' : 'card-header d-flex'" v-if="!show.form.slot">
        <h4 class="card-title-datatable">
          <span @click="$emit('forceupdate')" style="cursor: pointer">
            {{ names }}</span
          >
        </h4>
        <button
          class="btn btn-primary mx-1"
          @click="$emit(b.action, b)"
          :title="b.title"
          v-for="(b, i) in btns"
          :key="i"
        >
          <i :class="'menu-icon tf-icons bx m-0 bx-' + b.icon"></i> {{ b.name }}
        </button>
        <button
          class="btn btn-primary mx-1"
          @click="openFilter"
          title="Filtros"
          v-if="btnfilter == null || btnfilter == true"
        >
          <i class="menu-icon tf-icons bx bx-filter-alt m-0"></i>
        </button>
        <button
          class="btn btn-primary"
          @click="create"
          v-if="cancreate == null || cancreate"
          title="Criar novo"
        >
          {{ button }}
        </button>
      </div>
      <div class="card-header d-flex" v-else>
        <h4 class="card-title-datatable">
          <button class="form-back-button" @click="closeForm">
            <i class="menu-icon tf-icons bx bx-left-arrow-alt m-0"></i>
          </button>
          {{ name }}
        </h4>

        <button
          class="btn btn-primary mx-1"
          @click="$emit(b.action, b)"
          :title="b.title"
          v-for="(b, i) in btnsaction"
          v-show="
            (editId && (b.edit == null || b.edit)) ||
            (!editId && (b.create == null || b.create))
          "
          :key="i"
        >
          <i :class="'menu-icon tf-icons bx m-0 bx-' + b.icon"></i> {{ b.name }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          v-if="editId"
          @click="doUpdate"
        >
          <i class="menu-icon tf-icons bx m-0 bx-save"></i>
          Salvar
        </button>
        <button type="button" class="btn btn-primary" v-else @click="doCreate">
          <i class="menu-icon tf-icons bx m-0 bx-save"></i>
          Criar
        </button>
      </div>
      <div class="card-body form-body">
        <div class="form-body" v-if="show.form.slot">
          <slot name="form" :crud="this"></slot>
        </div>
        <div class="h-100" v-if="show.list">
          <slot name="list" :crud="this"></slot>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="modal_form" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" :class="{ 'max-width-100vh': bigModal }" role="document">
        <div class="modal-content" >
          <div class="modal-header bg-primary pb-3">
            <h5 class="modal-title text-white">
              {{ editId ? "Editar" : "Criar" }} {{ name }}
            </h5>
            <button type="button" class="btn-close" @click="closeForm"></button>
          </div>
          <div class="modal-body form-modal-body">
            <slot name="form" :crud="this" v-if="show.form.modal"></slot>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              v-if="editId"
              @click="doUpdate"
            >
              Salvar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-else
              @click="doCreate"
              :disabled="load"
            >
              <div
                class="spinner-border spinner-border-sm text-white"
                role="status"
                v-if="load"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              {{ load ? "Criando..." : "Criar" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="modal_filter" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" :class="{ 'max-width-100vh': bigModal }" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary pb-3">
            <h5 class="modal-title text-white">Filtrar {{ names }}</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeFilter"
            ></button>
          </div>
          <div class="modal-body form-modal-body">
            <slot
              name="filter"
              :crud="this"
              :filters="filters"
              v-if="show.filter"
            ></slot>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="filters = {}"
            >
              Limpar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$emit('filter', filters)"
            >
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_delete">
      <div class="modal-dialog modal-dialog-centered" :class="{ 'max-width-100vh': bigModal }" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Deletar registro</h5>
            <button
              type="button"
              class="btn-close"
              @click="deleteId = null"
            ></button>
          </div>
          <div class="modal-body">
            <span>Deseja realmente deletar o registro {{ deleteId }}?</span>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="doDelete(deleteId)"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    </div>
    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Alert from "../Alert.vue";
export default {
  props: [
    "modal",
    "bigModal",
    "name",
    "names",
    "url",
    "cancreate",
    "btns",
    "button",
    "btnfilter",
    "btnsaction",
    "closeonedit",
    "closeoncreate",
    "isTab"
  ],
  components: {
    Alert,
  },
  data() {
    return {
      show: {
        form: {
          modal: false,
          slot: false,
          bigModal: false,
        },
        filter: false,
        list: true,
      },
      filters: {},
      editId: null,
      form: {},
      deleteId: null,
      error: null,
      load: false,
    };
  },
  methods: {
    openFilter() {
      this.show.filter = true;
      $(this.$refs.modal_filter).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    closeFilter() {
      $(this.$refs.modal_filter).modal("hide");
      setTimeout(() => {
        this.show.filter = false;
      }, 500);
    },
    openForm() {
      if (this.modal) {
        $(this.$refs.modal_form).modal({
          backdrop: "static",
          keyboard: false,
        });
        $(this.$refs.modal_form).appendTo("body");
        this.show.form.modal = true;
      } else {
        this.show.list = false;
        this.show.form.slot = true;
      }
    },
    closeForm() {
      if (this.modal) {
        $(this.$refs.modal_form).modal("hide");
        setTimeout(() => {
          this.show.form.modal = false;
        }, 500);
      } else {
        this.show.list = true;
        this.show.form.slot = false;
      }
    },
    create() {
      this.error = null;
      this.form = {};
      this.editId = null;
      this.$emit("onformcreate", this.form);
      this.openForm();
    },
    edit(data) {

      var newData = Object.assign({}, data);

      this.error = null;
      this.form = {}
      this.editId = newData.id;
      this.form = newData;
      this.$emit("onformedit", this.form)
      this.openForm();
    },
    delete(id) {
      this.deleteId = id;
    },
    doDelete(id) {
      this.deleteId = null;
      this.load = true;
      this.$emit("ondelete", id);
      this.crudDelete(this.url, id)
        .then((res) => {
          this.$refs.alert.show(
            "Deletado",
            "O registro deletado com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          this.$emit("delete", id);
        })
        .catch((e) => {
          this.$refs.alert.show(
            "Erro",
            "Não foi possível deletar o registro!",
            null,
            5000,
            "bg-danger"
          );
        })
        .finally(() => {
          this.load = false;
        });
    },
    doCreate() {
      this.load = true;
      this.error = null;
      this.$emit("oncreate", this.form);

      this.crudCreate(this.url, this.form)
        .then((res) => {
          this.$refs.alert.show(
            "Criado",
            "O registro foi criado com sucesso!",
            null,
            5000,
            "bg-primary"
          );

          //this.$emit("create", res.data);

          if (this.closeoncreate == null || this.closeoncreate) {
            this.closeForm();
          } else {
            this.edit(res.data);
          }
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro",
              "Alguns campos possuem erros!",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro",
              "Não foi possível criar o registro!",
              null,
              5000,
              "bg-danger"
            );
          }
        })
        .finally(() => {
          this.load = false;

          this.$emit("forceRerender");
        });

    },
    doUpdate() {
      this.load = true;
      this.error = null;
      this.$emit("onupdate", this.form);
      this.crudUpdate(this.url, this.form)
        .then((res) => {
          this.$refs.alert.show(
            "Atualizado",
            "O registro foi atualizado com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          //this.$emit("update", res.data);
          if (this.url == "/AdminUser/User") {
            this.updateUser();
          }
          if (this.closeonedit == null || this.closeonedit) {
            this.closeForm();
          }
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro",
              "Alguns campos possuem erros!",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro",
              "Não foi possível atualizar o registro!",
              null,
              5000,
              "bg-danger"
            );
          }
        })
        .finally(() => {

          this.load = false;

          this.$emit("forceRerender");


        });
    },
  },
  mounted() {},
  watch: {
    deleteId(id) {
      if (id != null) {
        $(this.$refs.modal_delete).modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        $(this.$refs.modal_delete).modal("hide");
      }
    },
  },
};
</script>

<style>
.form-body {
  min-height: calc(100% - 96px);
}
.reload-icon {
  line-height: 25px;
  cursor: pointer;
}
.form-modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.form-body {
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.form-back-button {
  background-color: transparent;
  outline: none;
  border: none;
}
.form-back-button i {
  font-size: 30px;
}
.max-width-100vh {
  max-width: 100vh;
}
@media only screen and (max-width: 821px) {
.btn{
  font-size: 20px;
}
}
</style>