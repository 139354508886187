<template>
  <div class="w-100 h-100 p-1 m-auto">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 148.8 149.7"
      style="enable-background: new 0 0 148.8 149.7"
      xml:space="preserve"
    >
      <g id="_x31_O">
        <path
          :style="{ fill: _color }"
          d="M23.5,44.8c9.9-18.1,29.9-30,50.6-29.8l0,0c26.1-1.3,63.2,24.5,41.9,50.7c-0.8-3.9-4.2-17-16.6-26.4
		c-14.3-10.8-30.3-8.7-34.9-8.1C46.4,33.6,35.4,45,32.4,48.3c2.3-2.2,19.4-17.7,42.5-14.2c3.4,0.5,22.1,3.4,31.5,19.5
		c3.6,6.1,4.6,12.2,4.9,16.1c-10.9,7.8-22.1,2.9-32.1-5.1c-21.9-17.4-55.1-4.9-57.8,22.9c0,0.2,0,0.5-0.1,0.7
		c-0.1,1.6-2.4,1.9-2.9,0.3c-1.4-5-2.2-10.3-2.2-15.8C16.3,62.6,19,53,23.5,44.8L23.5,44.8z"
        />
      </g>
      <g id="_2O">
        <path
          :style="{ fill: _color }"
          d="M48.7,111.2c14.6,11,30.8,8.9,35.5,8.3c18.4-2.4,29.6-14,32.7-17.4c-2.4,2.2-19.7,18-43.2,14.5
		c-3.5-0.5-22.5-3.4-32.1-19.8c-3.7-6.3-4.7-12.4-5-16.3c11.1-7.9,22.5-2.9,32.7,5.1c22.3,17.7,56,5,58.7-23.3c0-0.2,0-0.5,0.1-0.7
		c0.1-1.7,2.4-1.9,2.9-0.3c1.5,5.1,2.2,10.5,2.2,16.1c0,10.3-2.7,20-7.4,28.4c0,0,0.1,0,0.1-0.1c-10.1,18.4-30.4,30.5-51.5,30.3l0,0
		c-26.5,1.3-64.2-24.9-42.6-51.5C32.7,88.3,36.1,101.6,48.7,111.2z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ["color"],
  data() {
    return {
      _color: this.color ? this.color : "currentColor",
    };
  },
  methods: {},
};
</script>
