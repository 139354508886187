<template>
  <button type="button" :disabled="load">
    <div
      class="spinner-border spinner-border-sm text-white"
      role="status"
      v-if="load"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    {{ load ? loadtext : text }}
  </button>
</template>
<script>
export default {
  props: ["text", "loadtext"],
  data() {
    return {
      load: false,
    };
  },
};
</script>