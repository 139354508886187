<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <h3>Cadastro QR Code</h3>
        <div>
          <Tab
            :values="[
              { name: 'Gerar', slot: 'gerar' },
              { name: 'Consumir', slot: 'consumir' },
              { name: 'Registrados', slot: 'registrados' },
            ]"
            @tabchange="$event == 'acesso' ? $refs.acesso.reload() : null"
            ref="tab"
          >
            <template v-slot:gerar v-if="gerarComponent">
              <div style="display: flex; align-content: end">
                <h3>QR Codes Gerados</h3>

                <div style="margin-left: auto">
                  <LoadButton
                    style="flex; margin-bottom: 25px; background-color: #006035;"
                    ref="btn_gerar"
                    @click="abrirModalRegistrados"
                    class="btn btn-success"
                    text="Gerar"
                    loadtext="Gerando..."
                  >
                  </LoadButton>
                </div>
              </div>

              <Datatable
                ref="datatable"
                :fields="fieldRegistrados"
                :url="'/QRCode/Generated/Read'"
                :where="where"
              >
                <template v-slot:qrcode="data">
                  <div>
                    <div class="text-center" style="display: flex">
                      <div
                        class="txt"
                        style="margin-right: 10px; cursor: pointer"
                        @click="abrirModalQR(data.row.qrcode)"
                      >
                        clique para ver
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:acoes="data">
                  <div>
                    <button
                      @click="OpenWindowPrintGroupQrCode(data.row.id)"
                      style="
                        width: 100%;
                        height: 100%;
                        padding: 10px;
                        background: #006035;
                        border-radius: 5px;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          text-align: center;
                          color: white;
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        Baixar pdf
                      </div>
                    </button>
                  </div>
                </template>
              </Datatable>
            </template>
            <template v-slot:consumir v-if="consumirComponent">
              <div style="display: flex; align-content: end">
                <h3>QR Codes Impressos</h3>
                <div style="margin-left: auto">
                  <LoadButton
                    style="flex; margin-bottom: 25px; background: #006035;"
                    @click="abrirModalImprimir"
                    ref="btn_imprimir"
                    class="btn btn-success"
                    text="Reeimprimir"
                  >
                  </LoadButton>
                  <LoadButton
                    style="flex; margin-bottom: 25px; margin-left: 25px; background: #006035;"
                    @click="abrirModalConsumir"
                    ref="btn_consumir"
                    class="btn btn-success"
                    text="Consumir"
                    loadtext="Criando..."
                  >
                  </LoadButton>
                </div>
              </div>
              <Datatable
                ref="datatable"
                :fields="fieldsConsumir"
                :url="'/QRCode/Consume/Read'"
                @edit="form.crud.edit($event)"
                @delete="form.crud.delete($event)"
                :where="where"
              >
                <template v-slot:qrcode="data">
                  <div>
                    <div class="text-center" style="display: flex">
                      <div>
                        {{ data.row.qrcode }}
                      </div>
                    </div>
                  </div>
                </template>
              </Datatable>
            </template>
            <template v-slot:registrados v-if="registradosComponent">
              <h3>QR Codes Gerados</h3>
              <Tab
                :values="[
                  { name: 'Consumidos', slot: 'consumidos' },
                  { name: 'Vinculados', slot: 'vinculados' },
                ]"
                @tabchange="$event == 'acesso' ? $refs.acesso.reload() : null"
                ref="tab"
              >
                <template v-slot:consumidos>
                  <Datatable
                    ref="datatable"
                    :fields="fieldsGerados"
                    :url="'/QRCode/Consumed/Read'"
                    :where="where"
                  >
                    <template v-slot:qrcode="data">
                      <div>
                        <div class="text-center" style="display: flex">
                          <div>
                            {{ data.row.qrcode }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </Datatable>
                </template>
                <template v-slot:vinculados>
                  <Datatable
                    ref="datatable"
                    :fields="fieldsGerados"
                    :url="'/QRCode/Linked/Read'"
                    :where="where"
                  >
                    <template v-slot:qrcode="data">
                      <div>
                        <div class="text-center" style="display: flex">
                          <div>
                            {{ data.row.qrcode }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </Datatable>
                </template>
              </Tab>
            </template>
          </Tab>
        </div>
      </template>
    </Layout>
    <div class="modal fade" tabindex="-1" ref="modal_imprimir">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Reeimprimir QR Code</h5>
            <button
              type="button"
              class="btn-close"
              @click="fecharModalImprimir"
            ></button>
          </div>
          <div class="modal-body">
            <div
              style="
                width: 100%;
                height: 100%;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                display: inline-flex;
              "
            >
              <button
                style="
                  padding: 10px;
                  background: #006035;
                  border-radius: 5px;
                  justify-content: center;
                  align-items: center;
                  gap: 5px;
                  display: flex;
                "
                @click="decrementPrnt"
              >
                <div
                  style="
                    width: 15px;
                    height: 15px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      width: 14px;
                      height: 3px;
                      background: white;
                      border-radius: 6px;
                    "
                  ></div>
                </div>
              </button>
              <div
                style="
                  width: 100px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 4px;
                  display: inline-flex;
                "
              >
                <input
                  style="
                    align-self: stretch;
                    height: 40px;
                    padding-left: 10px;
                    padding-right: 15px;
                    background: white;
                    border-radius: 5px;
                    overflow: hidden;
                    border: 1px #b0b0b0 solid;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    display: inline-flex;
                  "
                  :value="this.change.counterPrnt"
                />
              </div>
              <button
                style="
                  padding: 10px;
                  background: #006035;
                  border-radius: 5px;
                  justify-content: center;
                  align-items: center;
                  gap: 5px;
                  display: flex;
                "
                @click="incrementPrnt"
              >
                <div
                  style="
                    width: 15px;
                    height: 15px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    display: inline-flex;
                  "
                >
                  <div style="width: 16px; height: 16px; position: relative">
                    <div
                      style="
                        width: 16px;
                        height: 3px;
                        left: 6.5px;
                        top: 16px;
                        position: absolute;
                        transform: rotate(-90deg);
                        transform-origin: 0 0;
                        background: white;
                        border-radius: 6px;
                      "
                    ></div>
                    <div
                      style="
                        width: 16px;
                        height: 2.5px;
                        left: 0px;
                        top: 7px;
                        position: absolute;
                        background: white;
                        border-radius: 6px;
                      "
                    ></div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <hr />
          <div class="modal-footer">
            <LoadButton
              @click="Reprint"
              ref="btn_delete"
              class="btn btn-success"
              text="Imprimir"
              loadtext="Imprimindo..."
            >
            </LoadButton>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_consumir">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Consumir QR Code</h5>
            <button
              type="button"
              class="btn-close"
              @click="fecharModalConsumir"
            ></button>
          </div>
          <div class="modal-body">
            <div style="display: flex; justify-content: space-between">
              <div style="margin-right: 10px" class="mb-3 col-md-6 col-12">
                <label class="form-label">QR Code</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="this.consume.qrcode"
                />
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Módulo</label>
                <SelectData
                  url="/QrCode/Module"
                  field="id"
                  label="name"
                  empty="Escolha o Módulo"
                  :value="
                    this.consume.moduloId == 0 ? null : this.consume.moduloId
                  "
                  @value="this.consume.moduloId = $event"
                >
                </SelectData>
              </div>
            </div>
          </div>
          <hr />
          <div class="modal-footer">
            <LoadButton
              @click="ConsumirCode"
              ref="btn_delete"
              class="btn btn-success"
              text="Consumir"
              loadtext="Consumindo..."
            >
            </LoadButton>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_gerar">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Gerar QR Code</h5>
            <button
              type="button"
              class="btn-close"
              @click="fecharModalRegistrados"
            ></button>
          </div>
          <div class="modal-body">
            <div
              style="
                width: 100%;
                height: 100%;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                display: inline-flex;
              "
            >
              <button
                style="
                  padding: 10px;
                  background: #006035;
                  border-radius: 5px;
                  justify-content: center;
                  align-items: center;
                  gap: 5px;
                  display: flex;
                "
                @click="decrementQR"
              >
                <div
                  style="
                    width: 15px;
                    height: 15px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      width: 14px;
                      height: 3px;
                      background: white;
                      border-radius: 6px;
                    "
                  ></div>
                </div>
              </button>
              <div
                style="
                  width: 100px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 4px;
                  display: inline-flex;
                "
              >
                <input
                  style="
                    align-self: stretch;
                    height: 40px;
                    padding-left: 10px;
                    padding-right: 15px;
                    background: white;
                    border-radius: 5px;
                    overflow: hidden;
                    border: 1px #b0b0b0 solid;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    display: inline-flex;
                  "
                  :value="this.change.counterQR"
                />
              </div>
              <button
                style="
                  padding: 10px;
                  background: #006035;
                  border-radius: 5px;
                  justify-content: center;
                  align-items: center;
                  gap: 5px;
                  display: flex;
                "
                @click="incrementQR"
              >
                <div
                  style="
                    width: 15px;
                    height: 15px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    display: inline-flex;
                  "
                >
                  <div style="width: 16px; height: 16px; position: relative">
                    <div
                      style="
                        width: 16px;
                        height: 3px;
                        left: 6.5px;
                        top: 16px;
                        position: absolute;
                        transform: rotate(-90deg);
                        transform-origin: 0 0;
                        background: white;
                        border-radius: 6px;
                      "
                    ></div>
                    <div
                      style="
                        width: 16px;
                        height: 2.5px;
                        left: 0px;
                        top: 7px;
                        position: absolute;
                        background: white;
                        border-radius: 6px;
                      "
                    ></div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <hr />
          <div class="modal-footer">
            <LoadButton
              @click="GerarCode"
              ref="btn_delete"
              class="btn btn-success"
              text="Gerar"
              loadtext="Gerando..."
            >
            </LoadButton>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_QR">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">QR Code(s)</h5>
            <button
              type="button"
              class="btn-close"
              @click="fecharModalQR"
            ></button>
          </div>
          <div class="modal-body">
            <span style="white-space: pre-line">
              {{ codes }}
            </span>
          </div>
          <hr />
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Alert from "../../components/Alert.vue";
import SelectData from "../../components/crud/input/SelectData.vue";
import Tab from "../../components/form/Tab.vue";
import LoadButton from "../../components/form/LoadButton.vue";

export default {
  components: {
    Layout,
    Datatable,
    Tab,
    SelectData,
    Alert,
    LoadButton,
  },
  data() {
    return {
      codes: null,
      gerarComponent: true,
      consumirComponent: true,
      registradosComponent: true,
      pdf: {
        qrcodes: [],
      },
      change: {
        counterQR: 1,
        counterPrnt: 1,
      },
      consume: {
        qrcode: null,
        moduloId: 0,
      },
      fieldsGerados: [
        { name: "Status", value: "status" },
        { name: "QR Code", value: "qrcode", type: "slot" },
        { name: "Proprietário", value: "proprietario" },
        {
          name: "Data Vinculação",
          value: "vinculacao",
          format: (v, d) => {
            if (v != null  && v != "0001-01-01T00:00:00") {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "Não Vinculado";
            }
          },
        },
        {
          name: "Data Criação",
          value: "criacao",
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
      ],
      fieldsConsumir: [
        { name: "Status", value: "status" },
        { name: "QR Code", value: "qrcode", type: "slot" },
        {
          name: "Data Criação",
          value: "criacao",
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
      ],
      fieldRegistrados: [
        { name: "Status", value: "status" },
        { name: "QR Code", value: "qrcode", type: "slot" },
        {
          name: "Data Criação",
          value: "criacao",
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
        { name: "Ações", value: "acoes", type: "slot" },
      ],
    };
  },
  methods: {
    forceRerenderGerar() {
      this.gerarComponent = false;
      this.$nextTick(() => {
        this.gerarComponent = true;
      });
    },
    forceRerenderConsumir() {
      this.consumirComponent = false;
      this.$nextTick(() => {
        this.consumirComponent = true;
      });
    },
    forceRerenderRegistrados() {
      this.registradosComponent = false;
      this.$nextTick(() => {
        this.registradosComponent = true;
      });
    },
    GerarCode() {
      this.urlPost("/QRCode/Generate", this.change)
        .then((e) => {
          this.error = null;
          this.$refs.alert.show(
            "Gerando",
            "QR Code(s) gerado(s) com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          this.change.counterQR = 0;
          this.forceRerenderGerar();
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível Gerar!",
              null,
              3000,
              "bg-danger"
            );
          }
        });

      this.fecharModalRegistrados();
    },
    ConsumirCode() {
      this.urlPost("/QRCode/Consume", this.consume)
        .then((e) => {
          this.error = null;
          this.$refs.alert.show(
            "Gerando",
            "QR Code(s) consumido(s) com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          this.consume.qrcode = "";
          this.forceRerenderConsumir();
          this.forceRerenderRegistrados();
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível Gerar!",
              null,
              3000,
              "bg-danger"
            );
          }
        });

      this.fecharModalConsumir();
    },
    OpenWindowPrintGroupQrCode(qrcodes) {
      window.open(`/admin/qrcode/grouper-print/${qrcodes}`, "_blank");

      setTimeout(function () {
        window.location.reload();
      }, 1000);
    },
    Reprint() {
      window.open(`/admin/qrcode/reprint/${this.change.counterPrnt}`, "_blank");

      this.fecharModalImprimir();
    },
    incrementQR() {
      this.change.counterQR++;
    },
    decrementQR() {
      if (this.change.counterQR > 1) this.change.counterQR--;
    },
    incrementPrnt() {
      this.change.counterPrnt++;
    },
    decrementPrnt() {
      if (this.change.counterPrnt > 1) this.change.counterPrnt--;
    },
    abrirModalQR(qrCodes) {
      $(this.$refs.modal_QR).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.codes = qrCodes.join("\n");
    },
    fecharModalQR() {
      $(this.$refs.modal_QR).modal("hide");
    },
    abrirModalConsumir() {
      $(this.$refs.modal_consumir).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    fecharModalConsumir() {
      $(this.$refs.modal_consumir).modal("hide");
    },
    abrirModalRegistrados() {
      $(this.$refs.modal_gerar).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    fecharModalRegistrados() {
      $(this.$refs.modal_gerar).modal("hide");
    },
    abrirModalImprimir() {
      $(this.$refs.modal_imprimir).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    fecharModalImprimir() {
      $(this.$refs.modal_imprimir).modal("hide");
    },
  },
};
</script>
<style>
.txt:hover {
  text-decoration: underline;
}
</style>
