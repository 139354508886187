<template>
  <div class="square-pin-icon" :title="data ? data.nome : ''">
    <div class="square-pin-icon-container">
      <div
        class="square-pin-icon-text-container"
        :style="'background-color: rgb(' + getColor(0) + ')'"
      >
        <span class="square-pin-icon-text">
          {{ point }}
        </span>
      </div>
    </div>
    <div class="square-pin-icon-point"></div>
  </div>
</template>

<script>
export default {
  prop: ["data", "type"],
  methods: {
    getColor(valor) {
      var r, g, b;
      if (this.type == "temperatura") {
        if (valor <= -30) {
          r = 149;
          g = 185;
          b = 217;
        } else if (valor <= -15) {
          var valorTemp = valor - -30;
          var percent = (valorTemp * 100) / 15;
          r = Math.round(149 - (149 * percent) / 100);
          g = Math.round(185 - (104 * percent) / 100);
          b = Math.round(217 - (44 * percent) / 100);
        } else if (valor <= 5) {
          var valorTemp = valor - -15;
          var percent = (valorTemp * 100) / 20;
          r = Math.round(0 - (0 * percent) / 100);
          g = Math.round(81 + (127 * percent) / 100);
          b = Math.round(173 - (85 * percent) / 100);
        } else if (valor <= 15) {
          var valorTemp = valor - 5;
          var percent = (valorTemp * 100) / 10;
          r = Math.round(0 + (255 * percent) / 100);
          g = Math.round(208 - (73 * percent) / 100);
          b = Math.round(88 - (88 * percent) / 100);
        } else if (valor <= 30) {
          var valorTemp = valor - 15;
          var percent = (valorTemp * 100) / 15;
          r = Math.round(255 - (7 * percent) / 100);
          g = Math.round(135 - (135 * percent) / 100);
          b = Math.round(0 - (0 * percent) / 100);
        } else if (valor <= 40) {
          var valorTemp = valor - 30;
          var percent = (valorTemp * 100) / 10;
          r = Math.round(248 - (92 * percent) / 100);
          g = Math.round(0 - (0 * percent) / 100);
          b = Math.round(0 - (0 * percent) / 100);
        } else if (valor <= 50) {
          var valorTemp = valor - 40;
          var percent = (valorTemp * 100) / 10;
          r = Math.round(156 - (31 * percent) / 100);
          g = Math.round(0 - (0 * percent) / 100);
          b = Math.round(0 - (0 * percent) / 100);
        } else {
          r = 125;
          g = 0;
          b = 0;
        }
      } else {
        var m = valor;
        if (m > 150) {
          m = 150;
        }
        switch (this.type) {
          case "vento_velocidade": {
            m *= 5;
            r = 150 + m;
            g = 150 - m;
            b = 150 - m;
            break;
          }
          case "umidade": {
            m *= 1;
            r = 150 - m;
            g = 150 - m;
            b = 150 + m;
            break;
          }
          default: {
            m *= 15;
            r = 150 - m;
            g = 150 - m;
            b = 150 + m;
          }
        }
      }
      return `${r}, ${g}, ${b}`;
    },
  },
};
</script>

<style>
</style>