<template>
  <div class="nav-align-top mb-4">
    <ul class="nav nav-tabs nav-fill">
      <li class="nav-item" v-for="(v, i) in values" :key="i">
        <button
          type="button"
          :class="'nav-link' + (active == i ? ' active' : '')"
          @click="active = i"
        >
          <i :class="'tf-icons bx bx-' + v.icon" v-if="v.icon"></i> {{ v.name }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <div
        :class="'tab-pane fade ' + (active == i ? ' active show' : '')"
        v-for="(v, i) in values"
        :key="i"
      >
        <slot :name="v.slot" :values="values"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["values"],
  data() {
    return {
      active: 0,
    };
  },
  watch: {
    active(i) {
      this.$emit("tabchange", this.values[i].slot);
    },
  },
  created() {},
};
</script>