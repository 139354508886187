<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <div>
          <h3>Relatório</h3>
          <Tab
            :values="[
              { name: 'Gerar', slot: 'gerar' },
              { name: 'Medidas', slot: 'medidas' },
            ]"
            ref="tab"
          >
            <template v-slot:gerar>
              <div class="page-container">
                <div>
                  <div class="section">
                    <h2>Gerar Relatório</h2>
                    <p>Para gerar relatório selecione a propriedade, QR code, sensor e período desejado.</p>

                    <div class="subsection">
                      <h3>Propriedade</h3>
                      <p>Local onde o dispositivo está instalado.</p>
                      <div class="mb-3 col-md-4 col-12">
                        <label class="form-label">
                          Nome da propriedade <span class="text-danger">*</span>
                        </label>
                        <select class="form-select" @change="onPropertyChange" :class="{ 'invalid-field': !isPropertyValid }">
                          <option value="" disabled selected>Selecione</option>
                          <option v-for="property in properties" :key="property.id" :value="property.id">
                            <img :src="property.icon" alt="icon" /> {{ property.nome }}
                          </option>
                          <option value="shared">Compartilhados Comigo</option>
                        </select>
                        <Error v-if="!isPropertyValid" :error="[{'code': 1}]" />
                      </div>
                    </div>

                    <div class="subsection">
                      <h3>Módulo</h3>
                      <p>Selecione o módulo onConnect conectado aos sensores.</p>
                      <div class="row">
                        <div class="mb-3 col-md-6 col-12">
                          <label class="form-label">
                            Módulo <span class="text-danger">*</span>
                          </label>
                          <select class="form-select" ref="qrCodeSelect" @change="onQRCodeChange" :class="{ 'invalid-field': !isQRCodeValid }">
                            <option value="" disabled selected>Selecione o módulo</option>
                            <option v-for="qrCode in qrCodeOptions" :key="qrCode.id" :value="qrCode.id">
                              {{ qrCode.nomeDispositivo }}
                            </option>
                          </select>
                          <Error v-if="!isQRCodeValid" :error="[{'code': 1}]" />
                          <p>Seus módulos cadastrados aparecem nesse seletor.</p>
                        </div>
                        <div class="mb-3 col-md-6 col-12">
                          <label class="form-label">
                            Escolha o Sensor <span class="text-danger">*</span>
                          </label>
                          <select class="form-select" v-model="selectedSensor" :class="{ 'invalid-field': !isSensorValid }">
                            <option value="null" disabled selected>Selecione o sensor</option>
                            <option v-for="sensor in sensorOptions" :key="sensor.id" :value="sensor.id">
                              {{ sensor.name }}
                            </option>
                          </select>
                          <Error v-if="!isSensorValid" :error="[{'code': 1}]" />
                        </div>
                      </div>
                    </div>

                    <div class="subsection">
                      <div class="row">
                        <h3>Período</h3>
                        <p>Selecione o dia que você pretende gerar o relatório.</p>
                        <div class="mb-3 col-md-6 col-12">
                          <label class="form-label">
                            Selecione o período que você pretende gerar o relatório. <span class="text-danger">*</span>
                          </label>
                          <select class="form-select" ref="periodSelect" @change="onPeriodChange" :class="{ 'invalid-field': !isPeriodValid }">
                            <option value="" disabled selected>Selecione o período</option>
                            <option value="daily">Diário</option>
                            <option value="monthly">Mensal</option>
                          </select>
                          <Error v-if="!isPeriodValid" :error="[{'code': 1}]" />
                        </div>
                        <div v-if="selectedPeriod === 'daily'" class="mb-3 col-md-6 col-12">
                          <label class="form-label">
                            Selecione a data <span class="text-danger">*</span>
                          </label>
                          <Datepicker
                            v-model="reportDate"
                            dayFormat="dd"
                            :locale="ptLocale"
                            ref="datepickerDaily"
                            minimumView="day"
                            placeholder="Selecione a data"
                            :class="{ 'invalid-field': !isDateValid }"
                            style="width: 100%; height: 42px;"
                            inputFormat="dd/MM/yyyy"
                            auto-close
                            @closed="handleDateClosed"
                          />
                          <Error v-if="!isDateValid" :error="[{'code': 1}]" />
                        </div>
                        <div v-if="selectedPeriod === 'monthly'" class="mb-3 col-md-6 col-12">
                          <label class="form-label">
                            Selecione o mês e ano <span class="text-danger">*</span>
                          </label>
                          <Datepicker
                            v-model="reportMonthYear"
                            :locale="ptLocale"
                            ref="datepickerMonthly"
                            minimumView="month"
                            placeholder="Selecione o mês"
                            :class="{ 'invalid-field': !isMonthYearValid }"
                            style="width: 100%; height: 42px;"
                            inputFormat="MM/yyyy"
                            auto-close
                            @closed="handleDateClosed"
                          />
                          <Error v-if="!isMonthYearValid" :error="[{'code': 1}]" />
                        </div>
                      </div>
                    </div>

                    <div class="subsection">
                      <h3>Formato de Exportação</h3>
                      <p>Selecione o formato de exportação do relatório.</p>
                      <div class="mb-3 col-md-6 col-12">
                        <label class="form-label">
                          Formato <span class="text-danger">*</span>
                        </label>
                        <select class="form-select" v-model="selectedFormat" :class="{ 'invalid-field': !isFormatValid }">
                          <option value="null" disabled selected>Selecione o formato</option>
                          <option value="pdf">PDF (Padrão onAgro)</option>
                          <option value="xlsx">XLSX (Planilha)</option>
                        </select>
                        <Error v-if="!isFormatValid" :error="[{'code': 1}]" />
                      </div>
                    </div>

                    <div class="row">
                      <div style="flex: 1">
                        <button class="btn btn-primary right-button" @click="generateReport">Gerar Relatório</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="loading" class="loading-overlay">
                  <div class="loading-content">
                    <h4 class="mb-4">Gerando Relatório</h4>
                    <h6 class="loading-message">
                      Coletando e compilando os dados para gerar seu relatório. Isso pode levar alguns instantes, por favor, aguarde...
                    </h6>
                    <div class="spinner-border text-primary spinner-border-lg" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
                <div ref="clickCatcher" class="click-catcher"></div>
              </div>
            </template>
            <template v-slot:medidas>
              <div class="page-container">
                <div>
                  <div class="section">
                    <h2>Unidades de medida</h2>
                    <p>As unidades selecionadas serão salvas e mostradas no relatório.</p>
                    <form @submit.prevent="saveUnits">
                      <div class="row">
                        <div class="col-md-4 col-12" v-for="(options, key) in availableUnits" :key="key">
                          <label class="form-label">{{ capitalize(key) }}</label>
                          <select class="form-select" v-model="units[key]">
                            <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-12">
                          <button type="submit" class="btn btn-primary right-button">Salvar unidades</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </template>
          </Tab>
        </div>
      </template>
    </Layout>
    <Alert ref="alert"></Alert>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import Layout from "../../components/layout/Tema.vue";
import SelectData from "../../components/crud/input/SelectData.vue";
import Alert from "../../components/Alert.vue";
import Error from "../../components/Error.vue";
import urbanIcon from '../../../public/assets/img/icons/urban-icon.svg';
import ruralIcon from '../../../public/assets/img/icons/rural-icon.svg';
import Datepicker from 'vue3-datepicker';
import Tab from "../../components/form/Tab.vue";
import { ptBR } from 'date-fns/locale';
import { arrayBufferToBase64 } from "../../mixin/util";

const customLocale = {
  ...ptBR,
  options: {
    ...ptBR.options,
    weekStartsOn: 1, // Semana começa na segunda-feira
  },
  localize: {
    ...ptBR.localize,
    day: (n, { width = 'abbreviated' }) => {
      const dayAbbreviations = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];
      return dayAbbreviations[n];
    },
  },
};

export default {
  name: 'MyComponent',
  components: {
    Layout,
    SelectData,
    Alert,
    Error,
    Datepicker,
    Tab
  },
  data() {
    return {
      ptLocale: customLocale,
      properties: [],
      sensorOptions: [],
      qrCodeOptions: [],
      selectedProperty: null,
      selectedQRCode: null,
      selectedSensor: null,
      selectedFormat: null,
      reportDate: null,
      loading: false,
      maxDate: null,
      isPropertyValid: true,
      isQRCodeValid: true,
      isSensorValid: true,
      isPeriodValid: true,
      isDateValid: true,
      isFormatValid: true,
      selectedPeriod: null,
      reportMonthYear: null,
      isMonthYearValid: true,
      units: {
        luminosidade: 'lux',
        pressao: 'atm',
        profundidade: 'm',
        temperatura: '°C',
        velocidade: 'km/h',
        volume: 'mm³'
      },
      availableUnits: {
        luminosidade: [],
        pressao: [],
        profundidade: [],
        temperatura: [],
        velocidade: [],
        volume: []
      }
    };
  },
  created() {
    this.loadProperties();
    this.loadAvailableUnits();
    this.loadUserUnits();
  },
  methods: {
    loadProperties() {
      Promise.all([
        this.urlPost('/PropriedadeAgricola/ReadUrban', {
          limit: -1,
          offset: 0,
          orderby: {},
          where: {}
        }, { contentType: 'application/json' }),
        this.urlPost('/PropriedadeAgricola/ReadRural', {
          limit: -1,
          offset: 0,
          orderby: {},
          where: {}
        }, { contentType: 'application/json' })
      ])
        .then((responses) => {
          const urbanResponse = responses[0].data;
          const ruralResponse = responses[1].data;
          const urbanProperties = Array.isArray(urbanResponse.itens) ? urbanResponse.itens : [];
          const ruralProperties = Array.isArray(ruralResponse.itens) ? ruralResponse.itens : [];

          urbanProperties.forEach((property) => {
            property.type = 'urban';
            property.icon = urbanIcon;
          });
          ruralProperties.forEach((property) => {
            property.type = 'rural';
            property.icon = ruralIcon;
          });
          this.properties = [...urbanProperties, ...ruralProperties];
          this.properties.sort((a, b) => a.nome.localeCompare(b.nome));
        })
        .catch((error) => {
          console.error('Error making POST requests for properties:', error);
        });
    },
    onPropertyChange(event) {
      const selectedProperty = event.target.value;
      this.selectedProperty = selectedProperty;

      if (selectedProperty === 'shared') {
        this.loadSharedQRCode();
      } else {
        this.loadQRCodeLinkedByProperty(selectedProperty);
      }
    },
    async loadSharedQRCode() {
      const user = this.getUser();
      try {
        const response = await this.urlGet(`/QRCode/SharedLinked/${user.id}`);
        const qrCodes = response.data.itens.filter(qrCode => qrCode.premium);

        this.qrCodeOptions = qrCodes.map(qrCode => ({
          id: qrCode.id,
          nomeDispositivo: qrCode.nomeDispositivo,
          moduleId: qrCode.moduleId,
          premium: qrCode.premium
        }));

        this.$refs.qrCodeSelect.selectedIndex = 0;
        this.selectedQRCode = null;
        this.selectedSensor = null;
        this.sensorOptions = [];
      } catch (error) {
        console.error('Error loading shared QR codes:', error);
      }
    },
    async loadQRCodeLinkedByProperty(propertyId) {
      try {
        const response = await this.urlGet(`/QRCode/Linked/${propertyId}`);
        const qrCodes = response.data;

        this.qrCodeOptions = qrCodes.map(qrCode => ({
          id: qrCode.id,
          nomeDispositivo: qrCode.nomeDispositivo,
          moduleId: qrCode.moduleId,
          premium: qrCode.premium
        }));

        this.$refs.qrCodeSelect.selectedIndex = 0;
        this.selectedQRCode = null;
        this.selectedSensor = null;
        this.sensorOptions = [];
      } catch (error) {
        console.error('Error loading QR codes by property:', error);
      }
    },
    async loadSensorsByQRCode(qrCodeId) {
      try {
        const response = await this.urlPost(`/Station/GetSensorByQrCodeId/${qrCodeId}`);
        this.sensorOptions = response.data.itens.map(sensor => ({
          id: sensor.linkname,
          name: sensor.nome
        }));
        this.selectedSensor = null;
      } catch (error) {
        console.error('Error loading sensors by QR code:', error);
      }
    },
    async onQRCodeChange(event) {
      const selectedQRCodeId = parseInt(event.target.value);
      const selectedQRCode = this.qrCodeOptions.find((qrCode) => qrCode.id === selectedQRCodeId);
      this.selectedQRCode = selectedQRCode;
      if (selectedQRCode) {
        await this.loadSensorsByQRCode(selectedQRCode.id);
      }
    },
    onSensorChange(event) {
      this.selectedSensor = event.target.value;
    },
    onPeriodChange(event) {
      this.selectedPeriod = event.target.value;
      if (this.selectedPeriod === 'daily') {
        this.isMonthYearValid = true;
      } else {
        this.isDateValid = true;
      }
    },
    onDateReportChange(event) {
      this.reportDate = event.target.value;
    },
    handleDateClosed() {
      // Forçar perda de foco no input do datepicker
      if (this.selectedPeriod === 'daily') {
        this.$refs.datepickerDaily.$el.querySelector('input').blur();
      } else if (this.selectedPeriod === 'monthly') {
        this.$refs.datepickerMonthly.$el.querySelector('input').blur();
      }
    },
    getInvalidFieldsMessage() {
      const messages = [];
      if (!this.isPropertyValid) messages.push('Propriedade');
      if (!this.isQRCodeValid) messages.push('Módulo');
      if (!this.isSensorValid) messages.push('Sensor');
      if (!this.isDateValid) messages.push('Período (data ou mês)');
      if (!this.isFormatValid) messages.push('Formato');

      return messages.join(', ');
    },
    async generateReport() {
      this.isPropertyValid = !!this.selectedProperty;
      this.isQRCodeValid = !!this.selectedQRCode;
      this.isSensorValid = !!this.selectedSensor;
      this.isDateValid = !!this.reportDate || !!this.reportMonthYear;
      this.isFormatValid = !!this.selectedFormat;
      
      if (!this.isPropertyValid || !this.isQRCodeValid || !this.isSensorValid || !this.isDateValid || !this.isFormatValid) {
        this.$refs.alert.show(
          'Atenção',
          'Por favor, preencha todos os campos obrigatórios: ' + this.getInvalidFieldsMessage(),
          null,
          3000,
          'bg-warning'
        );
        return;
      }

      this.loading = true;

      const user = this.getUser();
      let postData = {
        userId: user.id,
        qrCodeId: this.selectedQRCode.id,
        sensorName: this.selectedSensor,
        startDate: null,
        endDate: null,
        type: null,
        format: this.selectedFormat,
      };

      let fileName = '';
      let mimeType = '';

      if (this.selectedPeriod === 'daily') {
        postData.type = 'DAILY';
        postData.startDate = this.reportDate;
        postData.endDate = this.reportDate;
        const date = new Date(this.reportDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        fileName = `onagro-relatorio-diario-${day}-${month}-${year}.${this.selectedFormat.toLowerCase()}`;
      } else if (this.selectedPeriod === 'monthly') {
        postData.type = 'MONTHLY';
        const year = this.reportMonthYear.getFullYear();
        const month = String(this.reportMonthYear.getMonth() + 1).padStart(2, '0');
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);
        postData.startDate = firstDayOfMonth.toISOString().split('T')[0];
        postData.endDate = lastDayOfMonth.toISOString().split('T')[0];
        fileName = `onagro-relatorio-mensal-${month}-${year}.${this.selectedFormat.toLowerCase()}`;
      }

      mimeType = this.selectedFormat.toLowerCase() === 'pdf' 
        ? 'application/pdf' 
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      try {
        const response = await this.urlPost('/report/GenerateReport', postData, { responseType: 'arraybuffer' });

        // Verifique se está em um ambiente web ou móvel (WebView)
        if (window.flutter_inappwebview) {
          // Ambiente de WebView (Flutter)
          const base64Data = await arrayBufferToBase64(response.data);
          const dataToSend = JSON.stringify({
            base64Data: base64Data,
            postData: postData
          });
          window.flutter_inappwebview.callHandler('downloadBlob', dataToSend);
        } else {
          // Ambiente web
          const blob = new Blob([response.data], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }

        this.$refs.alert.show(
          'Sucesso',
          'Relatório gerado com sucesso!',
          null,
          3000,
          'bg-success'
        );
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$refs.alert.show(
            'Nenhum registro encontrado',
            'Nenhum registro encontrado para os parâmetros selecionados. Verifique os parâmetros selecionados.',
            null,
            3000,
            'bg-danger'
          );
        } else {
          console.error('Erro ao gerar relatório:', error);
          this.$refs.alert.show(
            'Erro',
            'Erro ao gerar relatório. Por favor, tente novamente mais tarde.',
            null,
            3000,
            'bg-danger'
          );
        }
      } finally {
        this.loading = false;
      }
    },
    async saveUnits() {
      const user = this.getUser();
      const postData = {
        userId: user.id,
        luminosidade: this.units.luminosidade,
        pressao: this.units.pressao,
        profundidade: this.units.profundidade,
        temperatura: this.units.temperatura,
        velocidade: this.units.velocidade,
        volume: this.units.volume,
      };

      try {
        const exists = await this.urlGet(`/UserPreferredUnits/${user.id}`);
        if (exists.data) {
          // Atualiza as unidades preferidas do usuário
          await this.urlPut(`/UserPreferredUnits/${user.id}`, postData);
        } else {
          // Cria novas unidades preferidas do usuário
          await this.urlPost('/UserPreferredUnits', postData);
        }

        this.$refs.alert.show(
          "Sucesso ",
          "Unidades de medida salvas com sucesso!",
          null,
          3000,
          "bg-success"
        );
      } catch (error) {
        console.error('Erro ao salvar unidades de medida:', error);
        this.$refs.alert.show(
          "Erro ",
          "Erro ao salvar unidades de medida. Por favor, tente novamente mais tarde.",
          null,
          3000,
          "bg-danger"
        );
      }
    },
    async loadUserUnits() {
      const user = this.getUser();
      try {
        const response = await this.urlGet(`/UserPreferredUnits/${user.id}`);
        const data = response.data;
        if (data) {
          this.units.luminosidade = data.luminosidade;
          this.units.pressao = data.pressao;
          this.units.profundidade = data.profundidade;
          this.units.temperatura = data.temperatura;
          this.units.velocidade = data.velocidade;
          this.units.volume = data.volume;
        }
      } catch (error) {
        console.error('Erro ao carregar unidades de medida do usuário:', error);
      }
    },
    async loadAvailableUnits() {
      try {
        const response = await this.urlGet('/UserPreferredUnits/available-units');
        this.availableUnits = response.data;
      } catch (error) {
        console.error('Erro ao carregar unidades disponíveis:', error);
      }
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
};
</script>
<style scoped>
.invalid-field {
  border: 1px solid red;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loading-content {
  text-align: center;
}
.loading-message {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}
.page-container {
  margin: 0 auto;
  padding: 20px;
}
.header {
  text-align: left;
  margin-bottom: 20px;
}
.card {
  border: 1px solid #ddd;
  padding: 20px;
}
.section {
  margin-bottom: 20px;
}
.subsection {
  margin-top: 20px;
}
.select-wrapper {
  margin-right: 10px;
  display: inline-block;
}

.right-button {
  float: right;
}
.click-catcher {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}
</style>
