<template>
  <div class="termos-politica" style="line-height: normal; padding: 15px">
    <button class="form-back-button d-inline md-d-none">
      <i
        class="menu-icon tf-icons bx bx-left-arrow-alt m-0"
        onclick="window.location = '/'"
      ></i>
    </button>
    <div class="row">
      <div style="flex: 1">
        <h2 style="text-align: center">POLÍTICA DE PRIVACIDADE</h2>
      </div>
    </div>

    <h4>Coleta de dados gerais</h4>

    <p>
      A prioridade do onAgro é assegurar a privacidade e a segurança das
      informações cedidas pelos usuários cadastrados (“Usuário”). Neste sentido,
      o onAgro destinará seus melhores esforços para garantir a proteção da
      privacidade dos Usuários na plataforma onAgro, pois tem como política a
      não participação na prática de venda ou intercâmbio de dados pessoais com
      terceiros para fins promocionais.
    </p>

    <p>
      No entanto, o onAgro, de maneira específica monitora algumas informações
      de identificação pessoal necessárias para atingir os fins descritos na
      presente Política de Privacidade. Ao utilizar o onAgro, por meio do seu
      website ou aplicativo para celular, o usuário reconhece e aceita este
      recolhimento, conforme descrito neste Instrumento.
    </p>

    <p>
      O onAgro se reserva o direito de modificar esta Política de Privacidade a
      qualquer momento. Todas as modificações serão avisadas através dos nossos
      veículos oficiais de comunicação com o Usuário que, ao permanecer no
      sistema, demonstra que concordam com as modificações realizadas.
    </p>

    <h4>Cadastro do Usuário</h4>

    <p>
      O objetivo do onAgro em permitir que o Usuário voluntariamente coloque
      suas informações é facilitar a navegação do onAgro e o uso de suas
      ferramentas. A proatividade do Usuário em ceder seus dados significa que
      ele quer ser beneficiado com as ferramentas do onAgro e concorda com os
      termos de privacidade.
    </p>

    <p>
      Para se tornar um Usuário, o primeiro passo é o seu cadastro pessoal, onde
      nome, e-mail e senha são dados obrigatórios.
    </p>

    <p>
      As informações adicionais podem ser colocadas tanto durante o cadastro,
      quanto num momento posterior de preferência do Usuário para alterar seu
      perfil pessoal e de sua empresa. Dentre as possibilidades de informações
      adicionais, vale reforçar que o e-mail do Usuário nunca será exibido para
      os demais Usuários.
    </p>

    <p>
      Portanto, recomendamos que o Usuário possua cuidado ao enviar informações
      para o onAgro; particularmente, ao preencher campos de texto livres ou ao
      fazer upload de conteúdos, documentos e outros materiais. Os serviços do
      onAgro são automatizados, não sendo possível reconhecer quando o Usuário
      nos forneceu acidentalmente informações incorretas.
    </p>

    <p>
      O Usuário tem direito de acessar, modificar, corrigir e eliminar os dados
      sobre ele referentes à sua decisão de ter se tornado um Usuário. Se o
      Usuário atualizar qualquer informação, o onAgro poderá manter uma cópia
      das informações anteriores fornecidas por ele em nossos arquivos e
      documentações sobre uso do sistema, por tempo razoável, para fins de
      segurança e sujeito aos termos da lei.
    </p>

    <h4>Informações pessoais coletadas pela plataforma onAgro</h4>

    <p>
      O onAgro utiliza-se da tecnologia de “cookies” (dados no computador ou
      celular do Usuário) para permitir sua correta identificação, além de
      melhorar a qualidade das informações oferecidas em seu portal para os
      Usuários. O uso regular de cookies é uma prática aceitável de mercado,
      pois permite o armazenamento de informações importantes, como, por
      exemplo, os acessos do Usuário no onAgro, para posteriormente não ser
      apresentado um conteúdo repetido ou ultrapassado. Se não for a vontade do
      Usuário receber cookies, ou o mesmo desejar uma notificação prévia antes
      de seu uso, ele deverá programar, a seu critério, seu web browser
      (navegador da Web) com estas opções, caso seu browser disponha do recurso.
    </p>

    <p>
      Além das informações pessoais fornecidas pelo Usuário, o onAgro tem a
      capacidade tecnológica de recolher outras informações técnicas, como o
      endereço de protocolo de Internet do Usuário, o sistema operacional do
      computador ou do celular, o tipo de browser, o endereço de websites de
      referência e a localização física do dispositivo de acesso se o Usuário
      tiver consentido em compartilhar seu local com os serviços de
      geolocalização. A coleta destas informações tem como objetivo possibilitar
      ao onAgro aprimorar as ferramentas e serviços disponíveis aos Usuários,
      bem como auxiliar o próprio Usuário melhorar a própria experiência de uso
      do onAgro.
    </p>

    <p>
      O onAgro não fornecerá as informações do Usuário a terceiros sem prévia
      autorização do mesmo, com exceção de casos nos quais seja necessário o
      fornecimento para fins de resposta a ordens, solicitações ou perguntas de
      entidades governamentais ou judiciais ou nos casos em que tal fornecimento
      seja necessário para a proteção de direitos ou da segurança do onAgro, de
      seus Usuários ou do público em geral.
    </p>

    <p>
      O Usuário expressamente autoriza o onAgro a divulgar estas informações
      pessoais para atender aos fins acima indicados.
    </p>

    <p>
      O onAgro poderá, a seu critério, fazer uso das informações armazenadas nos
      seus bancos de dados, conforme descrito acima, por um prazo razoável, sem
      que exceda os requisitos ou limitações legais, para dirimir quaisquer
      disputas, solucionar problemas e garantir os direitos do onAgro, assim
      como os termos e condições da presente Política de Privacidade.
    </p>

    <p>
      O onAgro deverá também, a seu critério, examinar as informações
      armazenadas nos seus bancos de dados com o propósito de identificar
      Usuários com múltiplas identidades ou pseudônimos para fins legais ou de
      segurança.
    </p>

    <h4>Permissão do Usuário para Coleta e Uso de Dados pelo onAgro</h4>

    <p>
      As informações cedidas pelo Usuário e registradas devido ao uso do sistema
      (com exceção ao conteúdo de mensagens pessoais) servirão como insumos para
      o mapeamento de informações de mercado e formação de estatísticas do
      onAgro. Através do cadastramento, uso e fornecimento de informações ao
      onAgro, o Usuário expressamente aceita as condições desta Política de
      Privacidade sobre a coleta, armazenamento, processamento e uso de suas
      informações.
    </p>

    <p>
      As informações cedidas pelo Usuário que o torna pessoalmente identificável
      tem como objetivo fazer com os Usuários do onAgro se relacionem melhor com
      as ferramentas disponíveis. Informações adicionais coletadas pelo onAgro
      através da análise da navegação de cada Usuário e que não o torne
      identificável pessoalmente (como o padrão de navegação, por exemplo) são
      de propriedade exclusiva do onAgro, que pode usar essas informações do
      modo que melhor julgar apropriada, sujeito aos limites e condições desta
      Política de Privacidade.
    </p>

    <p>
      Além disso, as informações fornecidas são usadas para: (i) administrar a
      conta dos Usuários a fim de customizar cada vez mais os serviços; e (ii)
      comunicar novidades e atualizações. Porém, o onAgro pode retransmitir
      mensagens de parceiros, através da tecnologia de opt-in (aceitar)
      escolhida pelo Usuário.
    </p>

    <p>
      O Usuário tem o direito de cancelar o seu cadastro no onAgro e solicitar a
      remoção definitiva dos seus dados. Não obstante, o onAgro poderá manter
      determinadas informações de forma a atender o cumprimento das leis e dos
      regulamentos aplicáveis em matéria de conservação obrigatória de certos
      tipos de informações relativas aos Usuários e/ou quaisquer transações
      comerciais com eles, bem como para atender a ordens ou solicitações de
      autoridades governamentais ou judiciais.
    </p>

    <p>
      Neste sentido, para fechar a sua conta no onAgro, basta acessar a
      plataforma onAgro e selecionar apagar meus dados, mediante esse comando
      sua conta será apagada e juntamente com seus dados.
    </p>

    <h4>As Obrigações do Usuário</h4>

    <p>
      É dever do Usuário obedecer aos atuais termos e condições desta Política
      de Privacidade, o que inclui respeitar propriedade intelectual de
      terceiros e do onAgro.
    </p>

    <p>
      O Usuário não deve executar download, upload ou qualquer forma de
      disseminação de material ou informação de caráter violento, ofensivo,
      racista ou xenofóbico ou qualquer um que viole o espírito e objetivos do
      onAgro e sua comunidade de usuários.
    </p>

    <p>
      O Usuário não deve prover ao onAgro e/ou a qualquer outro Usuário
      informações que ele acredite serem danosas à sua pessoa, profissional ou
      socialmente.
    </p>

    <h4>Usuários Menores de Idade</h4>

    <p>
      Informações de menores de idade não poderão ser submetidas ao onAgro por
      pessoas com idade inferior a 18 anos, sem o prévio consentimento dos pais
      ou responsável. É terminantemente proibido que menores de idade naveguem e
      façam uso das diversas ferramentas do onAgro, sem o referido
      consentimento. Com a aceitação destes termos, ficará tacitamente entendido
      que este consentimento foi dado pelos pais ou responsável, ficando estes
      exclusivamente responsáveis pelo conteúdo acessado pelos menores de idade.
    </p>

    <h4>Canal de Comunicação com o Usuário</h4>

    <p>
      Se o Usuário tiver qualquer dúvida ou sugestão sobre a plataforma onAgro,
      ele poderá encaminhar um email para atendimento@onagro.com.br.
    </p>
  </div>
</template>