<template>
  <div :class="'aside-map' + (show ? ' show' : '')">
    <!-- <div
      class="text-center bg-primary py-3"
      style="border-top: 1px solid #fff; position: relative"
    >
      <h4 class="text-white m-0">
        {{ title }}
      </h4>
      <button
        type="button"
        class="btn-close"
        style="position: absolute"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div> -->
    <div class="modal-content aside-modal">
      <div
        class="modal-header py-3 aside-header"
        style="background-color: #ddd"
      >
        <div style="flex-direction: column">
          <div v-if="title.length > 1">
            <h5 class="modal-title" style="color: #006035; font-weight: 700">
              {{ title[0] }}
            </h5>
            <h6 class="modal-title" style="color: #006035; font-weight: 700">
              {{ title[1] }}
            </h6>
          </div>
        </div>
        <button
          type="button"
          class="btn px-1"
          style="color: #006035"
          @click="
            show = false;
            $emit('close');
          "
        >
          <font-awesome-icon
            class="icone-card-clima"
            icon="fa fa-x"
            style="font-weight: 800"
          />
        </button>
      </div>
      <div class="modal-body aside-body">
        <slot name="body"></slot>
      </div>
      <div v-html="css()"></div>
      <div class="modal-footer py-1" style="background-color: #ddd">
        <button class="btn btn-primary w-100" v-if="footerButton">
          <font-awesome-icon
            style="margin-right: 10px"
            :icon="footerButton.icon"
          />{{ footerButton.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "-",
      show: false,
      footerButton: null,
    };
  },
  watch: {
    show(s) {
      this.$emit("show", s);
    },
  },
  methods: {
    fadeIn(title) {
      this.title = title;
      if (this.show) {
        this.show = false;
        setTimeout(() => {
          this.show = true;
        }, 10);
      } else {
        this.show = true;
      }
    },
    css() {
      var z1 = !this.show ? 1100 : 900;
      var z2 = this.show ? 1000 : 900;
      var c = `<style>
      .leaflet-top.leaflet-right {
        z-index: 2000;
      }
      .map-body {
        z-index: 1100;
      }

      @media (max-width: 700px) {
      .leaflet-top.leaflet-right {
                z-index: ${z1} !important;
              }
              .map-body {
                z-index: ${z2};
                }
                }
                </style>
                `;
      return c;
    },
  },
  mounted() {},
};
</script>

<style>
.aside-modal {
  max-height: calc(100% - 20px);
}
.aside-map {
  height: 100%;
  width: 350px;
  position: absolute;
  z-index: 700;
  right: 70px;
  top: 10px;
  opacity: 0;
  display: none;
  max-height: calc(100% - 10px);
  overflow: hidden;
}
.aside-body {
  background-color: #ddd;
  padding: 0.2rem;
  max-height: calc(100% - 60px);
  overflow: auto;
}
.aside-map.show {
  display: block;
  animation: asite-show 0.3s ease-in-out forwards;
}
@keyframes asite-show {
  from {
    transform: translateX(+100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@media (max-width: 1200px) {
  /* .aside-modal {
    height: calc(100% - 20px);
  } */
  .aside-body {
    max-height: calc(100% - 60px);
  }
  .aside-map {
    max-height: calc(100% - 10px);
  }
}
@media (max-width: 700px) {
  .aside-header {
    padding-top: 60px !important;
  }
  .aside-modal {
    max-height: 100%;
  }

  .aside-body {
    max-height: calc(100% - 60px);
  }
  .aside-map {
    max-height: 100%;
  }
  .aside-map {
    top: 0px;
    right: 0px;
    width: 100vw;
    z-index: 5000;
  }
}
</style>
