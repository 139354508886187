<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="true"
          @delete="$refs.datatable.update()"
          @create="$refs.datatable.update()"
          @onformcreate="onformcreate"
          @forceRerender="$refs.datatable.update()"
          name="Módulo"
          names="Módulos"
          button="+"
          btnfilter="false"
          url="/Module"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form" v-if="component">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/Module/Read'"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:sensor="data">
                <div>
                  <div class="text-center" style="display: flex">
                    <div
                      v-for="(sensorData, index) in data.row.sensor"
                      :key="index"
                      style="margin-right: 10px"
                    >
                      <img
                        :src="sensorData.icon"
                        :alt="sensorData.icon"
                        width="50"
                        height="60"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Nome do módulo *</label>
                <input
                  style="width: 515px"
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.name"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.name"
                ></Error>
              </div>
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label">Descritivo *</label>
                <textarea
                  style="resize: none"
                  cols="58"
                  rows="6"
                  v-model="form.crud.form.description"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.description"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Escolha o Sensor</label>
                <VueMultiselect
                  style="z-index: 1000 !important; width: 515px"
                  v-model="form.crud.form.sensor"
                  :options="taggingOptions"
                  :multiple="true"
                  :taggable="true"
                  :show-labels="false"
                  placeholder="Selecione"
                  label="name"
                  track-by="id"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.sensor"
                ></Error>
              </div>
              <hr />
            </div>
          </template>
        </Crud>
      </template>
    </Layout>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
import VueMultiselect from "vue-multiselect";
export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    Alert,
    VueMultiselect,
  },
  data() {
    return {
      component: true,
      fields: [
        { name: "Nome", value: "name" },
        { name: "Quantidade de sensor", value: "sensor", type: "slot" },
        {
          name: "Data de criação",
          value: "created",
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          edit: true,
          delete: false,
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],
      selected: null,
      taggingOptions: [],
    };
  },
  methods: {
    forceRerender() {
      this.component = false;
      this.$nextTick(() => {
        this.component = true;
      });
    },
    getSensor: function () {
      this.urlGet("/Module/Sensor").then((response) => {
        this.taggingOptions = response.data.sensors;
      });
    },
  },
  mounted() {
    this.getSensor();
  },
};
</script>
