<template>
  <div class="row">
    <label class="form-label"
      >Sobre a Propriedade<span class="text-danger">*</span>
    </label>

    <div style="display: flex; align-items: center">
      <label style="margin-right: 10px">
        <input
          type="radio"
          name="propriedade"
          value="Urbano"
          :checked="form.crud.form.propriedade === 'Urbano'"
          v-model="form.crud.form.propriedade"
          @change="clearProperty(form)"
        />
        Urbano
      </label>
      <label>
        <input
          type="radio"
          name="propriedade"
          style="margin-left: 10px"
          value="Rural"
          :checked="form.crud.form.propriedade === 'Rural'"
          v-model="form.crud.form.propriedade"
          @change="clearProperty(form)"
        />
        Rural
      </label>
    </div>

    <div class="mb-3 col-md-6 col-12">
      <label class="form-label"
        >Nome da Propriedade<span class="text-danger">*</span></label
      >
      <input type="text" class="form-control" v-model="form.crud.form.nome" />
      <Error v-if="form.crud.error" :error="form.crud.error.nome"></Error>
    </div>
    <div class="mb-3 col-md-6 col-12">
      <label class="form-label" v-if="form.crud.form.propriedade === 'Rural'"
        >Cultura <span class="text-danger">*</span></label
      >
      <VueMultiselect
        v-if="form.crud.form.propriedade === 'Rural'"
        style="z-index: 1000 !important"
        v-model="form.crud.form.culturaPropriedades"
        :options="taggingOptions"
        :multiple="true"
        :taggable="true"
        :show-labels="false"
        placeholder="Selecione"
        label="name"
        track-by="code"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.ozonio"></Error>
    </div>
    <div class="mb-3 col-md-6 col-12">
      <label class="form-label"
        >Tipo de identificação <span class="text-danger">*</span></label
      >
      <SelectData
        v-if="form.crud.form.propriedade === 'Rural'"
        url="/PropriedadeAgricola/TypeIdentificationRural"
        field="nome"
        label="nome"
        empty="Selecione"
        :value="
          form.crud.form.tipoIdentificacao == undefined
            ? null
            : form.crud.form.tipoIdentificacao
        "
        @change="clearTypeOrganization(form)"
        @value="form.crud.form.tipoIdentificacao = $event"
      >
      </SelectData>

      <SelectData
        v-else
        url="/PropriedadeAgricola/TypeIdentificationUrban"
        field="nome"
        label="nome"
        empty="Selecione"
        :value="
          form.crud.form.tipoIdentificacao == undefined
            ? null
            : form.crud.form.tipoIdentificacao
        "
        @change="clearTypeOrganization(form)"
        @value="form.crud.form.tipoIdentificacao = $event"
      >
      </SelectData>
      <Error
        v-if="form.crud.error"
        :error="form.crud.error.tipoIdentificacao"
      ></Error>
    </div>

    <div class="mb-3 col-md-6 col-12">
      <label class="form-label"
        >Identificação <span class="text-danger">*</span></label
      >

      <input
        v-if="
          form.crud.form.tipoIdentificacao !== 'CPF' ||
          form.crud.form.tipoIdentificacao !== 'CNPJ'
        "
        type="text"
        class="form-control"
        onfocus="this.value=''"
        v-model="form.crud.form.identificacao"
      />

      <input
        v-if="form.crud.form.tipoIdentificacao == 'CNPJ'"
        type="text"
        class="form-control"
        onfocus="this.value=''"
        style="display: none"
        v-mask="'##.###.###/####-##'"
        v-model="form.crud.form.identificacao"
      />

      <input
        v-if="form.crud.form.tipoIdentificacao == 'CPF'"
        type="text"
        class="form-control"
        onfocus="this.value=''"
        style="display: none"
        v-mask="'###.###.###-##'"
        v-model="form.crud.form.identificacao"
      />

      <Error
        v-if="form.crud.error"
        :error="form.crud.error.identificacao"
      ></Error>
    </div>
    <div class="mb-3 col-md-6 col-12" v-if="form.crud.form.propriedade != null">
      <label class="form-label"
        >Tipo de organização
        <span class="text-danger">*</span>
      </label>
      <VueMultiselect
        style="z-index: 100 !important"
        v-model="form.crud.form.tipoOrganizacao"
        v-if="
          form.crud.form.propriedade == 'Urbano' &&
          form.crud.form.tipoIdentificacao != 'CNPJ' &&
          form.crud.form.tipoIdentificacao != 'CPF'
        "
        :options="optionsUrbana"
        :show-labels="false"
        placeholder="Selecione"
      />

      <VueMultiselect
        style="z-index: 100 !important"
        v-model="form.crud.form.tipoOrganizacao"
        v-if="
          form.crud.form.propriedade == 'Rural' &&
          form.crud.form.tipoIdentificacao != 'CNPJ' &&
          form.crud.form.tipoIdentificacao != 'CPF'
        "
        :options="optionsRural"
        :show-labels="false"
        placeholder="Selecione"
      />
      <VueMultiselect
        style="z-index: 100 !important"
        v-model="form.crud.form.tipoOrganizacao"
        v-if="
          form.crud.form.tipoIdentificacao == 'CNPJ' &&
          form.crud.form.propriedade == 'Urbano'
        "
        :options="optionsUrbanaCNPJ"
        :show-labels="false"
        placeholder="Selecione"
      />
      <VueMultiselect
        style="z-index: 100 !important"
        v-model="form.crud.form.tipoOrganizacao"
        v-if="
          form.crud.form.tipoIdentificacao == 'CNPJ' &&
          form.crud.form.propriedade == 'Rural'
        "
        :options="optionsRural"
        :show-labels="false"
        placeholder="Selecione"
      />
      <VueMultiselect
        style="z-index: 100 !important"
        v-model="form.crud.form.tipoOrganizacao"
        v-if="
          form.crud.form.tipoIdentificacao == 'CPF' &&
          form.crud.form.propriedade == 'Urbano'
        "
        :options="optionsUrbanaCPF"
        :show-labels="false"
        placeholder="Selecione"
      />
      <VueMultiselect
        style="z-index: 100 !important"
        v-model="form.crud.form.tipoOrganizacao"
        v-if="
          form.crud.form.tipoIdentificacao == 'CPF' &&
          form.crud.form.propriedade == 'Rural'
        "
        :options="optionsRural"
        :show-labels="false"
        placeholder="Selecione"
      />
      <Error
        v-if="form.crud.error"
        :error="form.crud.error.tipoOrganizacao"
      ></Error>
    </div>
    <div
      v-show="form.crud.form.tipoOrganizacao === 'Outros'"
      class="mb-3 col-md-6 col-12"
    >
      <label class="form-label">Outros<span class="text-danger">*</span></label>
      <input type="text" class="form-control" v-model="form.crud.form.outros" />
      <Error v-if="form.crud.error" :error="form.crud.error.nome"></Error>
    </div>
    <label class="form-label"
      >Endereço Completo<span class="text-danger">*</span></label
    >
    <div class="mb-3 col-md-6 col-12">
      <label class="form-label">CEP <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        v-mask="'#####-###'"
        v-model="form.crud.form.cep"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.cep"></Error>
    </div>
    <div class="mb-3 col-md-6 col-12">
      <label class="form-label">UF <span class="text-danger">*</span></label>
      <input type="text" class="form-control" v-model="form.crud.form.uf" />
      <Error v-if="form.crud.error" :error="form.crud.error.uf"></Error>
    </div>
    <div class="mb-3 col-md-6 col-12">
      <label class="form-label"
        >Municipio <span class="text-danger">*</span></label
      >
      <input
        type="text"
        class="form-control"
        v-model="form.crud.form.municipio"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.municipio"></Error>
    </div>
    <div class="mb-3 col-md-6 col-12">
      <label class="form-label"
        >Endereço <span class="text-danger">*</span></label
      >
      <input
        type="text"
        class="form-control"
        v-model="form.crud.form.logradouro"
      />
      <Error v-if="form.crud.error" :error="form.crud.error.logradouro"></Error>
    </div>
    <div class="mb-3 col-md-6 col-12">
      <label class="form-label"
        >Bairro <span class="text-danger">*</span></label
      >
      <input type="text" class="form-control" v-model="form.crud.form.bairro" />
      <Error v-if="form.crud.error" :error="form.crud.error.bairro"></Error>
    </div>
    <div class="mb-3 col-md-12 col-12">
      <label class="form-label">Local <span class="text-danger">*</span></label>
      <Local
        @value="form.crud.form.localId = $event"
        :value="form.crud.form.localId"
      ></Local>
      <Error v-if="form.crud.error" :error="form.crud.error.localId"></Error>
    </div>

    <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.created">
      <label class="form-label">Criado</label>
      <input
        type="datetime-local"
        class="form-control"
        readonly
        :value="form.crud.form.created"
      />
    </div>
    <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.updated">
      <label class="form-label">Atualizado</label>
      <input
        type="datetime-local"
        class="form-control"
        readonly
        :value="form.crud.form.updated"
      />
    </div>
  </div>
</template>
<script>
import Error from "../../components/Error.vue";
import SelectData from "../../components/crud/input/SelectData.vue";
import Local from "../../components/Local.vue";
import VueMultiselect from "vue-multiselect";
import { mask } from "vue-the-mask";

export default {
  props: ["form"],
  directives: { mask },
  components: {
    Error,
    SelectData,
    Local,
    VueMultiselect,
  },

  data() {
    return {
      taggingSelected: null,
      taggingOptions: [
        { name: "Leite", code: "LEITE" },
        { name: "Suíno Cultura", code: "SUINOCULTURA" },
        { name: "Viticultura (Uva)", code: "VITICULTURA" },
        { name: "Trigo", code: "TRIGO" },
        { name: "Soja", code: "SOJA" },
        { name: "Fumicultura", code: "FUMICULTURA" },
        { name: "Bovino Cultura", code: "BOVINOCULTURA" },
        { name: "Equino Cultura", code: "EQUINOCULTURA" },
        { name: "Citrus", code: "CITRUS" },
        { name: "Vegetais", code: "VEGETAIS" },
        { name: "Milho", code: "MILHO" },
        { name: "Outro", code: "OUTRO" },
      ],
      selected: null,
      optionsRural: [
        "Produtor Rural",
        "Cooperativa",
        "Agroindustria",
        "Grupo Agrícola",
        "Industria de alimentos e bebidas",
        "Instituição de pesquisa",
        "Consultor",
        "Revenda",
        "Outros",
      ],
      optionsUrbana: ["Físico", "Jurídico", "Público"],
      optionsUrbanaCNPJ: ["Jurídico", "Público"],
      optionsUrbanaCPF: ["Físico"],
    };
  },
  methods: {
    clearProperty(form) {
      form.crud.form.culturaPropriedades = [];
      form.crud.form.tipoOrganizacao = null;
    },
    clearTypeOrganization(form) {
      form.crud.form.tipoOrganizacao = null;
    },
  },
};
</script>
<style>
/*input[type="radio"] {
  appearance: none;
  border: 1px solid #d3d3d3;
  width: 15px;
  height: 15px;
  content: none;
  outline: none;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

input[type="radio"]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}

input[type="radio"]:checked::before {
  position: relative;
  color: green !important;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
  font-size: 12px;
  bottom: 20%;
  transform: translate(-50%, -50%);
}*/
</style>
