<template>
  <div class="row">
    <div class="col-12 order-3 order-md-2" v-for="(c, ci) in config" :key="ci">
      <div v-bind:class="isTab ? 'p-3' : 'card p-3'">
        <h3 class="text-center mb-1">{{ c.title }}</h3>
        <div v-for="(d, di) in c.data" :key="di">
          <hr />
          <h4 class="card-header m-0 pb-3 px-2">{{ d.title }}</h4>
          <span class="card-header m-0 pb-3 px-2">{{ d.caption }}</span>
          <div v-if="d.CSharpCode">
            <div>
              <h4 class="card-header m-0 pb-3 px-2">
                Observação sobre a utilização dos dados da API para dispositivos
                onAgro:
              </h4>
              <div>
                <div class="m-0 pb-3 px-2">
                  O valor rainfall, é referente ao valor acumulado de chuva no
                  dispositivo, ou seja, um valor incremental desde quando o
                  dispositivo foi ligado pela primeira vez, para saber quanto
                  choveu em um período, torna-se necessária a realização de um
                  cálculo. O valor de rainfall é zerado em caso de troca de
                  bateria do sistema, ou em caso de estouro de memória do
                  contador.
                </div>
              </div>
            </div>
            <div class="pb-3 px-2 mb-2 mt-2">
              Exemplo de código para calculo de chuva:
            </div>
            <div class="px-2">
              <pre class="code">
                  <code class="language-csharp">
                      * Vamos utilizar os valores da leitura inicial (12:05) de 50mm e
                      da leitura final (12:55) de 75mm para calcular quanto choveu durante
                      esse intervalo de tempo. Como o segundo valor é maior que o primeiro,
                      é feita a subtração dos valores e concluímos que choveu 25mm. *

                      class Program
                      {
                          static void Main()
                          {
                              // Exemplo de uso da função para calcular a quantidade de chuva no intervalo
                              double leituraInicial = 50.0; // Substitua pelo valor real da leitura inicial
                              double leituraFinal = 75.0;   // Substitua pelo valor real da leitura final

                              // Chama a função para calcular a quantidade de chuva no intervalo
                              double chuvaNoIntervalo = CalcularChuvaNoIntervalo(leituraInicial, leituraFinal);

                              // Exibe o resultado
                              Console.WriteLine($"A quantidade de chuva no intervalo é: {chuvaNoIntervalo} mm");
                          }

                          // Função para calcular a quantidade de chuva no intervalo
                          static double CalcularChuvaNoIntervalo(double leituraInicial, double leituraFinal)
                          {
                              // Verifica se a leitura final é menor que a inicial
                              if (leituraFinal &lt; leituraInicial)
                              {
                                  // Se for, a quantidade de chuva é a segunda leitura
                                  return leituraFinal;
                              }
                              else
                              {
                                  // Se não for, a quantidade de chuva é a diferença entre a segunda e a primeira leitura
                                  return leituraFinal - leituraInicial;
                              }
                          }
                      } 
                  </code>
              </pre>
            </div>
          </div>
          <div class="mt-3" v-if="d.curlParam">
            <span class="card-header m-0 pb-3 px-2" style="font-weight: bold"
              >Parâmetros:</span
            >
            <div
              v-for="(curlParam, curlParamKey) in d.curlParam"
              :key="curlParamKey"
            >
              <span class="card-header m-0 pb-3 px-2">* {{ curlParam }}</span>
            </div>
          </div>
          <div class="mt-3 mb-3" v-if="d.curlReturn1">
            <span class="card-header m-0 pb-3 px-2" style="font-weight: bold"
              >Retornos:</span
            >

            <div v-if="d.curlReturn1">
              <div class="mt-3" v-if="d.curlReturnTitle1">
                <span
                  class="card-header m-0 pb-3 px-2"
                  style="font-weight: bold"
                  >{{ d.curlReturnTitle1 }}</span
                >
              </div>

              <div
                v-for="(curlReturn1, curlReturn1Key) in d.curlReturn1"
                :key="curlReturn1Key"
              >
                <span class="card-header m-0 pb-3 px-2"
                  >* {{ curlReturn1 }}</span
                >
              </div>

              <div v-if="d.curlReturnSubitem1">
                <div
                  v-for="(
                    curlReturnSubitem1, curlReturnSubitem1Key
                  ) in d.curlReturnSubitem1"
                  :key="curlReturnSubitem1Key"
                >
                  <span class="card-header m-0 pb-3 px-5"
                    >* {{ curlReturnSubitem1 }}</span
                  >
                </div>
              </div>
            </div>

            <div class="mb-3" v-if="d.curlReturn2">
              <div v-if="d.curlReturnTitle2">
                <span
                  class="card-header m-0 pb-3 px-2"
                  style="font-weight: bold"
                  >{{ d.curlReturnTitle2 }}</span
                >
              </div>

              <div
                v-for="(curlReturn2, curlReturn2Key) in d.curlReturn2"
                :key="curlReturn2Key"
              >
                <span class="card-header m-0 pb-3 px-2"
                  >* {{ curlReturn2 }}</span
                >
              </div>

              <div v-if="d.curlReturnSubitem2">
                <div
                  v-for="(
                    curlReturnSubitem2, curlReturnSubitem2Key
                  ) in d.curlReturnSubitem2"
                  :key="curlReturnSubitem2Key"
                >
                  <span class="card-header m-0 pb-3 pl-3 px-5"
                    >* {{ curlReturnSubitem2 }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="d.recomendation">
            <span class="card-header m-0 pb-3 px-2" style="font-weight: bold"
              >Recomendação:</span
            >
            <div class="m-0 pb-3 px-2">{{ d.recomendation }}</div>
          </div>
          <div v-if="d.method" class="block-code">
            <div class="code">
              <div class="d-flex">
                <span :class="'method ' + d.method">{{
                  d.method.toUpperCase()
                }}</span>
                <span>{{ d.origin ? getOrigin() + d.url : d.url }}</span>
                <i
                  class="tf-icons bx bx-copy text-white copy-url"
                  style="cursor: pointer"
                  @click="copyTextToClipboard(getOrigin() + d.url)"
                ></i>
              </div>
            </div>
            <div v-if="d.example">
              <h4 class="payload-title">Exemplos</h4>
              <div class="code">
                <div
                  v-for="(p, pi) in d.example"
                  :key="pi"
                  class="d-flex example-code-block"
                >
                  <span>
                    {{ getOrigin() + p.url }}
                    <span class="type-code">{{ p.type }}</span>
                    <span class="example-code" v-if="p.description"
                      >// {{ p.description }}</span
                    >
                  </span>
                  <i
                    class="tf-icons bx bx-copy text-white copy-url"
                    style="cursor: pointer"
                    @click="copyTextToClipboard(p.value)"
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="d.param">
              <h4 class="payload-title">Parâmetros</h4>
              <div class="code">
                <div v-for="(p, pi) in d.param" :key="pi">
                  <span>
                    {{ pi }}: <span class="type-code">{{ p.type }}</span>
                    <span class="required-code" v-if="p.required">*</span>
                    <span class="example-code" v-if="p.description"
                      >// {{ p.description }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
            <div v-if="d.header">
              <h4 class="payload-title">Header</h4>
              <div class="code">
                <div v-for="(p, pi) in d.header" :key="pi">
                  <span>
                    {{ pi }}:
                    <span v-if="p.value" class="value-code">{{ p.value }}</span>
                    <span class="type-code">{{ p.type }}</span>
                    <span class="required-code" v-if="p.required">*</span>
                    <span class="example-code" v-if="p.description"
                      >// {{ p.description }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
            <div v-if="d.payload">
              <h4 class="payload-title">Payload</h4>
              <div class="code">
                <div v-for="(p, pi) in d.payload.values" :key="pi">
                  <span>
                    {{ pi }}: <span class="type-code">{{ p.type }}</span>
                    <span class="required-code" v-if="p.required">*</span>
                    <span class="example-code" v-if="p.description"
                      >// {{ p.description }}</span
                    >
                  </span>
                </div>
                <div v-if="d.payload.example">
                  <div v-for="(ex, exi) in d.payload.example" :key="exi">
                    <hr />
                    <h5 v-if="ex.title" class="text-info mb-0">
                      {{ ex.title }}
                    </h5>

                    <div class="code-json" v-if="ex.value">
                      <div class="copy-icon">
                        <i
                          class="tf-icons bx bx-copy text-white copy-url"
                          style="cursor: pointer"
                          @click="
                            copyTextToClipboard(
                              JSON.stringify(ex.value, null, 2)
                            )
                          "
                        ></i>
                      </div>
                      {{ JSON.stringify(ex.value, null, 2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="d.response">
              <h4 class="payload-title">Resposta</h4>
              <ul class="nav nav-tabs">
                <li class="nav-item" v-for="(t, ti) in d.response" :key="ti">
                  <button
                    type="button"
                    :class="'nav-link code ' + getActiveTab(ti, d.response, di)"
                    @click="active[di] = ti"
                  >
                    {{ ti }}
                  </button>
                </li>
              </ul>
              <div
                :class="'code tab ' + getActiveTab(ti, d.response, di)"
                v-for="(t, ti) in d.response"
                :key="ti"
              >
                <div>
                  <span class="example-code">// {{ t.description }}</span>
                </div>

                <div v-if="t.example" class="code-json">
                  <div class="copy-icon">
                    <i
                      class="tf-icons bx bx-copy text-white copy-url"
                      style="cursor: pointer"
                      @click="
                        copyTextToClipboard(JSON.stringify(t.example, null, 2))
                      "
                    ></i>
                  </div>
                  {{ JSON.stringify(t.example, null, 2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["config", "isTab"],
  data() {
    return {
      active: {
        isTab: false,
      },
    };
  },
  methods: {
    getOrigin() {
      return window.location.origin;
    },
    getActiveTab(v, r, ci) {
      if (v == this.active[ci]) {
        return "active";
      }
      if (r[this.active[ci]]) {
        return "";
      } else {
        var keys = Object.keys(r);
        if (keys[0] == v) {
          return "active";
        }
      }
      return "";
    },
  },
};
</script>
<style>
.example-code-block {
  line-height: 35px;
  vertical-align: middle;
  align-items: center;
}
.code-json {
  text-align: justify;
  white-space: pre;
  background-color: #414040;
  border-radius: 3px;
  padding: 1rem;
  position: relative;
  max-height: 500px;
  overflow: auto;
}
.code {
  background-color: #2d2d2d;
  color: #fff;
  font-family: Courier, monospace;
  padding: 10px;
  border-radius: 0.2rem;
}

.code .method {
  background-color: #2b86ee;
  padding: 5px;
  border-radius: 3px;
  margin-right: 10px;
}

.method.put {
  background-color: #b743ce;
}

.method.post {
  background-color: #13c44e;
}

.code div {
  margin-top: 5px;
  margin-bottom: 5px;
}
.copy-icon {
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
}
.block-code {
  padding: 10px;
}

.value-code {
  margin-right: 10px;
}

.type-code {
  color: #59f0d7;
  margin-right: 5px;
}

.required-code {
  color: #ee8e34;
  margin-right: 5px;
}

.example-code {
  color: #a5a5a5;
}
.copy-url {
  margin-left: auto;
  margin-right: 5px;
}

.header-title,
.payload-title {
  margin-top: 15px;
  margin-bottom: 10px;
  /* background-color: #7481f1; */
  color: #2166e7;
  padding: 5px;
  border-bottom: 1px solid #2166e7;
  font-size: 18px;
}

.code .d-flex {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}
br {
  margin-bottom: 5px;
}
.code.tab {
  border-radius: 0 0.2rem 0.2rem 0.2rem;
  display: none;
}
.code.tab.active {
  display: block;
}
.nav-link.code {
  background-color: #6e6e6e !important;
  color: #fff !important;
}
.nav-link.code.active {
  background-color: #2d2d2d !important;
  color: #fff !important;
}
</style>
