<template>
  <div class="h-100">
    <div class="row m-0 h-100 bg-primary">
      <div class="col-12 col-lg-3 login-card d-flex">
        <div class="m-auto w-100">
          <div class="px-3 w-100 md-float-material form-material">
            <div class="card-block">
              <div class="py-3">
                <div class="text-center">
                  <img src="/img/logol.svg" alt="" class="w-75" />
                </div>
              </div>
              <h4 class="text-white text-center my-3" style="font-size: 16px">
                Acessar o onAgro
              </h4>
              <div v-if="!load">
                <div>
                  <div class="mb-3">
                    <div class="input-group input-group-merge">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="E-mail"
                        v-model="form.login"
                        @keyup.enter="logar"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-4 pt-3">
                  <div class="form-password-toggle mb-3">
                    <div class="input-group input-group-merge">
                      <input
                        autocomplete="new-password"
                        :type="inpuType"
                        class="form-control"
                        placeholder="Senha"
                        v-model="form.senha"
                        @keyup.enter="logar"
                      />
                      <span
                        class="input-group-text cursor-pointer"
                        @click="
                          inpuType == 'password'
                            ? (inpuType = 'text')
                            : (inpuType = 'password')
                        "
                        ><i
                          class="bx bx-hide"
                          v-if="inpuType == 'password'"
                        ></i>
                        <i class="bx bx-show" v-else></i
                      ></span>
                    </div>
                    <div
                      class="form-text px-1"
                      style="color: #10C537"
                      v-show="erro == 500"
                    >
                      Verifique suas informações de acesso e tente novamente
                    </div>
                    <div
                      class="form-text px-1"
                      style="color: #10C537"
                      v-show="erro == 401"
                    >
                      Acesse o email cadastrado para validar seu cadastro.
                    </div>
                  </div>
                  <div class="text-center mb-3">
                    <a
                      @click="$router.push('/recuperar')"
                      class="float-right ml-auto"
                      style="cursor: pointer; color: #02f387"
                    >
                      <small>Esqueceu a senha?</small>
                    </a>
                  </div>
                  <div class="mb-3">
                    <button class="btn btn-success d-grid w-100" @click="logar">
                      Acessar
                    </button>
                  </div>
                </div>

                <hr />
                <!-- <p
                  class="text-white pt-1"
                  style="text-align: justify; text-justify: inter-word; font-size: 14px"
                >
                  Registre-se e tenha acesso a todas as funcionalidades da
                  plataforma onAgro.
                </p> -->
                <div class="pt-2" style="margin-bottom: 5rem">
                  <button
                    class="btn btn-outline-success d-grid w-100 text-white"
                    @click="$router.push('/registrar')"
                  >
                    Registrar
                  </button>
                </div>
                <div>
                  <a
                    class="btn btn-link p-0 pb-1"
                    href="/termo"
                    style="color: #02f387; display: block; font-size: 12px"
                    target="_blank"
                    >Termos e Condições do onAgro</a
                  >
                  <a
                    class="btn btn-link p-0"
                    href="/politica"
                    style="color: #02f387; display: block; font-size: 12px"
                    target="_blank"
                    >Política de Privacidade</a
                  >
                </div>
                <div>
                  <div class="mt-2">
                    <div class="col-md-12 text-center">
                      <p
                        class="text-inverse text-left m-b-0 text-white"
                        style="font-size: 12px"
                      >
                        Versão {{ version }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100 d-flex text-center"
                style="margin-top: 20vh"
                v-else
              >
                <div class="m-auto">
                  <h4 class="mb-4 text-white">Autenticando...</h4>
                  <div
                    class="spinner-border text-white spinner-border-lg"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-lg-9 px-0 h-100 d-none d-lg-flex"
        style="background-color: #417240"
      >
        <img src="img/login.png" alt="" class="h-100 p-5 pb-0 m-auto" />
      </div>
    </div>
  </div>
  <Alert ref="alert"></Alert>
</template>

<script>
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      form: {
        login: "",
        senha: "",
      },
      lembrar: false,
      inpuType: "password",
      erro: null,
      load: false,
    };
  },
  watch: {},
  mounted() {
    // this.socket = this.createWebsocket(
    //   "/auth/login-qr?session=" + this.session,
    //   this
    // );
  },
  methods: {
    logar() {
      this.erro = null;
      this.login(
        {
          login: this.form.login,
          password: this.form.senha,
          type: this.isMobile() ? "mobile" : "web",
        },
        (data) => {
          this.$router.push("/");
        },
        (e) => {
          if (e.data.code == 401) {
            this.erro = 401;
            this.$refs.alert.show(
              "",
              "Acesse o email cadastrado para validar seu cadastro.",
              null,
              5000,
              "bg-danger"
            );
          } else {
            this.erro = 500;
            this.$refs.alert.show(
              "",
              "Verifique suas informações de acesso e tente novamente",
              null,
              5000,
              "bg-danger"
            );
          }
        }
      );
    },
  },
};
</script>

<style>
.login-card {
  background-color: #006035;
  animation: show-login-card 0.5s ease-in-out;
}
@keyframes show-login-card {
  from {
    /* transform: translateX(-100%); */
    opacity: 0;
  }
  to {
    /* transform: translateX(0%); */
    opacity: 1;
  }
}
</style>
