import { createRouter, createWebHistory } from 'vue-router'
import Dashborad from '../views/Dashboard.vue'
import System from '../views/system/System.vue'

// import Log from '../views/log/Log.vue'

import Admin from '../views/admin/Administracao.vue'
import Thread from '../views/system/Thread.vue'
import DeveloperApi from '../views/developer/Api.vue'
import Page404 from '../views/Page404.vue'

// import Terminal from '../views/terminal/Terminal.vue'
// import TerminalSistema from '../views/terminal/Sistema.vue'
// import TerminalEstacao from '../views/terminal/Estacao.vue'
// import TerminalEstacaoWs from '../views/terminal/EstacaoWs.vue'
// import TerminalRotas from '../views/terminal/Rotas.vue'
// import TerminalDatabase from '../views/terminal/Database.vue'

import Api from '../views/recurso/Api.vue'
import ApiToken from '../views/recurso/ApiToken.vue'

import Politica from '../views/Politica.vue'
import Termo from '../views/Termo.vue'

import Usuario from '../views/admin/Usuario.vue'
import Estacao from '../views/admin/Dispositivos.vue'
import Firmware from '../views/admin/Firmware.vue'
import EstacaoLeitura from '../views/admin/EstacaoLeitura.vue'
import AdminApiToken from '../views/admin/ApiToken.vue'
import Parametro from '../views/admin/Parametro.vue'
import QrCode from '../views/admin/QrCode.vue'


import Login from '../views/login/Login.vue'
import Recovery from '../views/login/Recovery.vue'
import Register from '../views/login/Register.vue'
import VerifyEmail from '../views/login/VerifyEmail.vue'
import ChangePassword from '../views/login/ChangePassword.vue'
// import EstacaoLog from '../views/log/EstacaoLog.vue'

import Conta from '../views/user/Conta.vue'
import UserPropriedadeAgricola from '../views/user/Propriedade.vue'

// import ChatGPT from '../views/util/ChatGPT.vue'
import Sensores from '../views/admin/Sensores.vue'
import Modulos from '../views/admin/Modulos.vue'
import PDF from '../views/admin/PDF.vue'
import Reprint from '../views/admin/Reprint.vue'

import Report from '../views/report/Report.vue'
import Dispositivos from '../views/user/Dispositivos.vue'
import adminDispositivos from '../views/admin/Dispositivos.vue'

const routes = [{
        path: '/',
        name: 'Dashborad',
        component: Dashborad,
    },
    {
        path: '/conta',
        name: 'Conta',
        component: Conta,
    },
    {
        path: '/propriedade',
        name: 'UserPropriedadeAgricola',
        component: UserPropriedadeAgricola,
    },
    {
        path: '/dispositivos',
        name: 'Dispositivo',
        component: Dispositivos
    },
    // {
    //     path: '/sistema',
    //     name: 'System',
    //     component: System,
    // },
    {
        path: '/administracao/usuarios',
        name: 'Usuario',
        component: Usuario,
    },
    {
        path: '/administracao/dispositivos',
        name: 'Dispositivos',
        component: adminDispositivos,
    },
    {
        path: '/administracao/firmwares',
        name: 'Firmware',
        component: Firmware,
    },
    {
        path: '/administracao/estacao-leituras',
        name: 'EstacaoLeitura',
        component: EstacaoLeitura,
    },
    {
        path: '/administracao/api-tokens',
        name: 'AdminApiToken',
        component: AdminApiToken,
    },
    {
        path: '/admin/QRCode',
        name: 'QrCode',
        component: QrCode,
    },
    {
        path: '/administracao/parametros',
        name: 'Prametro',
        component: Parametro,
    },
    // {
    //     path: '/threads',
    //     name: 'Thread',
    //     component: Thread,
    // },
    // {
    //     path: '/logs',
    //     name: 'Log',
    //     component: Log,
    // },
    // {
    //     path: '/logs/estacao',
    //     name: 'EstacaoLog',
    //     component: EstacaoLog,
    // },
    {
        path: '/administracao',
        name: 'Administracao',
        component: Admin,
    }, 
    // {
    //     path: '/device-api',
    //     name: 'DeveloperApi',
    //     component: DeveloperApi,
    // },
    // {
    //     path: '/api',
    //     name: 'Api',
    //     component: Api,
    // },
    // {
    //     path: '/api-token',
    //     name: 'ApiToken',
    //     component: ApiToken,
    // },
    {
        path: '/administracao/sensores',
        name: 'sensores',
        component: Sensores,
    },
    {
        path: '/administracao/modulos',
        name: 'modulos',
        component: Modulos,
    },
    // {
    //     path: '/terminal',
    //     name: 'Terminal',
    //     component: Terminal,
    // },
    // {
    //     path: '/terminal/sistema',
    //     name: 'TerminalSistema',
    //     component: TerminalSistema,
    // },
    // {
    //     path: '/terminal/estacao',
    //     name: 'TerminalEstacao',
    //     component: TerminalEstacao,
    // },
    // {
    //     path: '/terminal/estacao-ws',
    //     name: 'TerminalEstacaoWs',
    //     component: TerminalEstacaoWs,
    // },
    // {
    //     path: '/terminal/rotas',
    //     name: 'TerminalRotas',
    //     component: TerminalRotas,
    // },
    // {
    //     path: '/terminal/database',
    //     name: 'TerminalDatabase',
    //     component: TerminalDatabase,
    // },

    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { public: true }
    },
    // {
    //     path: '/chatgpt',
    //     name: 'Chatgpt',
    //     component: ChatGPT,
    // },
    {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: Page404,
        meta: { public: true }
    },
    {
        path: '/politica',
        name: 'Politica',
        component: Politica,
        meta: { public: true }
    },
    {
        path: '/registrar',
        name: 'Register',
        component: Register,
        meta: { public: true }
    },
    {
        path: '/recuperar',
        name: 'Recovery',
        component: Recovery,
        meta: { public: true }
    },
    {
        path: '/termo',
        name: 'Termo',
        component: Termo,
        meta: { public: true }
    },
    {
        path: '/verificar-email',
        name: 'VerificarEmail',
        component: VerifyEmail,
        meta: { public: true }
    },
    {
        path: '/trocar-senha',
        name: 'TrocarSenha',
        component: ChangePassword,
        meta: { public: true }
    },
    {
        path: '/admin/qrcode/grouper-print/:grouper',
        name: 'PDF',
        component: PDF,
    },
    {
        path: '/admin/qrcode/reprint/:grouper',
        name: 'Reprint',
        component: Reprint,
    },
    {
        path: '/report',
        name: 'Relatório',
        component: Report,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router