<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="true"
          @delete="$refs.datatable.update()"
          @create="$refs.datatable.update()"
          @onformcreate="onformcreate"
          @forceRerender="$refs.datatable.update()"
          name="Api token"
          names="Api tokens"
          button="+"
          @filter="$refs.datatable.applyFilters($event)"
          url="/ApiToken"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <Datatable
              ref="datatable"
              :fields="fields"
              :url="'/ApiToken/Read'"
              @edit="form.crud.edit($event)"
              @delete="form.crud.delete($event)"
              :where="where"
            >
              <template v-slot:privateToken="data">
                <div>
                  <div class="text-center copy-token">
                    {{ data.row.privateToken }}
                  </div>
                  <i
                    class="tf-icons bx bx-copy text-primary"
                    style="cursor: pointer"
                    @click="copyTextToClipboard(data.row.privateToken)"
                  ></i>
                </div>
              </template>
              <template v-slot:publicToken="data">
                <div>
                  <div class="text-center copy-token">
                    {{ data.row.publicToken }}
                  </div>
                  <i
                    class="tf-icons bx bx-copy text-primary"
                    style="cursor: pointer"
                    @click="copyTextToClipboard(data.row.publicToken)"
                  ></i>
                </div>
              </template>
            </Datatable>
          </template>
          <template v-slot:form="form">
            <div class="row">
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label">Nome *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.nome"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.nome"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Expiração </label>
                <i
                    class="tf-icons bx bx-infinite text-primary"
                    style="cursor: pointer; margin-left: 3px;"
                    @click="noExpire(form)"
                    title="Nunca Expira"
                  ></i>
                <input
                  v-if="!ilimitado"
                  id="date"
                  type="datetime-local"
                  class="form-control"
                  v-model= "form.crud.form.expire"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.expire"
                ></Error>
              </div>
              <div class="mb-3 col-md-6 col-12">
                <label class="form-label">Usuário *</label>
                <SelectData
                  url="/ApiToken/User"
                  field="usuarioId"
                  label="name"
                  empty="Selecione"
                  :value="
                    form.crud.form.usuarioId == undefined
                      ? null
                      : form.crud.form.usuarioId
                  "
                  @value="form.crud.form.usuarioId = $event"
                >
                </SelectData>
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.firmware"
                ></Error>
              </div>
              <!-- <div class="mb-3 col-md-12 col-12">
                <label class="form-label">Token público *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.publicToken"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.publicToken"
                ></Error>
              </div>
              <div class="mb-3 col-md-12 col-12">
                <label class="form-label">Token privado</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.crud.form.privateToken"
                />
                <Error
                  v-if="form.crud.error"
                  :error="form.crud.error.modelo"
                ></Error>
              </div> -->
              <hr />

              <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.created">
                <label class="form-label">Criado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.created"
                />
              </div>
              <div class="mb-3 col-12 col-md-6" v-if="form.crud.form.updated">
                <label class="form-label">Atualizado</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  readonly
                  :value="form.crud.form.updated"
                />
              </div>
            </div>
          </template>
          <template v-slot:filter="data">
            <div>
              <div class="mb-3">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.filters.nome"
                />
              </div>
              <!-- <div class="mb-3">
                <label class="form-label">Level</label>
                <select class="form-select" v-model="data.filters.level">
                  <option :value="undefined">Todos</option>
                  <option :value="10">Admin</option>
                  <option :value="5">Suporte</option>
                  <option :value="2">Especial</option>
                  <option :value="0">Normal</option>
                </select>
              </div> -->
            </div>
          </template>
        </Crud>
      </template>
    </Layout>

    <Alert ref="alert"></Alert>
  </div>
</template>
<script>
import Crud from "../../components/crud/Crud.vue";
import Datatable from "../../components/crud/Datatable.vue";
import Layout from "../../components/layout/Tema.vue";
import Error from "../../components/Error.vue";
import Alert from "../../components/Alert.vue";
import SelectData from "../../components/crud/input/SelectData.vue";

export default {
  components: {
    Crud,
    Layout,
    Datatable,
    Error,
    SelectData,
    Alert,
  },
  data() {
    return {
      showToken: null,
      numeros: null,
      ilimitado: false,
      where: {
        nome: {
          operator: "LIKE",
          replace: "%{value}%",
        },
      },

      fields: [
        { name: "ID", value: "id", style: { width: "70px" } },
        { name: "Nome", value: "nome" },
        { name: "Usuário id", value: "usuarioId" },

        { name: "Chave pública", value: "publicToken", type: "slot" },
        {
          name: "Chave privada",
          value: "privateToken",
          type: "slot",
        },
        {
          name: "Expiração",
          value: "expire",
          style: { width: "100px" },
          format: (v, d) => {
            if (v != null && v != "9999-12-31T23:59:59.9999999") {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "Nunca";
            }
          },
        },
        {
          name: "Criado",
          value: "created",
          style: { width: "180px" },
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          edit: true,
          delete: true,
          style: { width: "100px", textAlign: "center" },
        },
      ],
    };
  },
  methods: {
    noExpire(form) {
      form.crud.form.expire = "9999-12-31T23:59:59.9999999";
      this.ilimitado = !this.ilimitado
    },
    forceRerender() {
      this.component = false;
      this.$nextTick(() => {
        this.component = true;
      });
    },
    onformcreate(form) {
      form.usuarioId = null;
    },
  },
};
</script>
