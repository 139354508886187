<template>
  <Tab
    :values="[
      { name: 'Urbana', slot: 'urbana' },
      { name: 'Rural', slot: 'rural' },
    ]"
    @tabchange="forceRerenderTab"
    ref="tab"
  >
    <template v-slot:urbana>
      <div style="display: flex">
        <h4>Dispositivos</h4>
        <div style="margin-left: auto">
          <LoadButton
            style="flex; margin-bottom: 25px; background-color: #006035;"
            ref="btn_gerar"
            @click="abrirModalAplicativo"
            class="btn btn-success"
            text="+"
            loadtext="Adicionando"
          >
          </LoadButton>
        </div>
      </div>
      <Datatable
        v-if="tabComponent"
        ref="datatable"
        :fields="fieldsAplicacao"
        :fieldsMobile="fieldsMobile"
        :userDevices="true"
        :url="'/Devices/ReadDeviceUrban'"
        @edit="form.crud.edit($event)"
        :where="where"
        :share="abrirModalCompartilhar"
      >
        <template v-slot:status="data">
          <div style="text-align: center" v-if="data.row.status == 1">
            <img src="../../../public/img/pending.svg" alt="Pendente" />
          </div>
          <div style="text-align: center" v-if="data.row.status == 2">
            <img src="../../../public/img/offline.svg" alt="Offline" />
          </div>
          <div style="text-align: center" v-if="data.row.status == 3">
            <img src="../../../public/img/ok.svg" alt="Online" />
          </div>
        </template>
        <template v-slot:acoes="data">
          <div v-if="data.row.status == 1">
            <button
              @click="abrirModalFinalizar(data.row.id)"
              style="
                width: 100%;
                height: 100%;
                padding: 10px;
                background: #006035;
                border-radius: 5px;
                justify-content: center;
                align-items: center;
                gap: 5px;
                display: inline-flex;
              "
            >
              <div
                style="
                  text-align: center;
                  color: white;
                  font-size: 16px;
                  font-weight: 700;
                  word-wrap: break-word;
                "
              >
                Finalizar Cadastro
              </div>
            </button>
          </div>
          <div v-else>
            <div
              class="text-center"
              style="display: flex; justify-content: center; gap: 1rem"
            >
              <div class="text-center">
                <button
                  class="btn btn-white p-0"
                  @click="$emit(form.crud.edit(data.row))"
                >
                  <i class="bx bx-edit-alt text-primary"></i>
                </button>
              </div>
              <div class="text-center">
                <button
                  class="btn btn-white p-0"
                  @click="abrirModalCompartilhar(data.row.id)"
                >
                  <i class="bx bx-share-alt text-success"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
      </Datatable>
    </template>

    <template v-slot:rural>
      <div style="display: flex">
        <h4>Dispositivos</h4>
        <div style="margin-left: auto">
          <LoadButton
            style="margin-bottom: 25px; background-color: #006035; "
            ref="btn_gerar"
            @click="abrirModalAplicativo"
            @edit="form.crud.edit($event)"
            class="btn btn-success"
            text="+"
            loadtext="Adicionando"
          >
          </LoadButton>
        </div>
      </div>

      <Datatable
        v-if="tabComponent"
        ref="datatable"
        :fields="fieldsAplicacao"
        :fieldsMobile="fieldsMobile"
        :userDevices="true"
        :url="'/Devices/ReadDeviceRural'"
        @edit="form.crud.edit($event)"
        :where="where"
      >
        <template v-slot:status="data">
          <div v-if="data.row.status == 1">
            <img src="../../../public/img/pending.svg" alt="Pendente" />
          </div>
          <div v-if="data.row.status == 2">
            <img src="../../../public/img/offline.svg" alt="Offline" />
          </div>
          <div v-if="data.row.status == 3">
            <img src="../../../public/img/ok.svg" alt="Online" />
          </div>
        </template>
        <template v-slot:acoes="data">
          <div v-if="data.row.status == 1">
            <button
              @click="abrirModalFinalizar(data.row.id)"
              style="
                width: 100%;
                height: 100%;
                padding: 10px;
                background: #006035;
                border-radius: 5px;
                justify-content: center;
                align-items: center;
                gap: 5px;
                display: inline-flex;
              "
            >
              <div
                style="
                  text-align: center;
                  color: white;
                  font-size: 16px;
                  font-weight: 700;
                  word-wrap: break-word;
                "
              >
                Finalizar Cadastro
              </div>
            </button>
          </div>
          <div v-else>
            <div
              class="text-center"
              style="display: flex; justify-content: center"
            >
              <div class="text-center">
                <button
                  class="btn btn-white p-0"
                  @click="$emit(form.crud.edit(data.row))"
                >
                  <i class="bx bx-edit-alt text-primary"></i>
                </button>
              </div>
              <div class="text-center">
                <button
                  class="btn btn-white p-0"
                  @click="abrirModalCompartilhar(data.row.id)"
                >
                  <i class="bx bx-share-alt text-success"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
      </Datatable>
    </template>
  </Tab>

  <div class="modal fade" tabindex="-1" ref="modal_aplicativo">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h3 class="modal-title text-white mb-2">Adicionar Dispositivo</h3>
          <button
            type="button"
            class="btn-close"
            @click="fecharModalAplicativo"
          ></button>
        </div>
        <div class="modal-body">
          <span style="white-space: pre-line">
            Para adicionar um dispositivo você precisa cadastrar no sistema
            através do app onAgro, no seu celular.
          </span>
        </div>
        <hr />
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" ref="modal_finalizar">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h3 class="modal-title text-white mb-2">Finalizar Cadastro</h3>
          <button
            type="button"
            class="btn-close"
            @click="fecharModalFinalizar"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-6 col-md-6 col-12">
              <input
                type="radio"
                name="propriedade"
                value="Urbano"
                checked
                v-model="this.finalize.tipo"
              />
              Urbano

              <input
                type="radio"
                name="propriedade"
                value="Rural"
                v-model="this.finalize.tipo"
              />
              Rural
            </div>
          </div>
          <div class="row">
            <div class="mb-6 col-md-6 col-12">
              <label class="form-label">Nome do Dispositivo<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                v-model="this.finalize.nome"
              />
            </div>
            <div class="mb-6 col-md-6 col-12">
              <label class="form-label"
                >Propriedade<span class="text-danger">*</span></label
              >
              <SelectData
                v-if="this.finalize.tipo == 'Urbano'"
                url="/Devices/PropertiesUrban"
                field="id"
                label="nome"
                empty="Selecione"
                :value="
                  this.finalize.propriedades == undefined
                    ? null
                    : this.finalize.propriedades
                "
                @value="this.finalize.propriedades = $event"
              >
              </SelectData>
              <SelectData
                v-else
                url="/Devices/PropertiesRural"
                field="id"
                label="nome"
                empty="Selecione"
                :value="
                  this.finalize.propriedades == undefined
                    ? null
                    : this.finalize.propriedades
                "
                @value="this.finalize.propriedades = $event"
              >
              </SelectData>
            </div>
            <input :value="(this.finalize.id = id)" type="hidden" />
          </div>
        </div>
        <hr />
        <div class="modal-footer">
          <LoadButton
            style="flex; margin-bottom: 25px; margin-left: 25px; background: #006035;"
            @click="FinalizarCadastro()"
            ref="btn_finalizar"
            class="btn btn-success"
            text="Finalizar"
            loadtext="Finalizando..."
          >
          </LoadButton>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" ref="modal_compartilhamento">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <div style="display: inline-block">
            <h3 class="modal-title text-white mb-2">
              Compartilhamento de Dispositivo
            </h3>
            <h6 class="modal-title text-white mb-2" v-if="!this.limiteAtingido">
              Você tem no máximo {{ this.limite }} compartilhamentos por
              propriedade.
            </h6>
            <h6 class="modal-title text-white mb-2" v-if="this.limiteAtingido">
              O numero de Compartilhamentos foi excedido.
            </h6>
          </div>
          <button
            type="button"
            class="btn-close"
            style="margin-bottom: 40px"
            @click="fecharModalCompartilhar"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3 col-md-8 col-12" v-if="!this.limiteAtingido">
            <label class="form-label"
              >Codigo do usuário<span class="text-danger">*</span></label
            >
            <input
              :disabled="this.limiteAtingido"
              type="text"
              class="form-control"
              onfocusout="myFunction()"
              v-model="this.share.CodigoCompartilhado"
            />
            <span v-if="!fetched" style="font-size: 12px"
              >Informe o nº do Codigo do Usuário</span
            >
          </div>
          <div v-if="fetched">
            <span style="font-size: 12px"
              >Voce quer compartilhar com {{ this.sharedName }}</span
            >
            <button class="btn btn-white p-0" @click="this.fetched = false">
              <i class="bx bx-x text-danger"></i>
            </button>
            <button class="btn btn-white p-0" @click="Compartilhar()">
              <i class="bx bx-check text-success"></i>
            </button>
          </div>
          <div
            v-for="(d, i) in shared"
            :key="i"
            style="
              width: auto;
              height: auto;
              overflow: hidden;
              justify-content: flex-start;
              align-items: flex-start;
              display: inline-flex;
            "
          >
            <div
              style="
                margin-top: 3px;
                margin-right: 8px;
                width: auto;
                height: 100%;
                padding-left: 3px;
                padding-right: 3px;
                padding-top: 2px;
                padding-bottom: 2px;
                background: #7d9965;
                border-radius: 5px;
                justify-content: flex-start;
                align-items: center;
                display: flex;
                gap: 5px;
                word-wrap: break-all;
              "
            >
              <div
                style="
                  color: #f0f5ea;
                  font-size: 14px;
                  font-weight: 500;
                  white-space: nowrap;
                "
              >
                {{ d.Nome }}
              </div>
              <div
                style="
                  width: 12px;
                  height: 12px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  display: inline-flex;
                "
              >
                <button
                  class="btn btn-white p-0"
                  @click="RemoverUsuario(d.token)"
                >
                  <i class="bx bx-x text-danger"></i>
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="modal-footer" v-if="!this.limiteAtingido">
            <LoadButton
              style="display: flex; margin-left: 25px; background: #006035"
              @click="BuscarUsuario()"
              ref="btn_finalizar"
              class="btn btn-success"
              text="Buscar"
              loadtext="Buscando..."
            >
            </LoadButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Alert ref="alert"></Alert>
</template>

<script>
import Datatable from "../../components/crud/Datatable.vue";
import Tab from "../../components/form/Tab.vue";
import LoadButton from "../../components/form/LoadButton.vue";
import SelectData from "../../components/crud/input/SelectData.vue";
import Alert from "../../components/Alert.vue";

export default {
  props: ["form"],
  components: {
    Tab,
    Datatable,
    LoadButton,
    SelectData,
    Alert,
  },

  data() {
    return {
      tabComponent: true,
      status: null,
      shared: [],
      limite: null,
      sharedName: null,
      fetched: false,
      limiteAtingido: false,
      finalize: {
        id: null,
        tipo: "Urbano",
        nome: null,
        propriedades: null,
      },
      share: {
        QrCodeId: null,
        CodigoCompartilhado: null,
      },
      sharedUser: {
        QrCodeId: null,
        CodigoCompartilhado: null,
      },
      where: {
        nome: {
          operator: "LIKE",
          replace: "%{value}%",
        },
      },
      fields: [
        { name: "Nome", value: "nome", style: { width: "100px" } },
        {
          name: "Endereço",
          value: "logradouro",
          style: { textAlign: "center" },
        },
        {
          name: "Ação",
          type: "action",
          orderby: false,
          value: "status",
          edit: true,
          delete: false,
          style: { width: "100px", minWidth: "100px", textAlign: "center" },
        },
      ],

      fieldsAplicacao: [
        { name: "Status", value: "status", type: "slot", style: { width: "70px" } },
        { name: "Nome", value: "nome" },
        { name: "Modulo", value: "tipo" },
        {
          name: "Data Vinculação",
          value: "dataVinculacao",
          format: (v, d) => {
            if (v != null) {
              return new Date(v).toLocaleString('pt-BR');
            } else {
              return "-";
            }
          },
        },
        { name: "Ações", value: "acoes", type: "slot" },
      ],
      fieldsMobile: [
        { name: "Status", value: "status", type: "slot", style: { width: "70px" } },
        { name: "Nome / Modulo / Data Vinculação", value: ["nome", "tipo", "dataVinculacao"] },
        { name: "Ações", value: "acoes", type: "slot" },
      ],
    };
  },

  methods: {
    FinalizarCadastro() {
      this.urlPost("/Devices/FinalizeRegistration", this.finalize)
        .then((e) => {
          this.error = null;
          this.$refs.alert.show(
            "Finalizando",
            "Cadastro Finalizado com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          setTimeout(() => {
            this.finalize.nome = null;
            this.finalize.propriedades = null;
            this.fecharModalFinalizar();
            this.forceRerenderTab();
          }, 1000);
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Não foi possível finalizar o cadastro!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
    Compartilhar() {
      this.urlPost("/Devices/Share", this.share)
        .then((e) => {
          this.error = null;
          this.$refs.alert.show(
            "Compartilhando",
            "Compartilhado com sucesso!",
            null,
            5000,
            "bg-primary"
          );
          this.shared.push(e.data.Usuario);
          this.fetched = false;
          this.limiteAtingido = e.data.LimiteAtingido;
          this.share.CodigoCompartilhado = "";
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.share.CodigoCompartilhado = "";
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Você ja Compartilhou com este Usuario!",
              null,
              3000,
              "bg-danger"
            );
            this.fetched = false;
          }
        });
    },
    RemoverUsuario(token) {
      (this.sharedUser.CodigoCompartilhado = token),
        (this.sharedUser.QrCodeId = this.share.QrCodeId),
        this.urlPost("/AdminUser/RemoveSharedUser", this.sharedUser)
          .then((e) => {
            this.shared = this.shared.filter(
              (item) => JSON.stringify(item) !== JSON.stringify(e.data.Usuario)
            );
            this.limiteAtingido = e.data.LimiteAtingido;
          })
          .catch((e) => {
            if (e.response.status == 400) {
              this.error = e.response.data;
              this.$refs.alert.show(
                "Erro ",
                "Erro no processo",
                null,
                3000,
                "bg-danger"
              );
            } else {
              this.$refs.alert.show(
                "Erro " + e.response.status,
                "Não foi possível Remover do Compartilhamento!",
                null,
                3000,
                "bg-danger"
              );
            }
          });
    },
    BuscarUsuario() {
      this.urlPost("/Devices/FetchUserNameToShare", this.share)
        .then((e) => {
          this.fetched = true;
          this.sharedName = e.data.NomeDispositivo;
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          }
          if (e.response.status == 406) {
            this.error = e.response.data;
            this.share.CodigoCompartilhado = "";
            this.$refs.alert.show(
              "Erro ",
              "Não Pode Compartilhar com Você",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.share.CodigoCompartilhado = "";
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Código de Usuario Invalido!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
    UsuariosVinculados() {
      this.urlPost("/Devices/FetchSharedUserName", this.share)
        .then((e) => {
          this.shared = e.data.Usuario;
          this.limite = e.data.Limite;
          this.limiteAtingido = e.data.LimiteAtingido;
          this.sharedUser.CodigoCompartilhado = e.data.Usuario;
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.error = e.response.data;
            this.$refs.alert.show(
              "Erro ",
              "Erro no processo",
              null,
              3000,
              "bg-danger"
            );
          } else {
            this.$refs.alert.show(
              "Erro " + e.response.status,
              "Código de Usuario Invalido!",
              null,
              3000,
              "bg-danger"
            );
          }
        });
    },
    forceRerenderTab() {
      this.tabComponent = false;
      this.$nextTick(() => {
        this.tabComponent = true;
      });
    },
    oncreate(form) {
      if (form.expire == 0) {
        form.expire = null;
        return;
      }
      var expire = new Date();
      expire.setDate(expire.getDate() + form.expire);
      form.expire = expire;
    },
    abrirModalAplicativo() {
      $(this.$refs.modal_aplicativo).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    fecharModalAplicativo() {
      $(this.$refs.modal_aplicativo).modal("hide");
    },
    abrirModalFinalizar(data) {
      $(this.$refs.modal_finalizar).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.id = data;
    },
    fecharModalFinalizar() {
      $(this.$refs.modal_finalizar).modal("hide");
    },
    abrirModalCompartilhar(id) {
      $(this.$refs.modal_compartilhamento).modal({
        backdrop: "static",
        keyboard: false,
      });
      this.share.QrCodeId = id;
      this.UsuariosVinculados(this.shared);
    },
    fecharModalCompartilhar() {
      $(this.$refs.modal_compartilhamento).modal("hide");
    },
  },
};
</script>
