<template>
  <div class="h-100">
    <Layout ref="layout">
      <template v-slot:body>
        <Crud
          :modal="false"
          @delete="$refs.datatable.update()"
          @onformcreate="onform"
          @filter="$refs.datatable.applyFilters($event)"
          url="/AdminDevices"
          :cancreate="false"
          :closeoncreate="false"
          :btnfilter="false"
          :iniorderby="{ column: 'created', type: 'DESC' }"
        >
          <template v-slot:list="form">
            <DevicesTab :form="form"/>
          </template>
          <template v-slot:form="form">
            <DispositivosForm :form="form" :user="true"/>
          </template>       
        </Crud>
      </template>
    </Layout>
  </div>
</template>

<script>
import Crud from "../../components/crud/Crud.vue";
import Layout from "../../components/layout/Tema.vue";
import DevicesTab from "../../components/form/DevicesTab.vue";
import DispositivosForm from "../../components/form/DispositivosForm.vue";

export default {
  components: {
    Layout,
    DevicesTab,
    Crud,
    DispositivosForm
  },
};
</script>
